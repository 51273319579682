import { GiphyFetch } from "@giphy/js-fetch-api";
import { Grid } from "@giphy/react-components";
import {
	EmojiHappyIcon,
	PaperClipIcon,
} from "@heroicons/react/outline";
import Picker from "emoji-picker-react";
import { useEffect, useRef, useState } from "react";
import { AiOutlineGif } from "react-icons/ai";
import { BsFillFileEarmarkFill } from "react-icons/bs";
import { IoIosSend } from "react-icons/io";
import { MdClose } from "react-icons/md";
import wordlist from "utils/wordlist";

const gf = new GiphyFetch("ZBm6MSEgPXXUFhjHzoJjqYYZEDlre8vi");

export default function Composer({
	onSend,
	isReplying,
	replyingTo,
	handleCancelReply,
}) {
	const [value, setValue] = useState("");
	const fileRef = useRef(null);
	const [pickerOpen, setPickerOpen] = useState(false);
	const [files, setFiles] = useState({});
	const [clipOpen, setClipOpen] = useState(false);
	const [isGifOpen, setIsGifOpen] = useState(false);
	const [searchGifTerm, setSearchTerm] = useState(false);
	const [suggestions, setSuggestions] = useState([]);

	const onSubmit = () => {
		if (files?.hasOwnProperty("name")) {
			console.log(files.getRawFile());
			// const file = files.getRawFile();
			// console.log(file, files);
			onSend(files, "file");
			setFiles({});
		} else {
			if (value.trim() === "") return;
			onSend(value, "text");
			setValue("");
		}
	};

	const onEmojiClick = (e, { emoji }) => {
		setValue(value + emoji);
		const input = document.getElementById("chat");
		input.style.height = "inherit";
		input.style.height = `${input.scrollHeight}px`;
	};

	useEffect(() => {
		window.addEventListener("click", function (e) {
			if (!document.getElementById("emoji")?.contains(e.target)) {
				setPickerOpen(false);
			}
			if (!document.getElementById("clip")?.contains(e.target)) {
				setClipOpen(false);
			}
			if (!document.getElementById("gif")?.contains(e.target)) {
				setIsGifOpen(false);
				setSearchTerm("");
			}
		});
	}, []);

	const onPaste = (e) => {
		if (e.clipboardData.files.length) {
			const fileObject = e.clipboardData.files[0];
			const file = {
				getRawFile: () => fileObject,
				name: fileObject.name,
				size: fileObject.size,
				type: fileObject.type,
				status: 2,
				progress: 0,
			};
			setFiles(file);
			// const filesState = this.state.files.map((f) => ({ ...f }));
			// filesState.push(file);

			// this.setState({ files: filesState });
		}
	};

	const onGifPress = (gif) => {
		onSend(
			{
				data: gif,
			},
			"gif"
		);
		setIsGifOpen(false);
		setSearchTerm("");
	};

	useEffect(() => {
		const captionLastWord = value.split(" ").pop();
		if (captionLastWord.length >= 2) {
			searchSuggestions(captionLastWord, setSuggestions);
		} else {
			setSuggestions([]);
		}
	}, [value]);

	const searchSuggestions = async (word, callback) => {
		const newTerms = wordlist.filter((x) =>
			x.toLocaleLowerCase().startsWith(word.toLocaleLowerCase())
		);
		const slicedArray = newTerms.slice(0, 10);
		callback(slicedArray);
		// console.slicedArray('nedical', data);
	};

	const fetchGifs = (offset) =>
		searchGifTerm
			? gf.search(searchGifTerm, { offset, limit: 10 })
			: gf.trending({ offset, limit: 10 });

	const handleFileUpload = (e) => {
		const fileObject = e.target.files[0];
		console.log(fileObject);
		const file = {
			getRawFile: () => fileObject,
			name: fileObject.name,
			size: fileObject.size,
			type: fileObject.type,
			status: 2,
			progress: 0,
		};
		setFiles(file);
	};

	const handleKeyDown = (e) => {
		e.target.style.height = "inherit";
		e.target.style.height = `${e.target.scrollHeight}px`;
		setValue(e.target.value);
		// In case you have a limitation
		// e.target.style.height = `${Math.min(e.target.scrollHeight, limit)}px`;
	};

	const onKeyDown = (e) => {
		if (e.key === "Enter") {
			e.preventDefault();
			onSubmit();
		}
	};

	const onSetSuggestion = (text) => {
		setValue((prev) => {
			const words = prev.split(" ");
			words.pop();
			words.push(text + " ");
			setSuggestions([]);
			return words.join(" ");
		});
	};

	return (
		<>
			{suggestions.length > 0 && (
				<div className="relative w-full flex flex-row overflow-x-auto gap-2 p-3">
					{suggestions.map((suggestion) => (
						<div
							key={suggestion}
							className="bg-gray-200 rounded-full px-4 py-2 text-sm cursor-pointer hover:bg-gray-300 whitespace-nowrap"
							onClick={() => onSetSuggestion(suggestion)}
						>
							{suggestion}
						</div>
					))}
				</div>
			)}
			<div style={{ paddingLeft: "25px", paddingRight: "25px" }}>
				{isReplying && (
					<div className="flex flex-row items-center justify-between w-full mt-3 mb-2">
						<p>
							<strong>Replying to: </strong>
							{replyingTo?.message}
						</p>
						<button onClick={handleCancelReply}>Cancel</button>
					</div>
				)}
			</div>
			<div
				onPaste={onPaste}
				className="flex flex-row w-full min-w-[25rem] min-h-16 border-t items-center justify-between p-5"
			>
				{!files?.hasOwnProperty("name") && (
					<div className="relative" id="emoji">
						<button
							className={`p-1 mr-2 hover:bg-gray-50 rounded-full ${
								pickerOpen && "bg-red-50"
							}`}
							onClick={() => setPickerOpen(!pickerOpen)}
						>
							<EmojiHappyIcon className="h-6 w-6 text-health" />
						</button>

						{pickerOpen && (
							<div className="emoji-picker">
								<Picker onEmojiClick={onEmojiClick} native={true} />
							</div>
						)}
					</div>
				)}
				{value === "" && !files?.hasOwnProperty("name") && (
					<>
						<div className="relative" id="gif">
							<button
								className={`p-1 mr-2 hover:bg-gray-50 rounded-full ${
									isGifOpen && "bg-red-50"
								}`}
								onClick={() => setIsGifOpen(!isGifOpen)}
							>
								<AiOutlineGif className="h-6 w-6 text-health" />
							</button>
							{isGifOpen && (
								<div className="p-2 w-96 h-96 bg-white absolute bottom-14 left-0 shadow-xl flex-col space-y-2 rounded overflow-y-auto border border-gray-50">
									<input
										placeholder="Search Gif"
										className="w-full p-2 pl-4 rounded-full appearance-none bg-gray-100 my-4 text-sm"
										onChange={(e) => setSearchTerm(e.target.value)}
									/>
									<Grid
										width={384}
										columns={3}
										fetchGifs={fetchGifs}
										noLink
										hideAttribution
										onGifClick={onGifPress}
										key={searchGifTerm}
									/>
								</div>
							)}
						</div>
						<div className="relative group" id="clip">
							<button
								className={`p-1 mr-2 hover:bg-gray-50 rounded-full ${
									clipOpen && "bg-red-50"
								}`}
								onClick={() => fileRef?.current?.click()}
							>
								<PaperClipIcon className="h-6 w-6 text-health" />
							</button>
							{/* {clipOpen && (
							<div className="p-2 w-48 bg-white absolute bottom-14 left-0 shadow-xl flex-col space-y-2 rounded">
								<button
									className="fab-button text-sm"
									onClick={() => fileRef?.current?.click()}
								>
									<BsImage className="w-4 h-4 text-red-500 mr-2" />
									Photo or Video
								</button>
								<button className="fab-button text-sm">
									<BsFillFileEarmarkFill className="w-4 h-4 text-red-500 mr-2" />
									Document
								</button>
							</div>
						)} */}
						</div>
					</>
				)}
				<div className="w-full">
					{files?.hasOwnProperty("name") ? (
						<div className="flex w-[100px] h-[100px] relative">
							{files.type.includes("image") ? (
								<img
									className="w-[100px] h-[100px] bg-gray-800 mb-2 rounded"
									alt=""
									src={URL.createObjectURL(files.getRawFile())}
								/>
							) : files.type.includes("video") ? (
								<video
									className="w-[100px] h-[100px] bg-gray-800 mb-2 rounded"
									alt=""
									src={URL.createObjectURL(files.getRawFile())}
								/>
							) : (
								<div className="w-[100px] h-[100px] bg-gray-800 mb-2 rounded flex items-center justify-center">
									<BsFillFileEarmarkFill className="w-4 h-4 text-red-500 mr-2" />
								</div>
							)}

							<button
								onClick={() => {
									setFiles({});
								}}
								className=" w-10 h-10 bg-gray-100 rounded-full absolute -top-[12px] -right-[12px] flex justify-center items-center"
							>
								<MdClose className="w-6 h-6 text-gray-700" />
							</button>
						</div>
					) : (
						<textarea
							autoFocus
							id="chat"
							rows="1"
							cols={50}
							onChange={handleKeyDown}
							onKeyDown={onKeyDown}
							value={value}
							className="block p-2.5 w-full text-sm resize-none bg-gray-100
						 rounded-3xl max-h-24 min-h-[40px] border border-gray-300
						 focus:ring-0
						  focus:ring-login-blue focus:border-login-blue
						   dark:bg-gray-800 dark:border-gray-600
						    dark:placeholder-gray-400 dark:text-white
							 dark:focus:ring-blue-500 dark:focus:border-blue-500"
							placeholder="Your message..."
						></textarea>
					)}
					{/* <input
					placeholder="Type message..."
					className="w-full p-3 bg-gray-100 rounded-full text-sm"
				/> */}
				</div>
				<input
					ref={fileRef}
					onChange={handleFileUpload}
					type="file"
					style={{ display: "none" }}
					// multiple={false}
				/>
				<button
					onClick={onSubmit}
					className="ml-5 p-3 bg-health rounded-full"
				>
					<IoIosSend className="h-5 w-5 text-white" />
				</button>
			</div>
		</>
	);
}
