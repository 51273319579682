// import { useRouter } from 'next/router';
import { useEffect } from "react";
import {
	boot as bootIntercom,
	load as loadIntercom,
} from "./intercom";

export const IntercomProvider = ({ children }) => {
	//   const router = useRouter();

	if (typeof window !== "undefined") {
		loadIntercom();
		bootIntercom();
	}

	useEffect(() => {
		console.log("hi");
		(function () {
			var w = window;
			var ic = w.Intercom;
			if (typeof ic === "function") {
				ic("reattach_activator");
				ic("update", w.intercomSettings);
			} else {
				var d = document;
				var i = function () {
					i.c(arguments);
				};
				i.q = [];
				i.c = function (args) {
					i.q.push(args);
				};
				w.Intercom = i;
				var l = function () {
					var s = d.createElement("script");
					s.type = "text/javascript";
					s.async = true;
					s.src = "https://widget.intercom.io/widget/qev4vlpy";
					var x = d.getElementsByTagName("script")[0];
					x.parentNode.insertBefore(s, x);
				};
				if (document.readyState === "complete") {
					l();
				} else if (w.attachEvent) {
					w.attachEvent("onload", l);
				} else {
					w.addEventListener("load", l, false);
				}
			}
		})();

		window.Intercom("boot", {
			api_base: "https://api-iam.intercom.io",
			app_id: "khflrbw3",
		});
	});

	window.Intercom("update", {
		hide_default_launcher: true,
	});

	window.intercomSettings = {
		app_id: "khflrbw3",
		custom_launcher_selector: "#intercom-launcher",
	};

	//   useEffect(() => {
	//     const handleRouteChange = url => {
	//       if (typeof window !== 'undefined') {
	//         updateIntercom();
	//       }
	//     };

	//     router.events.on('routeChangeStart', handleRouteChange);

	//     // If the component is unmounted, unsubscribe
	//     // from the event with the `off` method:
	//     return () => {
	//       router.events.off('routeChangeStart', handleRouteChange);
	//     };
	//   }, [router.events]);

	return children;
};
