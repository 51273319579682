import { useEffect } from "react";

import { PlusIcon } from "@heroicons/react/outline";
import {
	BsChatText,
	BsClipboardCheck,
	BsPeople,
	BsShare,
} from "react-icons/bs";
import * as S from "./styled";

const FabButton = ({
	isPlusClicked,
	setIsPlusClicked,
	setOpenShareModal,
	setOpenConsultModal,
	setOpenConversationModal,
	setOpenNewTeamModal,
}) => {
	useEffect(() => {
		const handleClickOutside = (e) => {
			if (!document.getElementById("fab")?.contains(e.target)) {
				setIsPlusClicked(false);
			}
		};
		window.addEventListener("click", handleClickOutside);
		return () => {
			window.removeEventListener("click", handleClickOutside);
		};
	}, []);

	return (
		<S.ButtonWrapper
			id={"fab"}
			onClick={() => setIsPlusClicked(!isPlusClicked)}
		>
			<S.PlusIconStyled isPlusClicked={isPlusClicked}>
				<PlusIcon width={20} height={20} />
			</S.PlusIconStyled>
			{isPlusClicked && (
				<S.FabOptionsWrapper>
					<S.FabOptions>
						<S.ListItem
							onClick={() => {
								console.log("Pheme a Friend clicked");
								setOpenShareModal(true);
							}}
						>
							<S.Icon as={BsShare} />
							Pheme a Friend
						</S.ListItem>
						<S.ListItem
							onClick={() => {
								console.log("New Consult clicked");
								setOpenConsultModal(true);
							}}
						>
							<S.Icon as={BsClipboardCheck} />
							New Consult
						</S.ListItem>
						<S.ListItem
							onClick={() => {
								console.log("New Chat clicked");
								setOpenConversationModal(true);
							}}
						>
							<S.Icon as={BsChatText} />
							New Chat
						</S.ListItem>
						<S.ListItem
							onClick={() => {
								console.log("New Team clicked");
								setOpenNewTeamModal(true);
							}}
						>
							<S.Icon as={BsPeople} />
							New Team
						</S.ListItem>
					</S.FabOptions>
				</S.FabOptionsWrapper>
			)}
		</S.ButtonWrapper>
	);
};

export default FabButton;
