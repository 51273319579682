import { Dialog, Transition } from "@headlessui/react";
import User from "components/user";
import { createNewConversation } from "containers/consults/actions";
import { sendMessage } from "containers/global/actions";
import moment from "moment";
import { Fragment, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFullName } from "utils/helpers";

import "react-datepicker/dist/react-datepicker.css";

export default function ConsultInfoModal({
	open,
	setOpen,
	selectedChat,
}) {
	const cancelButtonRef = useRef(null);
	const dispatch = useDispatch();
	const user = useSelector((state) => state.auth.user);
	const [message, setMessage] = useState("");
	const [loading, setLoading] = useState(false);
	const [selectedPeople, setSelectedPeople] = useState([]);
	const { members } = selectedChat || {};
	const consult = selectedChat?.data;

	const Info = ({ title, desc, isMTop = true }) => {
		return (
			<>
				<div className={`text-xs text-gray-400 ${isMTop && "mt-4"}`}>
					{title}
				</div>
				<div className="font-regular text-sm mt-1 text-gray-700 max-w-[16rem]">
					{desc}
				</div>
			</>
		);
	};
	const onCreateChat = async () => {
		const members = selectedPeople?.map((item) => item._id);
		if (members?.length) {
			setLoading(true);
			dispatch(
				createNewConversation({
					members,
					type: "chat",
					onNext,
				})
			);
		} else {
			alert("Please select atleast one person");
		}
	};

	const onNext = (data) => {
		const createdAt = new Date().valueOf();
		dispatch(
			sendMessage({
				id: data?.url,
				message,
				sender: {
					userId: user?._id,
				},
				channel: data,
				type: typeof message === "string" ? "text" : "file",
				createdAt,
			})
		);
		setLoading(false);
		setOpen(false);
		setMessage("");
	};

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as="div"
				className="relative z-10"
				initialFocus={cancelButtonRef}
				onClose={() => {}}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed z-10 inset-0 overflow-y-auto">
					<div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
								<div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
									<div className="text-gray-700 font-bold text-lg mb-3">
										Consult Detail
									</div>

									<div className="font-md text-gray-600 font-semibold">
										Patient Info
									</div>
									<div className="pt-3 flex flex-col border-b w-full pb-4">
										<Info
											title="Name"
											desc={getFullName(consult)}
											isMTop={false}
										/>
										<Info
											title="Age / Date of Birth"
											desc={consult?.dateOfBirth}
										/>
										<Info
											title="Gender"
											desc={consult?.identifier?.replace("Gender: ", "")}
										/>
										<Info title="Consult Subject" desc={consult?.title} />
										{consult?.description && (
											<Info
												title="Consult Description"
												desc={consult?.description || "N/A"}
											/>
										)}
										<Info
											title="Created On"
											desc={moment(consult?.createdAt).format(
												"D MMM, YYYY H:mm A"
											)}
										/>
									</div>
									<div className="flex flex-row items-center justify-between w-full mt-3 mb-2">
										<div className="text-md text-gray-600 font-semibold">
											Members
										</div>
										<button className="text-xs text-red-600 font-semibold px-2 py-1 bg-red-100 rounded-full">
											+ Add to Consult
										</button>
									</div>
									{members?.map((member, index) => (
										<User
											name={member.nickname}
											img={member?.profilePhoto}
											isActive={member?.connectionStatus === "active"}
										/>
									))}
								</div>
								<div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
									<button
										type="button"
										className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-login-blue sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
										onClick={() => setOpen(false)}
										ref={cancelButtonRef}
									>
										Close
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
