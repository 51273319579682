export const FETCH_ALL_CHATS = 'FETCH_ALL_CHATS';
export const FETCH_ALL_CHATS_SUCCESS = 'FETCH_ALL_CHATS_SUCCESS';
export const FETCH_ALL_CHATS_FAILED = 'FETCH_ALL_CHATS_FAILED';

export const FETCH_ALL_CONSULT_CHATS = 'FETCH_ALL_CONSULT_CHATS';
export const FETCH_ALL_CONSULT_CHATS_SUCCESS =
  'FETCH_ALL_CONSULT_CHATS_SUCCESS';
export const FETCH_ALL_CONSULT_CHATS_FAILED = 'FETCH_ALL_CONSULT_CHATS_FAILED';

export const INTIALIZE_APP = 'INTIALIZE_APP';
export const INTIALIZE_APP_SUCCESS = 'INTIALIZE_APP_SUCCESS';
export const INTIALIZE_APP_FAILED = 'INTIALIZE_APP_FAILED';

export const FETCH_SINGLE_CHAT = 'FETCH_SINGLE_CHAT';
export const FETCH_SINGLE_CHAT_SUCCESS = 'FETCH_SINGLE_CHAT_SUCCESS';
export const FETCH_SINGLE_CHAT_FAILED = 'FETCH_SINGLE_CHAT_FAILED';

export const CREATE_NEW_CONVERSATION = 'CREATE_NEW_CONVERSATION';
export const CREATE_NEW_CONVERSATION_SUCCESS =
  'CREATE_NEW_CONVERSATION_SUCCESS';
export const CREATE_NEW_CONVERSATION_FAILED = 'CREATE_NEW_CONVERSATION_FAILED';

export const FETCH_PREVIOUS_MESSAGES = 'FETCH_PREVIOUS_MESSAGES';
export const FETCH_PREVIOUS_MESSAGES_SUCCESS =
  'FETCH_PREVIOUS_MESSAGES_SUCCESS';
export const FETCH_PREVIOUS_MESSAGES_FAILED = 'FETCH_PREVIOUS_MESSAGES_FAILED';

export const SEND_MESSAGE = 'SEND_MESSAGE';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAILED = 'SEND_MESSAGE_FAILED';

export const UPDATE_PROGRESS = 'UPDATE_PROGRESS';

export const REPLY_TO_MESSAGE = 'REPLY_TO_MESSAGE';
export const REPLY_TO_MESSAGE_SUCCESS = 'REPLY_TO_MESSAGE_SUCCESS';
export const REPLY_TO_MESSAGE_FAILED = 'REPLY_TO_MESSAGE_FAILED';

export const RESEND_MESSAGE = 'RESEND_MESSAGE';
export const RESEND_MESSAGE_SUCCESS = 'RESEND_MESSAGE_SUCCESS';
export const RESEND_MESSAGE_FAILED = 'RESEND_MESSAGE_FAILED';

export const FORWARD_MESSAGE = 'FORWARD_MESSAGE';
export const FORWARD_MESSAGE_SUCCESS = 'FORWARD_MESSAGE_SUCCESS';
export const FORWARD_MESSAGE_FAILED = 'FORWARD_MESSAGE_FAILED';

export const DELETE_MESSAGE = 'DELETE_MESSAGE';
export const DELETE_MESSAGE_SUCCESS = 'DELETE_MESSAGE_SUCCESS';
export const DELETE_MESSAGE_FAILED = 'DELETE_MESSAGE_FAILED';

export const CREATE_CONSULT = 'CREATE_CONSULT';
export const CREATE_CONSULT_SUCCESS = 'CREATE_CONSULT_SUCCESS';
export const CREATE_CONSULT_FAILED = 'CREATE_CONSULT_FAILED';

export const GET_CONSULTS = 'GET_CONSULTS';
export const GET_CONSULTS_SUCCESS = 'GET_CONSULTS_SUCCESS';
export const GET_CONSULTS_FAILED = 'GET_CONSULTS_FAILED';

export const RESOLVE_CONSULT = 'RESOLVE_CONSULT';
export const RESOLVE_CONSULT_SUCCESS = 'RESOLVE_CONSULT_SUCCESS';
export const RESOLVE_CONSULT_FAILED = 'RESOLVE_CONSULT_FAILED';

export const DELETE_CONSULT = 'DELETE_CONSULT';
export const DELETE_CONSULT_SUCCESS = 'DELETE_CONSULT_SUCCSS';
export const DELETE_CONSULT_FAILED = 'DELETE_CONSULT_FAILED';

export const REOPEN_CONSULT = 'REOPEN_CONSULT';
export const REOPEN_CONSULT_SUCCESS = 'REOPEN_CONSULT_SUCCESS';
export const REOPEN_CONSULT_FAILED = 'REOPEN_CONSULT_FAILED';

export const ARCHIVE_CONSULT = 'ARCHIVE_CONSULT';
export const ARCHIVE_CONSULT_SUCCESS = 'ARCHIVE_CONSULT_SUCCESS';
export const ARCHIVE_CONSULT_FAILED = 'ARCHIVE_CONSULT_FAILED';

export const UPDATE_CONSULT = 'UPDATE_CONSULT';
export const UPDATE_CONSULT_SUCCESS = 'UPDATE_CONSULT_SUCCESS';
export const UPDATE_CONSULT_FAILED = 'UPDATE_CONSULT_FAILED';

export const REPLY_TO_THREAD = 'REPLY_TO_THREAD';
export const REPLY_TO_THREAD_SUCCESS = 'REPLY_TO_THREAD_SUCCESS';
export const REPLY_TO_THREAD_FAILED = 'REPLY_TO_THREAD_FAILED';

export const FETCH_THREAD = 'FETCH_THREAD';
export const FETCH_THREAD_SUCCESS = 'FETCH_THREAD_SUCCESS';
export const FETCH_THREAD_FAILED = 'FETCH_THREAD_FAILED';

export const FETCH_MEMBERS = 'FETCH_MEMBERS';
export const FETCH_MEMBERS_SUCCESS = 'FETCH_MEMBERS_SUCCESS';
export const FETCH_MEMBERS_FAILED = 'FETCH_MEMBERS_FAILED';

export const MARK_AS_DELIVERED = 'MARK_AS_DELIVERED';
export const MARK_AS_READ = 'MARK_AS_READ';

export const MESSAGE_RECEIVED = 'MESSAGE_RECEIVED';

export const GET_ALL_CONSULTS = 'GET_ALL_CONSULTS';
export const GET_ALL_CONSULTS_SUCCESS = 'GET_ALL_CONSULTS_SUCCESS';
export const GET_ALL_CONSULTS_FAILED = 'GET_ALL_CONSULTS_FAILED';

export const CREATE_NEXT_SHIFT = 'CREATE_NEXT_SHIFT';
export const CREATE_NEXT_SHIFT_SUCCESS = 'CREATE_NEXT_SHIFT_SUCCESS';
export const CREATE_NEXT_SHIFT_FAILED = 'CREATE_NEXT_SHIFT_FAILED';

export const CREATE_NEW_SHIFT_CONVERSATION = 'CREATE_NEW_SHIFT_CONVERSATION';
export const CREATE_NEW_SHIFT_CONVERSATION_SUCCESS =
  'CREATE_NEW_SHIFT_CONVERSATION_SUCCESS';
export const CREATE_NEW_SHIFT_CONVERSATION_FAILED =
  'CREATE_NEW_SHIFT_CONVERSATION_FAILED';

export const DELETE_CHAT = 'DELETE_CHAT';
export const DELETE_CHAT_SUCCESS = 'DELETE_CHAT_SUCCESS';
export const DELETE_CHAT_FAILED = 'DELETE_CHAT_FAILED';

export const LEAVE_CHANNEL = 'LEAVE_CHANNEL';
export const LEAVE_CHANNEL_SUCCESS = 'LEAVE_CHANNEL_SUCCESS';
export const LEAVE_CHANNEL_FAILED = 'LEAVE_CHANNEL_FAILED';

export const ADD_REACTION = 'ADD_REACTION';
export const ADD_REACTION_SUCCESS = 'ADD_REACTION_SUCCESS';
export const ADD_REACTION_FAILED = 'ADD_REACTION_FAILED';
