import React from "react";
import * as S from "./styled";

export default function Button(props) {
	const { buttonType, children, ...restProps } = props;

	return (
		<S.Button buttonType={buttonType} {...restProps}>
			{children}
		</S.Button>
	);
}
