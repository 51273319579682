export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAILED = 'SIGN_IN_FAILED';

export const LOG_OUT = 'LOG_OUT';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';
export const LOG_OUT_FAILED = 'LOG_OUT_FAILED';

export const SEND_PHONE_CODE = 'SEND_PHONE_CODE';
export const SEND_PHONE_CODE_SUCCESS = 'SEND_PHONE_CODE_SUCCESS';
export const SEND_PHONE_CODE_FAILED = 'SEND_PHONE_CODE_FAILED';

export const VERIFY_PHONE_CODE = 'VERIFY_PHONE_CODE';
export const VERIFY_PHONE_CODE_SUCCESS = 'VERIFY_PHONE_CODE_SUCCESS';
export const VERIFY_PHONE_CODE_FAILED = 'VERIFY_PHONE_CODE_FAILED';

export const SEND_PROFILE_VERIFICATION = 'SEND_PROFILE_VERIFICATION';
export const SEND_PROFILE_VERIFICATION_SUCCESS =
  'SEND_PROFILE_VERIFICATION_SUCCESS';
export const SEND_PROFILE_VERIFICATION_FAILED =
  'SEND_PROFILE_VERIFICATION_FAILED';

export const CREATE_PASSWORD = 'CREATE_PASSWORD';
export const ADD_PROFESSION = 'ADD_PROFESSION';

export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';

export const SEND_PASSWORD_RESET = 'SEND_PASSWORD_RESET';
export const SEND_PASSWORD_RESET_FAILED = 'SEND_PASSWORD_RESET_FAILED';
export const SEND_PASSWORD_RESET_SUCCESS = 'SEND_PASSWORD_RESET_SUCCESS';

export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS';
export const GET_CURRENT_USER_FAILED = 'GET_CURRENT_USER_FAILED';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';

export const SET_STATUS_MESSAGE = 'SET_STATUS_MESSAGE';
export const SET_STATUS_MESSAGE_SUCCESS = 'SET_STATUS_MESSAGE_SUCCESS';
export const SET_STATUS_MESSAGE_FAILED = 'SET_STATUS_MESSAGE_FAILED';

export const ADD_TEAM_MEMBER = 'ADD_TEAM_MEMBER';
export const ADD_TEAM_MEMBER_SUCCESS = 'ADD_TEAM_MEMBER_SUCCESS';
export const ADD_TEAM_MEMBER_FAILED = 'ADD_TEAM_MEMBER_FAILED';

export const REMOVE_TEAM_MEMBER = 'REMOVE_TEAM_MEMBER';
export const REMOVE_TEAM_MEMBER_SUCCESS = 'REMOVE_TEAM_MEMBER_SUCCESS';
export const REMOVE_TEAM_MEMBER_FAILED = 'REMOVE_TEAM_MEMBER_FAILED';

export const GET_VERIFICATION_REQUESTS = 'GET_VERIFICATION_REQUESTS';
export const GET_VERIFICATION_REQUESTS_SUCCESS =
  'GET_VERIFICATION_REQUESTS_SUCCESS';
export const GET_VERIFICATION_REQUESTS_FAILED =
  'GET_VERIFICATION_REQUESTS_FAILED';

export const ACCEPT_VERIFICATION = 'ACCEPT_VERIFICATION';
export const ACCEPT_VERIFICATION_SUCCESS = 'ACCEPT_VERIFICATION_SUCCESS';
export const ACCEPT_VERIFICATION_FAILED = 'ACCEPT_VERIFICATION_FAILED';

export const REJECT_VERIFICATION = 'REJECT_VERIFICATION';
export const REJECT_VERIFICATION_SUCCESS = 'REJECT_VERIFICATION_SUCCESS';
export const REJECT_VERIFICATION_FAILED = 'REJECT_VERIFICATION_FAILED';

export const RESET_REGISTRATION = 'RESET_REGISTRATION';
