import * as constants from './constants';
import {LOG_OUT_SUCCESS} from '../auth/constants';
import {removeItemFromArray} from '../../utils/helpers';

const initialState = {
  searchInProcess: false,
  teamActionInProcess: false,
  directoryError: '',
  blockedUsers: [],
  teams: [],
};

const directoryReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case constants.FETCH_DIRECTORY: {
      return {
        ...state,
        searchInProcess: true,
      };
    }
    case constants.FETCH_DIRECTORY_SUCCESS: {
      return {
        ...state,
        searchInProcess: false,
      };
    }
    case constants.FETCH_DIRECTORY_FAILED: {
      const {error} = actions.payload;
      return {
        ...state,
        searchInProcess: false,
        directoryError: error,
      };
    }
    case constants.FETCH_BLOCKED_USERS_SUCCESS: {
      const {users} = actions.payload;
      return {
        ...state,
        blockedUsers: users,
      };
    }
    case constants.BLOCK_USER: {
      const {user} = actions.payload;
      return {
        ...state,
        blockedUsers: [user, ...state.blockedUsers],
      };
    }
    case constants.BLOCK_USER_SUCCESS: {
      const {users} = actions.payload;
      return {
        ...state,
        blockedUsers: users,
      };
    }
    case constants.BLOCK_USER_FAILED: {
      const blockedUsers = [...state.blockedUsers];
      blockedUsers.shift();
      return {
        ...state,
        blockedUsers,
      };
    }
    case constants.UNBLOCK_USER: {
      const {user} = actions.payload;
      const id = user?._id || user?.userId;
      return {
        ...state,
        blockedUsers: removeItemFromArray(id, state.blockedUsers, 'userId'),
      };
    }
    case constants.UNBLOCK_USER_SUCCESS: {
      const {users} = actions.payload;
      return {
        ...state,
        blockedUsers: users,
      };
    }
    case constants.BLOCK_USER_FAILED: {
      const {user} = actions.payload;
      return {
        ...state,
        blockedUsers: [user, ...state.blockedUsers],
      };
    }
    case constants.CREATE_TEAM:
    case constants.ADD_TEAM_USERS: {
      return {
        ...state,
        teamActionInProcess: true,
      };
    }
    case constants.CREATE_TEAM_SUCCESS:
    case constants.ADD_TEAM_USERS_SUCCESS: {
      return {
        ...state,
        teamActionInProcess: false,
      };
    }
    case constants.CREATE_TEAM_FAILED:
    case constants.ADD_TEAM_USERS_FAILED: {
      return {
        ...state,
        teamActionInProcess: false,
      };
    }
    case constants.GET_TEAMS_SUCCESS: {
      const {teams} = actions.payload;
      return {
        ...state,
        teams,
      };
    }
    case LOG_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

export default directoryReducer;
