import axios from "axios";
import moment from "moment";
import { isProduction } from "./constants";

export const getApi = async (token) => {
	if (token) {
		return axios.create({
			baseURL: isProduction
				? process.env.REACT_APP_API_URL_PROD
				: process.env.REACT_APP_API_URL_DEV,
			headers: { Authorization: token },
		});
	}
	return axios.create({
		baseURL: isProduction
			? process.env.REACT_APP_API_URL_PROD
			: process.env.REACT_APP_API_URL_DEV,
	});
};

export const validateEmail = (email) => {
	const expression =
		/(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

	return expression.test(String(email).toLowerCase());
};

export const validatePhone = (phone) => {
	const expression =
		/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/im;
	return expression.test(String(phone));
};

export const validatePassword = (password) => {
	return (
		password.length > 6 &&
		/\d/.test(password) &&
		/(?=.*[A-Z])/.test(password)
	);
};

export const getFullName = (user) =>
	`${
		user?.firstName || user?.lastName
			? `${user?.firstName || ""} ${user?.lastName || ""}`
			: user?.nickname
	}`;

export const removeItemFromArray = (id, array, key) => {
	if (Array.isArray(array)) {
		return array.filter((item) => {
			return item[key] !== id;
		});
	} else {
		return [];
	}
};

export const getInitials = (text = "") => {
	const names = text.split(" ");
	const initials = names
		.map((name, index) => (index < 2 ? name.charAt(0) : ""))
		.join("");
	return initials;
};

export const sortMessages = (sentMessages, pendingMessages, id) => {
	const sent = sentMessages?.hasOwnProperty(id) ? sentMessages[id] : [];
	const pending = pendingMessages?.hasOwnProperty(id)
		? pendingMessages[id]
		: [];
	const newMessages = [...pending, ...sent];
	const sortedMessages = newMessages.sort(
		(a, b) => a.createdAt - b.createdAt
	);
	return sortedMessages;
};

export const formatTime = (time) =>
	moment(time || Date.now()).format("hh:mm a");

export const formatTimeDate = (time) =>
	moment(time || Date.now()).format("DD-MMM-YYYY hh:mm a");

export const formatDateTime = (date) =>
	moment(date || Date.now()).format("DD MMM YYYY");

export const isSameDate = (date1, date2) => {
	const d1 = new Date(date1);
	const d2 = new Date(date2);
	return (
		d1.getFullYear() === d2.getFullYear() &&
		d1.getMonth() === d2.getMonth() &&
		d1.getDate() === d2.getDate()
	);
};
