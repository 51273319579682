import { useState } from "react";
import Lightbox from "react-awesome-lightbox";
import { useDispatch, useSelector } from "react-redux";
import {
	formatDateTime,
	getFullName,
	getInitials,
} from "utils/helpers";
// You need to import the CSS only once
import ProgressiveImg from "components/progressiveImage";
import moment from "moment";
import "react-awesome-lightbox/build/style.css";
import {
	TbChecks,
	TbClock,
	TbFile,
	TbMessage,
	TbTrash,
} from "react-icons/tb";
import { emojiObject } from "utils/constants";

export default function MessageItem({
	message,
	plainUrl,
	name,
	size,
	sender,
	messageType,
	createdAt,
	type,
	thumbnails = [],
	reactions = [],
	isRead,
	shouldShowDate,
	isSent,
	customType,
	data = "0",
	onReply,
	onDelete,
	setReactionsData,
	setShowReactions,
	channel,
	onReaction,
}) {
	const user = useSelector((state) => state.auth.user);
	const dispatch = useDispatch();
	const [showImage, setShowImage] = useState(false);
	const isSender = sender?.userId === user?._id;

	const getSize = (actualSize = 300) => {
		return actualSize * 0.85;
	};

	function urlify(text) {
		const urlRegex = /(https?:\/\/[^\s]+)/g;
		return text.split(urlRegex).map((part) => {
			if (part.match(urlRegex)) {
				return (
					<a
						target="_blank"
						rel="noopener noreferrer"
						href={part}
						className="text-blue-600"
					>
						{part}
					</a>
				);
			}
			return part;
		});
	}

	if (messageType === "admin") {
		return (
			<div className="px-4 py-2 bg-gray-100 text-health self-center rounded-full text-xs max-w-lg text-center mx-12 font-semibold">
				{message}
			</div>
		);
	}

	const GifRender = () => {
		const media = JSON.parse(data);
		return (
			<ProgressiveImg
				onClick={() => setShowImage(true)}
				src={media?.data?.images?.original?.url}
				width={getSize(media?.data?.images?.original?.width)}
				height={getSize(media?.data?.images?.original?.height)}
			/>
		);
	};

	const onReactionViewPress = () => {
		setShowReactions(true);
		setReactionsData(reactions);
	};

	const RenderReply = () => {
		if (customType === "reply") {
			const replyData = JSON.parse(data);
			return (
				<div className="flex bg-gray-50 p-2 rounded-lg mb-2">
					<div className="flex flex-col">
						<div className="flex items-center">
							<div className="text-base text-gray-500 font-semibold">
								{replyData?.sender?.nickname}:
							</div>
						</div>
						<div className="text-sm text-gray-700 mt-1">
							{replyData?.message}
						</div>
					</div>
				</div>
			);
		}
		return null;
	};

	function formatBytes(bytes, decimals = 2) {
		if (!+bytes) return "0 Bytes";

		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = [
			"Bytes",
			"KB",
			"MB",
			"GB",
			"TB",
			"PB",
			"EB",
			"ZB",
			"YB",
		];

		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${
			sizes[i]
		}`;
	}

	// show reaction bar to react when hovering on message

	const ReactionBar = () => {
		return (
			<div className="flex flex-row items-center space-x-2 text-xs max-w-xs self-center scale-100 bg-white shadow-md rounded-full p-2">
				{Object.keys(emojiObject).map((key) => {
					const emoji = emojiObject[key];
					return (
						<button
							onClick={() => onReaction(key)}
							className="w-10 h-10 bg-gray-50 rounded-full items-center justify-center flex group/forward hover:bg-gray-200"
						>
							<img alt="emoji" className="w-4 h-4" src={emoji} />
						</button>
					);
				})}
			</div>
		);
	};

	const senderView = () => {
		return (
			<div className="chat-message">
				<div className="flex items-end justify-end group/item relative">
					<div className="flex absolute z-10 -top-12 scale-0 group-hover/item:scale-100">
						<ReactionBar />
					</div>
					{/** message actions - reply , forward, delete */}
					<div className="flex flex-row items-center space-x-2 text-xs max-w-xs  self-center scale-0 group-hover/item:scale-100">
						{/* <button onClick={onReply} className="w-12 h-12 bg-gray-50 rounded-full items-center justify-center flex group/forward hover:bg-gray-200">
							<HiReply size={22} className="text-gray-400 group-hover/forward:text-gray-500" />
							<span className="message-tooltip group-hover/forward:scale-100">
								Reply
							</span>
						</button> */}

						<button
							onClick={onDelete}
							className="w-12 h-12 bg-gray-50 rounded-full items-center justify-center hover:bg-red-500 flex group/delete"
						>
							<TbTrash
								size={22}
								className="text-gray-400 group-hover/delete:text-white"
							/>
							<span className="message-tooltip group-hover/delete:scale-100">
								Delete Message
							</span>
						</button>
						{/* <div className="w-12 h-12 bg-gray-50 rounded-full items-center justify-center flex">
							<TbArrowForwardUp
								size={22}
								className="text-gray-400"
							/>
						</div> */}
					</div>

					<div className="flex flex-col space-y-2 text-xs max-w-2xl mx-2 ml-[5rem] order-1 items-end">
						<div className="px-4 py-2 rounded-lg rounded-br-none bg-chat-sender flex flex-col items-end relative">
							<RenderReply />
							{/** render reaction count with image  absolute position */}
							{reactions &&
							reactions?.filter((i) => emojiObject?.hasOwnProperty(i.key))
								.length === 0 ? null : (
								<button
									onClick={onReactionViewPress}
									className={`p-2 bg-white rounded-full shadow-lg absolute -bottom-6 -right-2 flex flex-row items-center justify-center z-10`}
								>
									{reactions?.map(
										(item) =>
											emojiObject?.hasOwnProperty(item.key) && (
												<img
													alt="emoji"
													className="w-4 h-4"
													src={emojiObject[item.key]}
												/>
											)
									)}
								</button>
							)}
							<div className="w-full">
								{messageType === "file" && type.includes("image") && (
									<ProgressiveImg
										onClick={() => setShowImage(true)}
										src={plainUrl}
										width={getSize(
											thumbnails?.length > 0 && thumbnails[0]?.realWidth
										)}
										height={getSize(
											thumbnails.length > 0 && thumbnails[0]?.realHeight
										)}
									/>
								)}
								{messageType === "file" && type.includes("video") && (
									<video
										width={getSize(
											thumbnails.length > 0 && thumbnails[0]?.realWidth
										)}
										height={getSize(
											thumbnails.length > 0 && thumbnails[0]?.realHeight
										)}
										class={`max-w-[200px] rounded-lg border-gray-100 border`}
										alt=""
										controls
									>
										<source src={plainUrl} type="video/mp4" />
									</video>
								)}

								{messageType === "file" && type.includes("audio") && (
									<audio
										class={`max-w-[300px] rounded-lg border-gray-100`}
										alt=""
										controls
									>
										<source src={plainUrl} type="audio/mp3" />
									</audio>
								)}

								{messageType === "file" &&
									!type.includes("video") &&
									!type.includes("audio") &&
									!type.includes("image") && (
										<div className="flex items-center space-x-2">
											<div className="flex items-center justify-center w-8 h-8 rounded-full bg-gray-100">
												<TbFile />
											</div>
											<div className="flex flex-col">
												<a
													href={plainUrl}
													target="_blank"
													rel="noopener noreferrer"
													className="text-blue-600"
												>
													{name}
												</a>
												{size && (
													<span className="text-xs text-gray-500">
														{formatBytes(size, 2)}
													</span>
												)}
											</div>
										</div>
									)}

								{customType === "gif" ? (
									<GifRender />
								) : (
									messageType !== "file" && (
										<span className="text-sm text-gray-800 self-start w-full">
											{urlify(message)}
										</span>
									)
								)}
								<div className="flex flex-row items-end justify-end mt-1">
									<div className="text-gray-600 text-xs">
										{moment(createdAt).format("DD MMM h:mm a")}
									</div>
									{isSent ? (
										<TbChecks
											className={`text-${
												isRead ? "green" : "gray"
											}-600 text-xs ml-1`}
										/>
									) : (
										<TbClock className={`text-gray-600 text-xs ml-1`} />
									)}
								</div>
							</div>
						</div>
					</div>
					{user?.profilePhoto ? (
						<img
							src={user?.profilePhoto}
							alt="My profile"
							className="w-6 h-6 rounded-full order-1"
						/>
					) : (
						<div class="w-6 h-6 order-1 flex justify-center items-center rounded-full bg-red-500 text-xs text-white uppercase">
							{getInitials(getFullName(user))}
						</div>
					)}
				</div>
				{showImage && (
					<Lightbox image={plainUrl} onClose={() => setShowImage(false)} />
				)}
			</div>
		);
	};

	const receiverView = () => {
		return (
			<div className="chat-message">
				<div className="flex items-end group/item relative">
					<div className="flex absolute z-10 left-10 -top-12 scale-0 group-hover/item:scale-100">
						<ReactionBar />
						<button
							onClick={onReply}
							className="w-12 h-12 bg-gray-50 rounded-full items-center justify-center hover:bg-red-500 flex group/delete"
						>
							<TbMessage
								size={22}
								className="text-gray-400 group-hover/delete:text-white"
							/>
							<span className="message-tooltip group-hover/delete:scale-100">
								Reply to message
							</span>
						</button>
					</div>
					<div className="flex flex-col space-y-2 text-xs max-w-2xl mx-2 order-2 items-start mr-[5rem]">
						<div className="px-4 py-2 rounded-lg rounded-bl-none bg-chat-receiver flex flex-col items-start relative">
							<RenderReply />
							{/** render reaction count with image  absolute position */}
							{reactions &&
							reactions?.filter((i) => emojiObject?.hasOwnProperty(i.key))
								.length === 0 ? null : (
								<button
									onClick={onReactionViewPress}
									className={`p-2 bg-white rounded-full shadow-lg absolute -bottom-6 -left-2 flex flex-row items-center justify-center z-10`}
								>
									{reactions?.map(
										(item) =>
											emojiObject?.hasOwnProperty(item.key) && (
												<img
													alt="emoji"
													src={emojiObject[item.key]}
													className="w-4 h-4"
												/>
											)
									)}
								</button>
							)}
							<div className="relative max-w-xl break-words">
								{messageType === "file" && type.includes("image") && (
									<ProgressiveImg
										onClick={() => setShowImage(true)}
										src={plainUrl}
										width={getSize(
											thumbnails.length > 0 && thumbnails[0]?.realWidth
										)}
										height={getSize(
											thumbnails.length > 0 && thumbnails[0]?.realHeight
										)}
									/>
								)}
								{messageType === "file" && type.includes("audio") && (
									<audio
										width={getSize(
											thumbnails.length > 0 && thumbnails[0]?.realWidth
										)}
										height={getSize(
											thumbnails.length > 0 && thumbnails[0]?.realHeight
										)}
										class={`max-w-[200px] rounded-lg border-gray-100 border`}
										alt=""
										controls
									>
										<source src={plainUrl} type="audio/mp3" />
									</audio>
								)}
								{messageType === "file" && type.includes("video") && (
									<video
										width={getSize(
											thumbnails.length > 0 && thumbnails[0]?.realWidth
										)}
										height={getSize(
											thumbnails.length > 0 && thumbnails[0]?.realHeight
										)}
										class={`max-w-[200px] rounded-lg border-gray-100 border`}
										alt=""
										controls
									>
										<source src={plainUrl} type="video/mp4" />
									</video>
								)}

								{messageType === "file" &&
									!type.includes("video") &&
									!type.includes("audio") &&
									!type.includes("image") && (
										<div className="flex items-center space-x-2">
											<div className="flex flex-col">
												<a
													href={plainUrl}
													target="_blank"
													rel="noopener noreferrer"
													className="text-blue-600"
												>
													{name}
												</a>
												{size && (
													<span className="text-xs text-gray-500">
														{formatBytes(size, 2)}
													</span>
												)}
											</div>
											<div className="flex items-center justify-center w-8 h-8 rounded-full bg-gray-100">
												<TbFile />
											</div>
										</div>
									)}
								{customType === "gif" ? (
									<GifRender />
								) : (
									messageType !== "file" && (
										<span className="text-sm text-gray-800">
											{urlify(message)}
										</span>
									)
								)}
							</div>
							<div className="flex flex-row items-end justify-end mt-1">
								<div className="text-gray-600 text-xs">
									{moment(createdAt).format("DD MMM h:mm a")}
								</div>
							</div>
						</div>
					</div>
					{sender?.plainProfileUrl ? (
						<img
							src={sender?.plainProfileUrl}
							alt="My profile"
							className="w-6 h-6 rounded-full order-1"
						/>
					) : (
						<div class="mr-2 w-6 h-6 relative flex justify-center items-center rounded-full bg-health text-xs text-white uppercase flex-shrink-0">
							{getInitials(sender?.nickname)}
						</div>
					)}
				</div>
				{showImage && (
					<Lightbox image={plainUrl} onClose={() => setShowImage(false)} />
				)}
			</div>
		);
	};

	return (
		<>
			{shouldShowDate && (
				<div className="px-2 py-2 bg-gray-100 ml-[40%] text-gray-500 rounded-full text-xs w-36 text-center mx-12 font-semibold">
					{formatDateTime(createdAt)}
				</div>
			)}
			{isSender ? senderView() : receiverView()}
		</>
	);
}
