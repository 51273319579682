import moment from "moment";
import { useEffect, useState } from "react";

export default function ChatItem({
	isActive = false,
	isOnline = false,
	url,
	lastMessage,
	coverUrl,
	members,
	myId,
	name,
	customType,
	unreadMessageCount,
	onSelect,
}) {
	const [user, setUser] = useState(null);
	const [title, setTitle] = useState("");
	useEffect(() => {
		if (members?.length === 2) {
			const chatUser = members.find((usr) => usr.userId !== myId);
			setUser(chatUser);
			setTitle(`${chatUser.nickname || chatUser?.firstName || ""}`);
		}
		if (members?.length === 1) {
			const chatUser = members[0];
			setUser(chatUser);
			setTitle(chatUser.nickname || chatUser?.firstName || "");
		}
		if (members?.length === 3) {
			const chatUser = members.filter((usr) => usr.userId !== myId);
			if (chatUser.length === 2) {
				setTitle(
					`You, ${
						getFirstName(chatUser[0].nickname) || ""
					} and ${getFirstName(chatUser[1].nickname)}`
				);
			} else if (chatUser.length === 1) {
				setTitle(`${getFirstName(chatUser[0].nickname)}`);
			}
		}
		if (members?.length > 3) {
			const chatUser = members.filter((usr) => usr.userId !== myId);
			setTitle(`You and ${chatUser.length} others`);
		}
	}, [url, members]);

	const getFirstName = (n) => {
		return n.split(" ")[0];
	};

	return (
		<button
			className={`flex flex-col p-4 rounded-xl ${
				isActive ? "bg-health-20" : "bg-white"
			}`}
			onClick={onSelect}
		>
			<button className="flex flex-row items-start justify-between w-full">
				<div className="relative flex-shrink-0">
					<img
						src={
							members?.length > 2 ? coverUrl : user?.profilePhoto || coverUrl
						}
						alt=""
						className="rounded-full w-[40px] h-[40px]"
					/>

					{isOnline && (
						<span className="absolute text-green-500 right-0 bottom-0">
							<svg width="15" height="15">
								<circle cx="6" cy="6" r="6" fill="currentColor"></circle>
							</svg>
						</span>
					)}
				</div>
				<div className="flex flex-col flex-1 pl-2 mb-2 items-start">
					<div className="text-md font-semibold">
						{name !== "Group Channel" ? name : title}
					</div>
					<span className="text-[#989fa7] text-xs text-left line-clamp-2 max-w-[230px] flex-wrap text-ellipsis">
						{customType === "nextshift"
							? "NextShift"
							: lastMessage?.messageType === "file"
							? "attachment"
							: lastMessage?.message}
					</span>
				</div>
				<div className="flex flex-col items-center">
					<span className="text-gray-600 text-xs">
						{moment(lastMessage?.createdAt).fromNow()}
					</span>
					{!!unreadMessageCount && (
						<span className="text-white text-[12px] w-5 h-5 bg-red-500 rounded mt-1">
							{unreadMessageCount}
						</span>
					)}
				</div>
			</button>
		</button>
	);
}
