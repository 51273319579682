import {
	addReaction,
	fetchPreviousMessages,
} from "containers/global/actions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import sendBird from "services/sendbird";
import { isSameDate, sortMessages } from "utils/helpers";
import User from "../user";
import AddUserToChatModal from "./addUserToChatModal";
import MessageItem from "./messageItem";
import RemoveUserFromChatModal from "./removeUserFromChatModal";

export default function MessageList({
	id,
	onReply,
	setReactionsData,
	setShowReactions,
	selectedChat,
}) {
	const messages = useSelector((state) => state.global.messages);
	const user = useSelector((state) => state.auth.user);
	const pendingMessages = useSelector(
		(state) => state.global.pendingMessages
	);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const [chatMessages, setChatMessages] = useState([]);
	useEffect(() => {
		setChatMessages(sortMessages(messages, pendingMessages, id));
	}, [messages, pendingMessages, id]);
	const { members } = selectedChat || {};
	const [addUserToChatModalOpen, setAddUserToChatModalOpen] =
		useState(false);
	const [removeUserFromChatModalOpen, setRemoveUserFromChatModalOpen] =
		useState({ open: false, member: {} });

	useEffect(() => {
		setTimeout(() => {
			const container = document.getElementById("messages");
			if (container) {
				container.scrollTop = container.scrollHeight;
			}
		}, 100);
	}, [chatMessages]);

	useEffect(() => {
		const container = document.getElementById("messages");
		if (container) {
			container.scrollTop = container.scrollHeight;
		}
		setLoading(false);
	}, [id]);

	const onEndReached = () => {
		dispatch(
			fetchPreviousMessages({
				channel: id,
				onNext: () => setLoading(false),
			})
		);
	};

	const handleScroll = (e) => {
		const bottom =
			e.target.clientHeight + e.target.scrollTop <=
			e.target.clientHeight + 500;
		if (bottom && !loading) {
			setLoading(true);
			// onEndReached();
		}
	};

	const onDelete = (item) => {
		// console.log(item.createdAt, id, true);
		sendBird.deleteMessage(item, id, true).then((d) => {
			setChatMessages(
				sortMessages(messages, pendingMessages, id).filter(
					(x) => x.messageId !== item.messageId
				)
			);
			alert("The message is being deleted in the background.");
			console.log("deleted");
		});
	};

	const userIsAdmin = selectedChat?.creator?.userId === user?._id;

	return (
		<div className="flex w-full h-full overflow-hidden">
			<div
				className="flex flex-col p-2 w-3/4 h-full overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch"
				id="messages"
				onScroll={handleScroll}
			>
				{chatMessages.map((item, index) => (
					<MessageItem
						key={item.id}
						{...item}
						isSent={!item?.sending}
						setReactionsData={setReactionsData}
						setShowReactions={setShowReactions}
						onDelete={() => onDelete(item)}
						onReply={() => onReply(item)}
						channel={id}
						onReaction={(emoji) => {
							dispatch(
								addReaction({
									message: item,
									channel: id,
									reaction: emoji,
								})
							);
						}}
						shouldShowDate={
							chatMessages.length >= index + 1
								? !isSameDate(
										chatMessages[index - 1]?.createdAt,
										item?.createdAt
								  )
								: false
						}
					/>
				))}
			</div>
			<div className="w-1/4 h-full p-2 border-l overflow-y-auto">
				<div className="flex flex-row items-center justify-between w-full mt-3 mb-2">
					<div className="text-md text-gray-600 font-semibold">
						Members
					</div>
					<button
						className="text-xs text-red-600 font-semibold px-2 py-1 bg-red-100 rounded-full"
						onClick={() => setAddUserToChatModalOpen(true)}
					>
						+ Add to Chat
					</button>
				</div>
				{members?.map((member, index) => {
					const isAdmin =
						selectedChat.creator.nickname === member.nickname;
					console.log(selectedChat);
					return (
						<User
							userIsAdmin={userIsAdmin}
							member={member}
							name={member.nickname}
							img={member?.profilePhoto}
							isActive={member?.connectionStatus === "active"}
							isAdmin={isAdmin}
							setRemoveModalOpen={setRemoveUserFromChatModalOpen}
							showAbilityToRemoveUser={members?.length > 2}
						/>
					);
				})}
			</div>
			<AddUserToChatModal
				open={addUserToChatModalOpen}
				setOpen={setAddUserToChatModalOpen}
				teamId={123}
				channel={id}
			/>
			<RemoveUserFromChatModal
				members={members}
				member={removeUserFromChatModalOpen.member.userId}
				open={removeUserFromChatModalOpen.open}
				setOpen={setRemoveUserFromChatModalOpen}
				teamId={123}
				channel={id}
			/>
		</div>
	);
}
