import React from "react";
import ReactDOM from "react-dom/client";
import "./globals.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store, persistor } from "./store";
import Layout from "./components/Layout";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/lib/integration/react";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<BrowserRouter>
		<PersistGate persistor={persistor}>
			<Provider store={store}>
				<Layout>
					<React.StrictMode>
						<App />
					</React.StrictMode>
				</Layout>
			</Provider>
		</PersistGate>
	</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
