import ProfileGroup from "../../../assets/images/profilegroup.png";

export default function TeamItem({
  name,
  logo,
  isActive,

  onSelect
}) {
  return (
    <button
      className={`flex flex-col p-4 rounded-xl ${
        isActive ? "bg-health-20" : "bg-white"
      }`}
      onClick={onSelect}
    >
      <button className="flex flex-row items-start justify-between w-full">
        <div className="relative flex-shrink-0">
          <img
            src={logo || ProfileGroup}
            alt=""
            className="rounded-full w-[40px] h-[40px]"
          />
        </div>
        <div className="flex flex-col flex-1 pl-2 items-start self-center">
          <div className="text-md font-semibold">{name}</div>
        </div>
      </button>
    </button>
  );
}
