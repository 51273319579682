import * as constants from './constants';

export const fetchAllChats = () => ({
  type: constants.FETCH_ALL_CHATS,
});

export const fetchAllChatsSuccess = ({chats}) => ({
  type: constants.FETCH_ALL_CHATS_SUCCESS,
  payload: {chats},
});

export const fetchAllChatsFailed = ({error}) => ({
  type: constants.FETCH_ALL_CHATS_FAILED,
  payload: {error},
});

export const fetchAllConsultChats = (onNext) => ({
  type: constants.FETCH_ALL_CONSULT_CHATS,
  payload: {onNext},
});

export const fetchAllConsultChatsSuccess = ({chats}) => ({
  type: constants.FETCH_ALL_CONSULT_CHATS_SUCCESS,
  payload: {chats},
});

export const fetchAllConsultChatsFailed = ({error}) => ({
  type: constants.FETCH_ALL_CONSULT_CHATS_FAILED,
  payload: {error},
});

export const fetchSingleChat = ({id, channel, onSuccess}) => ({
  type: constants.FETCH_SINGLE_CHAT,
  payload: {id, channel, onSuccess},
});

export const fetchSingleChatSuccess = ({chats, id}) => ({
  type: constants.FETCH_SINGLE_CHAT_SUCCESS,
  payload: {chats, id},
});

export const fetchPreviousMessages = ({channel, onNext}) => ({
  type: constants.FETCH_PREVIOUS_MESSAGES,
  payload: {channel, onNext},
});

export const fetchPreviousMessagesSuccess = ({messages, id}) => ({
  type: constants.FETCH_PREVIOUS_MESSAGES_SUCCESS,
  payload: {messages, id},
});

export const fetchPreviousMessagesFailed = ({error}) => ({
  type: constants.FETCH_PREVIOUS_MESSAGES_FAILED,
  payload: {error},
});

export const createNewConversation = ({
  members,
  member,
  channel,
  onNext,
  type,
  customType,
  name,
  cover,
}) => ({
  type: constants.CREATE_NEW_CONVERSATION,
  payload: {members, member, channel, onNext, type, customType, name, cover},
});

export const createNewConversationSuccess = () => ({
  type: constants.CREATE_NEW_CONVERSATION_SUCCESS,
});

export const createNewConversationFailed = () => ({
  type: constants.CREATE_NEW_CONVERSATION_FAILED,
});

export const createNewShiftConversation = ({
  members,
  customType,
  messages,
}) => ({
  type: constants.CREATE_NEW_SHIFT_CONVERSATION,
  payload: {members, messages, customType},
});

export const createNewShiftConversationSuccess = () => ({
  type: constants.CREATE_NEW_SHIFT_CONVERSATION_SUCCESS,
});

export const createNewShiftConversationFailed = () => ({
  type: constants.CREATE_NEW_SHIFT_CONVERSATION_FAILED,
});

export const fetchThread = ({id, channel}) => ({
  type: constants.FETCH_THREAD,
  payload: {id, channel},
});

export const fetchThreadSuccess = ({messages, id}) => ({
  type: constants.FETCH_THREAD_SUCCESS,
  payload: {messages, id},
});

export const fetchThreadFailed = ({error, id}) => ({
  type: constants.FETCH_THREAD_SUCCESS,
  payload: {error, id},
});

export const fetchMembers = ({members, channel}) => ({
  type: constants.FETCH_MEMBERS,
  payload: {members, channel},
});

export const fetchMembersSuccess = ({members}) => ({
  type: constants.FETCH_MEMBERS_SUCCESS,
  payload: {members},
});

export const fetchMembersFailed = ({error}) => ({
  type: constants.FETCH_MEMBERS_FAILED,
  payload: {error},
});

export const fetchSingleChatFailed = ({error}) => ({
  type: constants.FETCH_SINGLE_CHAT_FAILED,
  payload: {error},
});

export const intializeApp = () => ({
  type: constants.INTIALIZE_APP,
});

export const intializeAppSuccess = ({isBiometricAvailable}) => ({
  type: constants.INTIALIZE_APP_SUCCESS,
  payload: {isBiometricAvailable},
});

export const intializeAppFailed = ({error}) => ({
  type: constants.INTIALIZE_APP_SUCCESS,
  payload: {error},
});

export const sendMessage = ({
  channel,
  message,
  id,
  sender,
  type,
  createdAt,
  onProgress,
  extraData,
}) => ({
  type: constants.SEND_MESSAGE,
  payload: {
    channel,
    message,
    id,
    sender,
    type,
    createdAt,
    onProgress,
    extraData,
  },
});

export const updateProgress = ({messageId, id, progress}) => ({
  type: constants.UPDATE_PROGRESS,
  payload: {messageId, progress, id},
});

export const sendMessageSuccess = ({message, id, createdAt}) => ({
  type: constants.SEND_MESSAGE_SUCCESS,
  payload: {message, id, createdAt},
});

export const sendMessageFailed = ({
  error,
  id,
  message,
  type,
  sender,
  createdAt,
}) => ({
  type: constants.SEND_MESSAGE_FAILED,
  payload: {error, id, message, type, sender, createdAt},
});

export const replyToMessage = ({
  parentMessage,
  message,
  id,
  sender,
  type,
  createdAt,
  channel,
  onProgress,
}) => ({
  type: constants.REPLY_TO_MESSAGE,
  payload: {
    parentMessage,
    message,
    id,
    sender,
    type,
    createdAt,
    channel,
    onProgress,
  },
});

export const replyToMessageSuccess = ({message, id, createdAt}) => ({
  type: constants.SEND_MESSAGE_SUCCESS,
  payload: {message, id, createdAt},
});

export const replyToMessageFailed = ({
  parentMessage,
  message,
  id,
  sender,
  type,
  createdAt,
  channel,
}) => ({
  type: constants.REPLY_TO_MESSAGE_FAILED,
  payload: {parentMessage, message, id, sender, type, createdAt, channel},
});

export const resendMessage = ({
  channel,
  message,
  id,
  sender,
  type,
  createdAt,
  failedAt,
  onProgress,
}) => ({
  type: constants.RESEND_MESSAGE,
  payload: {
    channel,
    message,
    id,
    sender,
    type,
    createdAt,
    failedAt,
    onProgress,
  },
});

export const resendMessageSuccess = ({message, id, createdAt, failedAt}) => ({
  type: constants.RESEND_MESSAGE_SUCCESS,
  payload: {message, id, createdAt, failedAt},
});

export const resendMessageFailed = ({
  error,
  id,
  message,
  type,
  sender,
  createdAt,
  failedAt,
}) => ({
  type: constants.RESEND_MESSAGE_FAILED,
  payload: {error, id, message, type, sender, createdAt, failedAt},
});

export const replyToThread = ({
  channel,
  message,
  sender,
  type,
  parentMessageId,
  parentMessage,
  id,
}) => ({
  type: constants.REPLY_TO_THREAD,
  payload: {channel, message, sender, type, parentMessageId, parentMessage, id},
});

export const replyToThreadSuccess = ({message, id, parentMessageId}) => ({
  type: constants.REPLY_TO_THREAD_SUCCESS,
  payload: {message, parentMessageId},
});

export const replyToThreadFailed = ({
  error,
  id,
  message,
  parentMessageId,
  parentMessage,
  type,
  sender,
}) => ({
  type: constants.REPLY_TO_THREAD_FAILED,
  payload: {error, message, parentMessageId, type, sender, parentMessage},
});

export const forwardMessage = ({
  message,
  channels,
  sender,
  onNext,
  onProgress,
}) => ({
  type: constants.FORWARD_MESSAGE,
  payload: {message, channels, sender, onNext, onProgress},
});

export const forwardMessageSuccess = () => ({
  type: constants.FORWARD_MESSAGE_SUCCESS,
});

export const forwardMessageFailed = ({err}) => ({
  type: constants.FORWARD_MESSAGE_FAILED,
  payload: {err},
});

export const onMessageReceived = ({message, id}) => ({
  type: constants.MESSAGE_RECEIVED,
  payload: {message, id},
});

export const deleteMessage = ({
  message,
  channel,
  isConsult,
  parentMessageId,
}) => ({
  type: constants.DELETE_MESSAGE,
  payload: {message, channel, isConsult, parentMessageId},
});

export const deleteMessageSuccess = ({
  message,
  channel,
  isConsult,
  parentMessageId,
}) => ({
  type: constants.DELETE_MESSAGE_SUCCESS,
  payload: {message, channel, isConsult, parentMessageId},
});

export const markAsRead = ({channelUrl}) => ({
  type: constants.MARK_AS_READ,
  payload: {channelUrl},
});

export const markAsDelivered = ({channelUrl}) => ({
  type: constants.MARK_AS_DELIVERED,
  payload: {channelUrl},
});

export const deleteMessageFailed = ({error}) => ({
  type: constants.DELETE_MESSAGE_FAILED,
  payload: {error},
});

export const createConsult = ({
  title,
  identifier,
  firstName,
  lastName,
  dateOfBirth,
  description,
  id,
  file,
  team,
  onNext,
}) => ({
  type: constants.CREATE_CONSULT,
  payload: {
    title,
    identifier,
    description,
    id,
    file,
    firstName,
    lastName,
    dateOfBirth,
    team,
    onNext,
  },
});

export const createConsultSuccess = ({message, id, consult}) => ({
  type: constants.CREATE_CONSULT_SUCCESS,
  payload: {message, id, consult},
});

export const createConsultFailed = ({error, id, message}) => ({
  type: constants.CREATE_CONSULT_FAILED,
  payload: {error, id, message},
});

export const getConsults = ({id, type}) => ({
  type: constants.GET_CONSULTS,
  payload: {id, type},
});

export const getConsultsSuccess = ({id, consults, type}) => ({
  type: constants.GET_CONSULTS_SUCCESS,
  payload: {id, consults, type},
});

export const getConsultsFailed = ({id, error}) => ({
  type: constants.GET_CONSULTS_FAILED,
  payload: {error, id},
});

export const markConsultResolved = ({consult, onNext, users}) => ({
  type: constants.RESOLVE_CONSULT,
  payload: {onNext, consult, users},
});

export const markConsultResolvedSuccess = ({consult, msg}) => ({
  type: constants.RESOLVE_CONSULT_SUCCESS,
  payload: {consult, msg},
});

export const markConsultResolvedFailed = ({id, error, channel}) => ({
  type: constants.RESOLVE_CONSULT_FAILED,
  payload: {id, error, channel},
});

export const reopenConsult = ({consult, onNext, users}) => ({
  type: constants.REOPEN_CONSULT,
  payload: {onNext, consult, users},
});

export const reopenConsultSuccess = ({consult, msg}) => ({
  type: constants.REOPEN_CONSULT_SUCCESS,
  payload: {consult, msg},
});

export const reopenConsultFailed = ({id, error, channel}) => ({
  type: constants.REOPEN_CONSULT_FAILED,
  payload: {id, error, channel},
});

export const archiveConsult = ({consult, onNext, shouldHideChat}) => ({
  type: constants.ARCHIVE_CONSULT,
  payload: {onNext, consult, shouldHideChat},
});

export const archiveConsultSuccess = ({consult, msg}) => ({
  type: constants.ARCHIVE_CONSULT_SUCCESS,
  payload: {consult, msg},
});

export const archiveConsultFailed = ({id, error, channel}) => ({
  type: constants.ARCHIVE_CONSULT_FAILED,
  payload: {id, error, channel},
});

export const deleteConsult = ({consult, onNext}) => ({
  type: constants.DELETE_CONSULT,
  payload: {consult, onNext},
});

export const deleteConsultSuccess = ({consult, message}) => ({
  type: constants.DELETE_CONSULT_SUCCESS,
  payload: {consult, message},
});

export const deleteConsultFailed = ({error, consult}) => ({
  type: constants.DELETE_CONSULT_FAILED,
  payload: {error, consult},
});

export const updateConsult = ({consult, onNext, oldConsult}) => ({
  type: constants.UPDATE_CONSULT,
  payload: {consult, onNext, oldConsult},
});

export const updateConsultSuccess = ({consult, message}) => ({
  type: constants.UPDATE_CONSULT_SUCCESS,
  payload: {consult, message},
});

export const updateConsultFailed = ({error, consult}) => ({
  type: constants.UPDATE_CONSULT_FAILED,
  payload: {error, consult},
});

export const getAllConsult = () => ({
  type: constants.GET_ALL_CONSULTS,
});

export const getAllConsultSuccess = ({consults}) => ({
  type: constants.GET_ALL_CONSULTS_SUCCESS,
  payload: {consults},
});

export const getAllConsultsFailed = ({error}) => ({
  type: constants.GET_ALL_CONSULTS_FAILED,
  payload: {error},
});

export const createNextShift = ({shifts, onNext}) => ({
  type: constants.CREATE_NEXT_SHIFT,
  payload: {shifts, onNext},
});

export const createNextShiftSuccess = ({shifts}) => ({
  type: constants.CREATE_NEXT_SHIFT_SUCCESS,
  payload: {shifts},
});

export const createNextShiftFailed = ({shifts, error}) => ({
  type: constants.CREATE_NEXT_SHIFT_FAILED,
  payload: {shifts, error},
});

export const deleteChat = ({channel, onNext}) => ({
  type: constants.DELETE_CHAT,
  payload: {channel, onNext},
});

export const deleteChatSuccess = ({channel}) => ({
  type: constants.DELETE_CHAT_SUCCESS,
  payload: {channel},
});

export const deleteChatFailed = ({error}) => ({
  type: constants.DELETE_CHAT_FAILED,
  payload: {error},
});

export const leaveChannel = ({channel, onNext}) => ({
  type: constants.LEAVE_CHANNEL,
  payload: {channel, onNext},
});

export const leaveChannelSuccess = ({channel}) => ({
  type: constants.LEAVE_CHANNEL_SUCCESS,
  payload: {channel},
});

export const leaveChannelFailed = ({error}) => ({
  type: constants.LEAVE_CHANNEL_FAILED,
  payload: {error},
});

export const addReaction = ({message, channel, reaction}) => ({
  type: constants.ADD_REACTION,
  payload: {message, channel, reaction},
});

export const addReactionSuccess = ({message, channel, reaction}) => ({
  type: constants.ADD_REACTION_SUCCESS,
  payload: {message, channel, reaction},
});

export const addReactionFailed = ({error}) => ({
  type: constants.ADD_REACTION_FAILED,
  payload: {error},
});
