import * as constants from "./constants";
import { LOG_OUT_SUCCESS } from "../auth/constants";
import _ from "lodash";
import { removeItemFromArray } from "../../utils/helpers";

const initialState = {
	loadingConsults: false,
	loadingPreviousMessages: false,
	creatingConsult: false,
	creatingNextShift: false,
	chats: [],
	consultAllChats: [],
	messages: {},
	pendingMessages: {},
	consults: {},
	consultChats: {},
	nextShiftConsults: [],
};

const ConsultReducer = (state = initialState, actions) => {
	switch (actions.type) {
		case constants.INTIALIZE_APP: {
			return {
				...state,
				pendingMessages: processPendingMessages(state.pendingMessages),
			};
		}
		case constants.FETCH_ALL_CHATS: {
			return {
				...state,
				loadingConsults: true,
				creatingNextShift: false,
			};
		}
		case constants.FETCH_ALL_CHATS_SUCCESS: {
			const { chats } = actions.payload;
			return {
				...state,
				loadingConsults: false,
				chats,
			};
		}
		case constants.FETCH_ALL_CHATS_FAILED: {
			return {
				...state,
				loadingConsults: false,
			};
		}
		case constants.FETCH_ALL_CONSULT_CHATS: {
			return {
				...state,
				loadingConsults: true,
				creatingNextShift: false,
			};
		}
		case constants.FETCH_ALL_CONSULT_CHATS_SUCCESS: {
			const { chats } = actions.payload;
			return {
				...state,
				loadingConsults: false,
				consultAllChats: sortConsults(chats),
			};
		}
		case constants.FETCH_ALL_CHATS_FAILED: {
			return {
				...state,
				loadingConsults: false,
			};
		}
		case constants.CREATE_NEXT_SHIFT: {
			return {
				...state,
				creatingNextShift: true,
			};
		}
		case constants.CREATE_NEXT_SHIFT_SUCCESS:
		case constants.CREATE_NEXT_SHIFT_FAILED: {
			return {
				...state,
				creatingNextShift: false,
			};
		}
		case constants.REPLY_TO_THREAD: {
			const { message, sender, type, parentMessageId, parentMessage } =
				actions.payload;
			const lastMessages = state.consultChats?.hasOwnProperty(
				parentMessageId
			)
				? state.consultChats[parentMessageId]
				: [];
			if (parentMessageId) {
				return {
					...state,
					consultChats: {
						...state.consultChats,
						[parentMessageId]: [
							{
								message: type === "text" ? message : "",
								messageType: type,
								type: message?.type,
								plainUrl: message?.uri,
								_sender: sender,
								createdAt: "",
								sending: true,
								name: message?.name,
								data: parentMessage
									? JSON.stringify(parentMessage)
									: message,
								customType: parentMessage ? "reply" : "",
								messageId: new Date().valueOf(),
							},
							...lastMessages,
						],
					},
				};
			} else {
				return state;
			}
		}
		case constants.REPLY_TO_THREAD_SUCCESS: {
			const { parentMessageId, message } = actions.payload;
			const lastMessages = state.consultChats?.hasOwnProperty(
				parentMessageId
			)
				? state.consultChats[parentMessageId]
				: [];
			if (message) {
				lastMessages.shift();
				return {
					...state,
					consultChats: {
						[parentMessageId]: [message, ...lastMessages],
					},
				};
			} else {
				return state;
			}
		}
		case constants.FETCH_THREAD_SUCCESS: {
			const { id, messages } = actions.payload;
			return {
				...state,
				consultChats: {
					...state.consultChats,
					[id]: messages,
				},
			};
		}
		case constants.REPLY_TO_THREAD_FAILED: {
			const { message, parentMessageId, type, sender } = actions.payload;
			const lastMessages = state.consultChats?.hasOwnProperty(
				parentMessageId
			)
				? state.consultChats[parentMessageId]
				: [];
			lastMessages.shift();
			return {
				...state,
				consultChats: {
					...state.consultChats,
					[parentMessageId]: [
						{
							message: type === "text" ? message : "",
							messageType: type,
							type: message?.type,
							plainUrl: message?.uri,
							_sender: sender,
							createdAt: "",
							name: message?.name,
							sending: false,
							sent: false,
							error: true,
						},
						...lastMessages,
					],
				},
			};
		}
		case constants.RESEND_MESSAGE: {
			const { id, message, sender, type, createdAt, failedAt } =
				actions.payload;
			return {
				...state,
				pendingMessages: {
					...state.pendingMessages,
					[id]: remapMessages(
						{
							message: type === "text" ? message : "",
							messageType: type,
							type: message?.type,
							plainUrl: message?.uri,
							progress: 0,
							size: message?.size,
							_sender: sender,
							createdAt,
							sending: true,
							name: message?.name,
							messageId: failedAt,
						},
						state.pendingMessages[id]
					),
				},
			};
		}
		case constants.UPDATE_PROGRESS: {
			const { messageId, id, progress } = actions.payload;
			return {
				...state,
				pendingMessages: {
					...state.pendingMessages,
					[id]: state.pendingMessages[id].map((item) => {
						if (item.messageId === messageId) {
							return {
								...item,
								progress,
							};
						} else {
							return item;
						}
					}),
				},
			};
		}
		case constants.SEND_MESSAGE:
		case constants.REPLY_TO_MESSAGE: {
			const { id, message, sender, type, createdAt, parentMessage } =
				actions.payload;
			const lastMessages = state?.pendingMessages?.hasOwnProperty(id)
				? state.pendingMessages[id]
				: [];
			if (id) {
				return {
					...state,
					pendingMessages: {
						...state.pendingMessages,
						[id]: [
							{
								message:
									type === "text"
										? message
										: type === "gif"
										? "gif"
										: "",
								messageType: type,
								type: message?.type,
								plainUrl: message?.uri,
								size: message?.size,
								progress: 0,
								_sender: sender,
								createdAt,
								sending: true,
								name: message?.name,
								data: parentMessage
									? JSON.stringify(parentMessage)
									: type === "gif"
									? JSON.stringify(message)
									: message,
								customType: parentMessage
									? "reply"
									: type === "gif"
									? "gif"
									: "",
								messageId: createdAt,
							},
							...lastMessages,
						],
					},
				};
			} else {
				return state;
			}
		}
		case constants.SEND_MESSAGE_SUCCESS: {
			const { id, message, createdAt } = actions.payload;
			const lastMessages = state.messages?.hasOwnProperty(id)
				? [...state.messages[id]]
				: [];
			if (message) {
				return {
					...state,
					pendingMessages: {
						...state.pendingMessages,
						[id]: removeItemFromArray(
							message.name,
							state.pendingMessages[id],
							"name"
						),
					},
					messages: {
						...state.messages,
						[id]: [message, ...lastMessages],
					},
				};
			} else {
				return state;
			}
		}
		case constants.CREATE_CONSULT: {
			return {
				...state,
				creatingConsult: true,
				consults: state?.consults || {},
			};
		}
		case constants.CREATE_CONSULT_SUCCESS: {
			const { id, message, consult } = actions.payload;
			const lastMessages = state.messages?.hasOwnProperty(id)
				? state.messages[id]
				: [];
			const lastConsults = state.consults?.hasOwnProperty(id)
				? state.consults[id]
				: [];
			if (message) {
				return {
					...state,
					creatingConsult: false,
					// messages: {
					//   ...state.messages,
					//   [id]: [message, ...lastMessages],
					// },
					consults: {
						...state.consults,
						[id]: [consult, ...lastConsults],
					},
					consultChats: {
						[message.messageId]: [],
						...state.consultChats,
					},
				};
			} else {
				return state;
			}
		}
		case constants.CREATE_CONSULT_FAILED: {
			return {
				...state,
				creatingConsult: false,
			};
		}
		case constants.MESSAGE_RECEIVED: {
			const { id = "", message } = actions.payload;
			const { parentMessageId = "" } = message;
			const recentMsgs = state.consultChats?.hasOwnProperty(
				parentMessageId
			)
				? state.consultChats[parentMessageId]
				: [];
			const recentAllMsgs = state.messages?.hasOwnProperty(id)
				? state.messages[id]
				: [];
			if (parentMessageId) {
				return {
					...state,
					consultChats: {
						[parentMessageId]: [message, ...recentMsgs],
					},
				};
			}
			if (id) {
				return {
					...state,
					messages: {
						...state.messages,
						[id]: [message, ...recentAllMsgs],
					},
				};
			}
			return initialState;
		}
		case constants.RESEND_MESSAGE_FAILED:
		case constants.REPLY_TO_MESSAGE_FAILED: {
			const { id, message, type, sender, createdAt, failedAt } =
				actions.payload;
			const lastMessages = [...state.pendingMessages[id]];
			lastMessages.shift();
			return {
				...state,
				pendingMessages: {
					...state.pendingMessages,
					[id]: remapMessages(
						{
							message: type === "text" ? message : "",
							messageType: type,
							type: message?.type,
							progress: 0,
							plainUrl: message?.uri,
							_sender: sender,
							createdAt,
							name: message?.name,
							sending: false,
							size: message?.size,
							sent: false,
							error: true,
							messageId: failedAt,
						},
						state.pendingMessages[id]
					),
				},
			};
		}
		case constants.SEND_MESSAGE_FAILED: {
			const { id, message, type, sender, createdAt } = actions.payload;
			const lastMessages = [...state.pendingMessages[id]];
			lastMessages.shift();
			return {
				...state,
				pendingMessages: {
					...state.pendingMessages,
					[id]: remapMessages(
						{
							message: type === "text" ? message : "",
							messageType: type,
							type: message?.type,
							plainUrl: message?.uri,
							size: message?.size,
							_sender: sender,
							createdAt,
							name: message?.name,
							sending: false,
							sent: false,
							error: true,
							messageId: createdAt,
						},
						state.pendingMessages[id]
					),
				},
			};
		}
		case constants.FETCH_SINGLE_CHAT_SUCCESS: {
			const { chats, id } = actions.payload;
			return {
				...state,
				loadingPreviousMessages: false,
				messages: {
					...state.messages,
					[id]: chats,
				},
			};
		}
		case constants.FETCH_MEMBERS_SUCCESS: {
			const { members } = actions.payload;
			return {
				...state,
				members: {
					...state.members,
					...members,
				},
			};
		}
		case constants.ADD_REACTION_SUCCESS: {
			const { message, channel, reaction } = actions.payload;
			if (state.messages?.hasOwnProperty(channel)) {
				return {
					...state,
					messages: {
						...state.messages,
						[channel]: addReaction(
							message,
							reaction,
							state.messages[channel]
						),
					},
				};
			}
			return state;
		}
		case constants.DELETE_MESSAGE_SUCCESS: {
			const { message, channel, isConsult, parentMessageId } =
				actions.payload;
			if (isConsult) {
				return {
					...state,
					consultChats: {
						...state.consultChats,
						[parentMessageId]: removeItemFromArray(
							message.messageId,
							state.consultChats[parentMessageId],
							"messageId"
						),
					},
				};
			}
			if (message.error) {
				return {
					...state,
					pendingMessages: {
						...state.pendingMessages,
						[channel.url]: removeItemFromArray(
							message.messageId,
							state.pendingMessages[channel.url],
							"messageId"
						),
					},
				};
			}
			return {
				...state,
				messages: {
					...state.messages,
					[channel.url]: removeItemFromArray(
						message.messageId,
						state.messages[channel.url],
						"messageId"
					),
				},
			};
		}
		case constants.MARK_AS_DELIVERED: {
			const { channelUrl } = actions.payload;
			return {
				...state,
				messages: {
					...state.messages,
					[channelUrl]: state.messages[channelUrl].map((item) => ({
						...item,
						isDelivered: true,
					})),
				},
			};
		}
		case constants.MARK_AS_READ: {
			const { channelUrl } = actions.payload;
			return {
				...state,
				messages: {
					...state.messages,
					[channelUrl]: state.messages[channelUrl].map((item) => ({
						...item,
						isRead: true,
					})),
				},
			};
		}
		case constants.GET_CONSULTS: {
			return {
				...state,
				loadingConsults: true,
			};
		}
		case constants.GET_CONSULTS_SUCCESS: {
			const { id, consults, type } = actions.payload;
			const { nextShiftConsults = [] } = state;
			return {
				...state,
				consults: {
					...state.consults,
					[id]: consults,
				},
				nextShiftConsults:
					type === "nextshift"
						? _.uniqBy([...nextShiftConsults, ...consults], "_id")
						: nextShiftConsults,
			};
		}
		case constants.RESOLVE_CONSULT_SUCCESS: {
			return {
				...state,
			};
		}
		case constants.REOPEN_CONSULT_SUCCESS: {
			const { consult, msg } = actions.payload;
			const { channel, _id } = consult;
			const $messages = remapMessages(msg, state.messages[channel]);
			const isExists = state?.consults?.hasOwnProperty(channel);
			const $consults = isExists
				? state?.consults[channel].map((item) => {
						if (item._id === _id) {
							return JSON.parse(msg.data);
						}
						return item;
				  })
				: [];
			return {
				...state,
				messages: {
					...state.messages,
					[channel]: $messages,
				},
				consults: {
					...state.consults,
					[channel]: $consults,
				},
				consultAllChats: remapConsultChats(
					msg.data,
					state.consultAllChats,
					consult.chatUrl
				),
			};
		}
		case constants.ARCHIVE_CONSULT_SUCCESS: {
			const { consult, msg } = actions.payload;
			const { channel, _id } = consult;
			const $messages = remapMessages(msg, state.messages[channel]);
			const isExists = state?.consults?.hasOwnProperty(channel);
			const $consults = isExists
				? state.consults[channel].map((item) => {
						if (item._id === _id) {
							return JSON.parse(msg.data);
						}
						return item;
				  })
				: [];
			return {
				...state,
				messages: {
					...state.messages,
					[channel]: $messages,
				},
				consults: {
					...state.consults,
					[channel]: $consults,
				},
			};
		}
		case constants.DELETE_CHAT_SUCCESS:
		case constants.LEAVE_CHANNEL_SUCCESS: {
			const { channel } = actions.payload;
			return {
				...state,
				chats: state.chats.filter((chat) => chat.url !== channel),
			};
		}
		case constants.DELETE_CONSULT_SUCCESS: {
			const { consult, message } = actions.payload;
			const { channel } = consult;
			return {
				...state,
				messages: {
					...state.messages,
					[channel]: removeItemFromArray(
						message.messageId,
						state.messages[channel],
						"messageId"
					),
				},
				consults: {
					...state.consults,
					[channel]: removeItemFromArray(
						consult._id,
						state.consults[channel],
						"_id"
					),
				},
			};
		}
		case constants.UPDATE_CONSULT_SUCCESS: {
			const { consult, message } = actions.payload;
			const { channel } = consult;
			return {
				...state,
			};
		}
		case constants.FETCH_PREVIOUS_MESSAGES: {
			return {
				...state,
				loadingPreviousMessages: true,
			};
		}
		case constants.FETCH_PREVIOUS_MESSAGES_FAILED: {
			return {
				...state,
				loadingPreviousMessages: false,
			};
		}
		case constants.FETCH_PREVIOUS_MESSAGES_SUCCESS: {
			const { messages, id } = actions.payload;
			return {
				...state,
				loadingPreviousMessages: false,
				messages: {
					...state.messages,
					[id]: _.uniqBy(
						[...state.messages[id], ...messages],
						"messageId"
					),
				},
			};
		}
		case LOG_OUT_SUCCESS:
			return initialState;
		default:
			return state;
	}
};

const remapConsult = (consult, array = []) => {
	return array.map((item) => {
		if (item._id === consult._id) {
			return consult;
		} else {
			return item;
		}
	});
};

// const cleanupChat = (messages = {}) => {
//   const msgs = Object.values(messages).map((item) => item.splice(0, 60));
//   const newMsg = {};
//   Object.keys(messages).forEach((item, index) => {
//     newMsg[item] = msgs[index];
//   });
//   return newMsg;
// };

const remapMessages = (newMessage, msgArray = []) => {
	// console.log(msgArray);
	return msgArray.map((item) => {
		if (item.messageId === newMessage.messageId) {
			return newMessage;
		} else {
			return item;
		}
	});
};

const addReaction = (message, reaction, msgArray = []) => {
	return msgArray.map((item) => {
		if (item.messageId === message) {
			const reactions = [...item.reactions];
			const reactionIndex = reactions.findIndex(
				(x) => x.key === reaction.key
			);
			console.log("index", reactionIndex);
			if (reactionIndex === -1) {
				reactions.push({
					key: reaction.key,
					userIds: [reaction.userId],
				});
			} else {
				if (reaction.operation === "add") {
					reactions[reactionIndex] = {
						...reactions[reactionIndex],
						userIds: [
							...reactions[reactionIndex].userIds,
							reaction.userId,
						],
					};
				} else {
					if (reactions[reactionIndex].userIds.length >= 2) {
						reactions[reactionIndex] = {
							...reactions[reactionIndex],
							userIds: reactions[reactionIndex].userIds.filter(
								(x) => x !== reaction.userId
							),
						};
					} else {
						reactions[reactionIndex] = {
							key: "deleted",
							userIds: [],
						};
					}
				}
			}
			console.log("iem", {
				...item,
				reactions,
			});
			return {
				...item,
				reactions,
			};
		} else {
			return item;
		}
	});
};

const sortConsults = (consultChats) => {
	const newConsults = consultChats.map((item) => ({
		...item,
		sortTime: item?.lastMessage?.createdAt || item.createdAt,
	}));
	// console.log(
	//   'timesort',
	//   newConsults.map((item) => item.sortTime),
	// );
	return _.orderBy(newConsults, ["sortTime"], "desc");
	// return consultChats;
};

const remapConsultChats = (newChat, chatArray = [], url) => {
	return chatArray.map((item) => {
		if (item.url === url) {
			return {
				...item,
				data: newChat,
			};
		} else {
			return item;
		}
	});
};

const processPendingMessages = (messages = {}) => {
	const messagesArray = Object.values(messages);
	const newMessageArray = messagesArray.map((item) => {
		return item.map((msg) => ({
			...msg,
			sending: false,
			sent: false,
			error: true,
		}));
	});
	const messageKeys = Object.keys(messages);
	let pendingMessages = {};
	newMessageArray.forEach((item, index) => {
		pendingMessages[messageKeys[index]] = item;
	});
	return pendingMessages;
};

export default ConsultReducer;
