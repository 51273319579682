import Loader from "components/loader";
import { getCurrentUser, updateUser } from "containers/auth/actions";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function Profile() {
	const user = useSelector((state) => state.auth.user);
	const [firstName, setFirstName] = React.useState(user?.firstName);
	const [lastName, setLastName] = React.useState(user?.lastName);
	const [email, setEmail] = React.useState(user?.email);
	const [profession, setProfession] = React.useState(user?.profession);
	const [title, setTitle] = React.useState(user.title);
	const [organization, setOrganization] = React.useState(user?.organization);
	const [address, setAdress] = React.useState(user?.organizationCity);
	const [phone, setPhone] = React.useState(user?.phone);
	const dispatch = useDispatch();
	const [loading, setLoading] = React.useState(false);

	useEffect(() => {
		setFirstName(user?.firstName);
		setLastName(user?.lastName);
		setEmail(user?.email);
		setProfession(user?.profession);
		setTitle(user?.title);
		setOrganization(user?.organization);
		setAdress(user?.organizationCity);
		setPhone(user?.phone);
	}, [user]);

	const updateProfile = () => {
		setLoading(true);
		dispatch(
			updateUser({
				profession,
				title,
				organization,
				organizationCity: address,
				organizationState: user?.organizationState,
				email,
				phone,
				// profilePhoto: photoBase64 || "",
				firstName,
				lastName,
				onNext: () => {
					setLoading(false);
					dispatch(getCurrentUser());
					alert("Profile Updated");
				},
			})
		);
	};

	return (
		<div className="overflow-hidden bg-white sm:rounded-lg w-full">
			<div className="px-4 py-5 sm:px-6">
				<h3 className="text-lg font-medium leading-6 text-gray-900">
					Profile
				</h3>
			</div>
			<div className="border-t border-gray-200 p-8">
				<div className="flex flex-row w-full  items-center justify-between space-x-3">
					<div className="flex-1">
						<label
							htmlFor="email-address"
							className="font-semibold text-sm"
						>
							First name
						</label>
						<input
							className="focus:outline-none border-transparent appearance-none w-full p-3 bg-white ring-1 ring-gray-300 focus:ring-login-blue focus:border-login-blue rounded mt-2 focus:z-10 sm:text-sm mb-6"
							placeholder="First name"
							value={firstName}
							onChange={(e) => setFirstName(e.target.value)}
						/>
					</div>
					<div className="flex-1">
						<label
							htmlFor="email-address"
							className="font-semibold text-sm"
						>
							Last name
						</label>
						<input
							className="focus:outline-none border-transparent appearance-none w-full p-3 bg-white ring-1 ring-gray-300 focus:ring-login-blue focus:border-login-blue rounded mt-2 focus:z-10 sm:text-sm mb-6"
							placeholder="First name"
							value={lastName}
							onChange={(e) => setLastName(e.target.value)}
						/>
					</div>
				</div>
				<div className="flex flex-row w-full  items-center justify-between space-x-3">
					<div className="flex-1">
						<label
							htmlFor="email-address"
							className="font-semibold text-sm"
						>
							Email Address
						</label>
						<input
							className="focus:outline-none border-transparent appearance-none w-full p-3 bg-gray-100 ring-1 ring-gray-300  rounded mt-2 focus:z-10 sm:text-sm mb-6"
							placeholder="Email Address"
							value={email}
							readOnly
							onChange={(e) => setEmail(e.target.value)}
						/>
					</div>
					<div className="flex-1">
						<label
							htmlFor="email-address"
							className="font-semibold text-sm"
						>
							Phone Number
						</label>
						<input
							className="focus:outline-none border-transparent appearance-none w-full p-3 bg-gray-100 ring-1 ring-gray-300 rounded mt-2 focus:z-10 sm:text-sm mb-6"
							placeholder="Phone Number"
							value={phone}
                            readOnly
							onChange={(e) => setPhone(e.target.value)}
						/>
					</div>
				</div>
				<div className="flex flex-row w-full  items-center justify-between space-x-3">
					<div className="flex-1">
						<label
							htmlFor="email-address"
							className="font-semibold text-sm"
						>
							Profession
						</label>
						<input
							className="focus:outline-none border-transparent appearance-none w-full p-3 bg-white ring-1 ring-gray-300 focus:ring-login-blue focus:border-login-blue rounded mt-2 focus:z-10 sm:text-sm mb-6"
							placeholder="Profession"
							value={profession}
							onChange={(e) => setProfession(e.target.value)}
						/>
					</div>
					<div className="flex-1">
						<label
							htmlFor="email-address"
							className="font-semibold text-sm"
						>
							Title
						</label>
						<input
							className="focus:outline-none border-transparent appearance-none w-full p-3 bg-white ring-1 ring-gray-300 focus:ring-login-blue focus:border-login-blue rounded mt-2 focus:z-10 sm:text-sm mb-6"
							placeholder="Title"
							value={title}
							onChange={(e) => setTitle(e.target.value)}
						/>
					</div>
				</div>
				<div className="flex flex-row w-full  items-center justify-between space-x-3">
					<div className="flex-1">
						<label
							htmlFor="email-address"
							className="font-semibold text-sm"
						>
							Address
						</label>
						<input
							className="focus:outline-none border-transparent appearance-none w-full p-3 bg-white ring-1 ring-gray-300 focus:ring-login-blue focus:border-login-blue rounded mt-2 focus:z-10 sm:text-sm mb-6"
							placeholder="NPI"
							value={address}
							onChange={(e) => setAdress(e.target.value)}
						/>
					</div>
					<div className="flex-1">
						<label
							htmlFor="email-address"
							className="font-semibold text-sm"
						>
							Organization
						</label>
						<input
							className="focus:outline-none border-transparent appearance-none w-full p-3 bg-white ring-1 ring-gray-300 focus:ring-login-blue focus:border-login-blue rounded mt-2 focus:z-10 sm:text-sm mb-6"
							placeholder="Organization"
							value={organization}
							onChange={(e) => setOrganization(e.target.value)}
						/>
					</div>
				</div>
				<div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
					<button
						type="button"
						className="self-end w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-health text-base font-medium text-white focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-login-blue sm:ml-3 sm:w-36 sm:text-sm"
						onClick={updateProfile}
					>
						{loading ? (
							<Loader color="text-white" />
						) : (
							"Save Profile"
						)}
					</button>
				</div>
			</div>
		</div>
	);
}
