export const FETCH_DIRECTORY = 'FETCH_DIRECTORY';
export const FETCH_DIRECTORY_SUCCESS = 'FETCH_DIRECTORY_SUCCESS';
export const FETCH_DIRECTORY_FAILED = 'FETCH_DIRECTORY_FAILED';

export const FETCH_BLOCKED_USERS = 'FETCH_BLOCKED_USERS';
export const FETCH_BLOCKED_USERS_SUCCESS = 'FETCH_BLOCKED_USERS_SUCCESS';
export const FETCH_BLOCKED_USERS_FAILED = 'FETCH_BLOCKED_USERS_FAILED';

export const BLOCK_USER = 'BLOCK_USER';
export const BLOCK_USER_SUCCESS = 'BLOCK_USER_SUCCESS';
export const BLOCK_USER_FAILED = 'BLOCK_USER_FAILED';

export const UNBLOCK_USER = 'UNBLOCK_USER';
export const UNBLOCK_USER_SUCCESS = 'UNBLOCK_USER_SUCCESS';
export const UNBLOCK_USER_FAILED = 'UNBLOCK_USER_FAILED';

export const CREATE_TEAM = 'CREATE_TEAM';
export const CREATE_TEAM_SUCCESS = 'CREATE_TEAM_SUCCESS';
export const CREATE_TEAM_FAILED = 'CREATE_TEAM_FAILED';

export const GET_TEAMS = 'GET_TEAMS';
export const GET_TEAMS_SUCCESS = 'GET_TEAMS_SUCCESS';
export const GET_TEAMS_FAILED = 'GET_TEAMS_FAILED';

export const DELETE_TEAM = 'DELETE_TEAM';
export const DELETE_TEAM_SUCCESS = 'DELETE_TEAM_SUCCESS';
export const DELETE_TEAM_FAILED = 'DELETE_TEAM_FAILED';

export const ADD_TEAM_USERS = 'ADD_TEAM_USERS';
export const ADD_TEAM_USERS_SUCCESS = 'ADD_TEAM_USERS_SUCCESS';
export const ADD_TEAM_USERS_FAILED = 'ADD_TEAM_USERS_FAILED';

export const REMOVE_TEAM_USERS = 'REMOVE_TEAM_USERS';
export const REMOVE_TEAM_USERS_SUCCESS = 'REMOVE_TEAM_USERS_SUCCESS';
export const REMOVE_TEAM_USERS_FAILED = 'REMOVE_TEAM_USERS_FAILED';

export const UPDATE_TEAM = 'UPDATE_TEAM';
export const UPDATE_TEAM_SUCCESS = 'UPDATE_TEAM_SUCCESS';
export const UPDATE_TEAM_FAILED = 'UPDATE_TEAM_FAILED';

export const ADD_PROXY = 'ADD_PROXY';
export const ADD_PROXY_SUCCESS = 'ADD_PROXY_SUCCESS';
export const ADD_PROXY_FAILED = 'ADD_PROXY_FAILED';

export const GET_TEAM_BY_ID = 'GET_TEAM_BY_ID';
export const GET_TEAM_BY_ID_SUCCESS = 'GET_TEAM_BY_ID_SUCCESS';
export const GET_TEAM_BY_ID_FAILED = 'GET_TEAM_BY_ID_FAILED';

export const LEAVE_TEAM = 'LEAVE_TEAM';
export const LEAVE_TEAM_SUCCESS = 'LEAVE_TEAM_SUCCESS';
export const LEAVE_TEAM_FAILED = 'LEAVE_TEAM_FAILED';
