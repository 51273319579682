import ChangePassword from "components/changePassword";
import Profile from "components/profile";
import StatusMessage from "components/statusMessage";
import { getCurrentUser, logOut } from "containers/auth/actions";
import { useEffect, useState } from "react";
import { BiHelpCircle, BiKey, BiUserCircle } from "react-icons/bi";
import { BsHouseDoor } from "react-icons/bs";
import { FiLogOut } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import sendBird from "services/sendbird";

const Settings = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const user = useSelector((state) => state.auth.user);
	const [active, setActive] = useState("profile");
	// const { boot, show } = useIntercom();

	const logout = async () => {
		dispatch(logOut());
		setTimeout(() => {
			navigate("/login");
			window.location.reload(false);
		}, 0);
	};

	useEffect(() => {
		dispatch(getCurrentUser());
		sendBird.connect(user);
	}, []);

	const renderPages = () => {
		switch (active) {
			case "profile":
				return <Profile />;
			case "password":
				return <ChangePassword />;
			case "status":
				return <StatusMessage />;
			default:
				return <Profile />;
		}
	};

	return (
		<div>
			<title>Settings</title>
			<div className="bg-white flex flex-row w-full min-w-[60rem] min-h-[30rem] h-screen">
				<div className="flex flex-col">
					{/* Sidebar -------- */}
					<div className="sidebar min-w-[350px] h-full border-r-2 border-r-gray-100 p-4">
						<div className="flex flex-row items-center justify-between">
							<h1 className="font-bold text-xl">Settings</h1>
							<button
								className="flex flex-row items-center justify-center rounded-full p-2"
								onClick={() => navigate("/")}
							>
								<BsHouseDoor className="w-6 h-6 text-gray-500" />
							</button>
						</div>
						<div className="flex flex-col m-4 space-y-2">
							<button
								className={`flex flex-row items-center rounded-xl p-2 pl-3 ${
									active === "profile" && "bg-health-20"
								}`}
								onClick={() => setActive("profile")}
							>
								<BiUserCircle
									className={`w-6 h-6 ${
										active === "profile" ? "text-health" : "text-gray-500"
									}`}
								/>
								<div
									className={`ml-4 font-medium text-lg ${
										active === "profile" ? "text-health" : "text-gray-500"
									}`}
								>
									Profile
								</div>
							</button>
							<button
								className={`flex flex-row items-center rounded-xl p-2 pl-3 ${
									active === "password" && "bg-health-20"
								}`}
								onClick={() => setActive("password")}
							>
								<BiKey
									className={`w-6 h-6 ${
										active === "password" ? "text-health" : "text-gray-500"
									}`}
								/>
								<div
									className={`ml-4 font-medium text-lg ${
										active === "password" ? "text-health" : "text-gray-500"
									}`}
								>
									Change Password
								</div>
							</button>
							{/* <button
								className={`flex flex-row items-center rounded-xl p-2 pl-3 ${
									active === "status" && "bg-health-20"
								}`}
								onClick={() => setActive("status")}
							>
								<BiMessageAltCheck
									className={`w-6 h-6 ${
										active === "status" ? "text-health" : "text-gray-500"
									}`}
								/>
								<div
									className={`ml-4 font-medium text-lg ${
										active === "status" ? "text-health" : "text-gray-500"
									}`}
								>
									Set Status Message
								</div>
							</button> */}
							{/* <button
								className={`flex flex-row items-center rounded-xl p-2 pl-3 ${
									active === "verification" && "bg-health-20"
								}`}
								onClick={() => setActive("verification")}
							>
								<MdOutlineVerified className="w-6 h-6 text-gray-500" />
								<div className="ml-4 font-medium text-lg text-gray-500">
									Verification Requests
								</div>
							</button> */}
							<button
								className={`flex flex-row items-center rounded-xl p-2 pl-3 ${
									active === "help" && "bg-health-20"
								}`}
								id={"intercom-launcher"}
								// onClick={() => openIntercomChat}
							>
								<BiHelpCircle
									className={`w-6 h-6 ${
										active === "help" ? "text-health" : "text-gray-500"
									}`}
								/>
								<div
									className={`ml-4 font-medium text-lg ${
										active === "help" ? "text-health" : "text-gray-500"
									}`}
								>
									Help
								</div>
							</button>
							<button
								className={`flex flex-row items-center rounded-xl p-2 pl-3`}
								onClick={logout}
							>
								<FiLogOut className="w-6 h-6 text-red-500" />
								<div className="ml-4 font-medium text-lg text-red-500">
									Log Out
								</div>
							</button>
						</div>
					</div>

					{/* Pages -------- */}
				</div>
				{renderPages()}
			</div>
		</div>
	);
};

export default Settings;
