export const isProduction = true;

export const urls = {
	login: "/auth/login",
	generatePhoneCode: "/auth/phoneCode",
	verifyPhoneCode: "/auth/phoneCode/verify",
	register: "/auth/register",
	resetPassword: "/auth/sendResetPassword",
	verification: "/users/verifications",
	searchMedicalWords: "/medical-words/search-term",
	changePassword: "users/change-password",
	getSingleUser: "/users/single",
	updateSingleUser: "/users/single",
	searchDirectory: "/users/directory",
	addTeamMember: "/users/teammates/add",
	removeTeamMember: "/users/teammates/remove",
	updateStatus: "/users/status",
	createConsult: "/chats/queries",
	getUsers: "/users/batch",
	team: "/teams",
	adminMessage: (channel) =>
		`/send-bird/admin/group-channel/${channel}/messages`,
	sendNotification: "/firebase/send-notification",
	userPhotouploadSignedUrl: "users/upload-profile-pic-url",
	uploadSignedUrl: (teamId) => `teams/${teamId}/upload-logo-url`,
	deleteTeam: (id) => `/teams/${id}`,
	leaveTeam: (id) => `/teams/${id}/leave`,
	getTeam: (id) => `/teams/${id}`,
	addTeamUser: (id) => `/teams/${id}/users/add`,
	removeTeamUser: (id) => `/teams/${id}/users/remove`,
	addProxyUser: (id) => `/teams/${id}/proxies/add`,
	removeProxyUser: (id) => `/teams/${id}/proxies/remove`,
	getConsults: (id) => `/chats/queries/${id}`,
	getUser: (id) => `users/${id}`,
	resolveConsult: (id) => `/chats/queries/${id}/resolve`,
	reopenConsult: (id) => `/chats/queries/${id}/reopen`,
	archiveConsult: (id) => `/chats/queries/${id}/archive`,
	deleteConsult: (id) => `/chats/queries/${id}/delete`,
	updateConsult: (id) => `/chats/queries/${id}/update`,
};

export const stateList = [
	{ label: "Alabama", value: "Alabama" },
	{ label: "Alaska", value: "Alaska" },
	{ label: "Arizona", value: "Arizona" },
	{ label: "Arkansas", value: "Arkansas" },
	{ label: "California", value: "California" },
	{ label: "Colorado", value: "Colorado" },
	{ label: "Connecticut", value: "Connecticut" },
	{ label: "Delaware", value: "Delaware" },
	{ label: "Florida", value: "Florida" },
	{ label: "Georgia", value: "Georgia" },
	{ label: "Hawaii", value: "Hawaii" },
	{ label: "Idaho", value: "Idaho" },
	{ label: "Illinois", value: "Illinois" },
	{ label: "Indiana", value: "Indiana" },
	{ label: "Iowa", value: "Iowa" },
	{ label: "Kansas", value: "Kansas" },
	{ label: "Kentucky", value: "Kentucky" },
	{ label: "Louisiana", value: "Louisiana" },
	{ label: "Maine", value: "Maine" },
	{ label: "Maryland", value: "Maryland" },
	{ label: "Massachusetts", value: "Massachusetts" },
	{ label: "Michigan", value: "Michigan" },
	{ label: "Minnesota", value: "Minnesota" },
	{ label: "Mississippi", value: "Mississippi" },
	{ label: "Missouri", value: "Missouri" },
	{ label: "Montana", value: "Montana" },
	{ label: "Nebraska", value: "Nebraska" },
	{ label: "Nevada", value: "Nevada" },
	{ label: "New Hampshire", value: "New Hampshire" },
	{ label: "New Jersey", value: "New Jersey" },
	{ label: "New Mexico", value: "New Mexico" },
	{ label: "New York", value: "New York" },
	{ label: "North Carolina", value: "North Carolina" },
	{ label: "North Dakota", value: "North Dakota" },
	{ label: "Ohio", value: "Ohio" },
	{ label: "Oklahoma", value: "Oklahoma" },
	{ label: "Oregon", value: "Oregon" },
	{ label: "Pennsylvania", value: "Pennsylvania" },
	{ label: "Rhode Island", value: "Rhode Island" },
	{ label: "South Carolina", value: "South Carolina" },
	{ label: "South Dakota", value: "South Dakota" },
	{ label: "Tennessee", value: "Tennessee" },
	{ label: "Texas", value: "Texas" },
	{ label: "Utah", value: "Utah" },
	{ label: "Vermont", value: "Vermont" },
	{ label: "Virginia", value: "Virginia" },
	{ label: "Washington", value: "Washington" },
	{ label: "West Virginia", value: "West Virginia" },
	{ label: "Wisconsin", value: "Wisconsin" },
	{ label: "Wyoming", value: "Wyoming" },
];

export const professionsList = [
	{ label: "Pharmacy", value: "pharmacy" },
	{ label: "Medicine", value: "medicine" },
	{ label: "Dental", value: "dental" },
	{ label: "Nursing", value: "nursing" },
	{
		label: "Affiliated Healthcare Professional",
		value: "affiliated_healthcare_professional",
	},
];

export const formatPhoneNumber = (phoneNumberString) => {
	if (phoneNumberString.includes("+") && phoneNumberString.length === 11) {
		return phoneNumberString;
	}

	let phone = phoneNumberString.replace(/\D/g, "");
	const match = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);

	if (phoneNumberString.length < 3) {
		return phone;
	}

	if (match) {
		phone = `(${match[1]}${match[2] ? ") " : ""}${match[2]}${
			match[3] ? "-" : ""
		}${match[3]}`;
		return phone;
	}

	return phoneNumberString;
};

export const emojiObject = {
	sendbird_emoji_heart_eyes:
		"https://static.sendbird.com/icons/emoji_heart_eyes.png",
	sendbird_emoji_laughing:
		"https://static.sendbird.com/icons/emoji_laughing.png",
	sendbird_emoji_rage: "https://static.sendbird.com/icons/emoji_rage.png",
	sendbird_emoji_sob: "https://static.sendbird.com/icons/emoji_sob.png",
	sendbird_emoji_sweat_smile:
		"https://static.sendbird.com/icons/emoji_sweat_smile.png",
	sendbird_emoji_thumbsup:
		"https://static.sendbird.com/icons/emoji_thumbsup.png",
	sendbird_emoji_thumbsdown:
		"https://static.sendbird.com/icons/emoji_thumbsdown.png",
};

export const emojis = [
	{
		key: "sendbird_emoji_heart_eyes",
		url: "https://static.sendbird.com/icons/emoji_heart_eyes.png",
	},
	{
		key: "sendbird_emoji_laughing",
		url: "https://static.sendbird.com/icons/emoji_laughing.png",
	},
	{
		key: "sendbird_emoji_rage",
		url: "https://static.sendbird.com/icons/emoji_rage.png",
	},
	{
		key: "sendbird_emoji_sob",
		url: "https://static.sendbird.com/icons/emoji_sob.png",
	},
	{
		key: "sendbird_emoji_sweat_smile",
		url: "https://static.sendbird.com/icons/emoji_sweat_smile.png",
	},
	{
		key: "sendbird_emoji_thumbsup",
		url: "https://static.sendbird.com/icons/emoji_thumbsup.png",
	},
	{
		key: "sendbird_emoji_thumbsdown",
		url: "https://static.sendbird.com/icons/emoji_thumbsdown.png",
	},
];
