import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import sendBird from "services/sendbird";
import "./App.css";
import Consults from "./pages/consults";
import Login from "./pages/login";
import Settings from "./pages/settings";

import { onMessageReceived } from "containers/global/actions";
import Signup from "pages/signup";
import { IntercomProvider } from "./utils/intercomProvider";
import PhemeContextProvider from "store/phemeContext";

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const onMessageReceive = (msg) => {
    dispatch(onMessageReceived(msg));
  };

  const onMessageDeleted = (msg) => {
    // dispatch(dele(msg));
  };

  useEffect(() => {
    if (user) {
      sendBird.connect(user);
      sendBird.subscribeToChat(onMessageReceive, onMessageDeleted);
    }
  }, [user]);

  return (
    <IntercomProvider>
      <PhemeContextProvider>
      <Routes>
        <Route path="/consults" element={<Consults />} />
        <Route path="/signup" element={<Signup />} />
        {/* <Route path="/archived" element={<Archived />} /> */}
        <Route path="/login" element={<Login />} />
        {/* <Route path="/teams" element={<Teams />} /> */}
        <Route path="/settings" element={<Settings />} />
        <Route path="/" element={user ? <Consults /> : <Login />} />
      </Routes>
      </PhemeContextProvider>
    </IntercomProvider>
  );
}

export default App;
