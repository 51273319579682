import styled from "styled-components";

export const Page = styled.section`
	display: flex;
	flex-direction: row;
	min-height: 100vh;
	height: 100%;
	@media (max-width: 900px) {
		flex-direction: column;
	}
`;

export const FormFieldTab = styled.div`
	width: 300px;
	display: flex;
	flex-direction: row;
	gap: 2px;
	border-radius: 5px;
	overflow: hidden;
	border: 1px solid var(--FIELD_BORDER);
	background-color: var(--FIELD_BG);
	padding: 2px;
`;

export const FormFieldTabItem = styled.div`
	flex: 1;
	text-align: center;
	background-color: ${(props) =>
		props.active ? "var(--PRIMARY)" : "var(--FIELD_BG)"};
	font-weight: ${(props) => (props.active ? 600 : 400)};
	color: ${(props) => (props.active ? "white" : "black")};
	padding: 10px 15px;
	border-radius: 4px;
	cursor: pointer;
`;

export const FormSection = styled.div`
	padding: 20px;
	width: 60%;

	@media (max-width: 900px) {
		width: 100%;
		order: 1;
	}

	& .logo {
		position: absolute;
		top: 20px;
		left: 20px;
		height: 40px;
		user-select: none;
	}

	& .button-container {
		display: flex;
		flex-direction: row;
		gap: 10px;
	}

	& .container {
		padding-top: 60px;
		padding-bottom: 40px;
		max-width: 550px;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: center;
	}

	& .head {
		margin-bottom: 30px;
	}

	& .title {
		font-size: 46px;
		font-weight: 700;
		margin-bottom: 10px;
	}

	& .text-account {
		font-size: 16px;
		font-weight: 500;
	}

	& .text-description {
		font-size: 16px;
		color: #6b7280;
		white-space: pre-wrap;
	}

	& .form-field-column {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}

	& .form-field-row {
		display: flex;
		flex-direction: row;
		gap: 10px;

		@media (max-width: 900px) {
			flex-direction: column;
		}
	}

	& .form-field-checkbox-row {
		position: relative;
		display: flex;
		flex-direction: row;
		gap: 10px;

		& input[type="checkbox"] {
			background-color: var(--FIELD_BG);
			border: 1px solid var(--FIELD_BORDER);
			width: 20px;
			height: 20px;
			border-radius: 3px;
			cursor: pointer;

			&:checked {
				background-color: var(--PRIMARY);
				border: 1px solid var(--PRIMARY);
			}

			&:focus {
				outline: 1px solid var(--PRIMARY);
				outline-offset: 2px;
			}
		}
	}

	& .form-field-checkbox-container {
		position: relative;
		display: flex;
		flex-direction: column;
		padding-bottom: 20px;
	}

	& .password-field-container {
		position: relative;
	}

	& .show-hide-button {
		position: absolute;
		top: 50%;
		right: 10px;
		transform: translateY(-50%);
		background: none;
		border: none;
		font-size: 14px;
		font-weight: 500;
		color: var(--PRIMARY);
		cursor: pointer;
		padding: 0;
	}

	& .form-field-container {
		position: relative;
		display: flex;
		flex-direction: column;
		gap: 10px;
		width: 50%;
		flex-grow: 1;
		padding-bottom: 20px;

		@media (max-width: 900px) {
			width: 100%;
		}

		& .label {
			font-size: 16px;
		}

		& .field {
			background-color: var(--FIELD_BG);
			border: 1px solid var(--FIELD_BORDER);
			border-radius: 5px;
			padding-top: 0.5rem;
			padding-right: 0.75rem;
			padding-bottom: 0.5rem;
			padding-left: 0.75rem;
			font-size: 16px;
			line-height: 1.5rem;
			--tw-ring-color: none;

			&:focus {
				background-color: var(--FIELD_BG_FOCUS);
				border: 1px solid var(--PRIMARY);
			}
		}
	}

	& .error-global {
		margin-top: 20px;
		width: 100%;
		padding: 10px 20px;
		background-color: #fadbdb;
		border: 1px solid #f3c9c9;
		border-radius: 5px;
		font-size: 12px;
		font-weight: 500;
		color: #a20606;
		line-height: 1.5em;
	}

	& .error-msg {
		position: absolute;
		left: 0;
		bottom: 0;
		font-size: 12px;
		color: red;
	}

	& .forgot-password-container {
		display: flex;
		justify-content: flex-end;
	}

	& .inline-button {
		font-size: 16px;
		font-weight: 600;
		color: var(--PRIMARY);
		opacity: 1;
		transition: all 150ms;
		cursor: pointer;

		&:hover {
			opacity: 0.8;
		}
	}
`;

export const InfoSection = styled.div`
	padding: 20px;
	width: 40%;
	background-color: #edfffb;
	border-left: 2px solid #e1f8f3;

	@media (max-width: 900px) {
		width: 100%;
		order: 2;
	}

	& .container {
		max-width: 400px;
		padding-top: 60px;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		gap: 20px;
		height: 100%;
		justify-content: center;
	}

	& .title {
		font-size: 32px;
		font-weight: 700;
	}

	& .description {
		font-size: 16px;
		color: #6b7280;
		white-space: pre-wrap;
	}

	& .review-box {
		background-color: var(--PRIMARY);
		color: #fbfffe;
		padding: 20px;
		border-radius: 10px;

		& .name {
			font-size: 24px;
			font-weight: 700;
		}

		& .meta {
			font-size: 14px;
			font-weight: 500;
		}

		& .text {
			font-size: 14px;
			margin-top: 10px;
			font-weight: 500;
		}
	}
`;
