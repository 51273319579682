import { createContext, useContext, useState } from "react";

const PhemeContext = createContext(null);

export function usePhemeContext() {
	const context = useContext(PhemeContext);

	if (context === null) {
		throw new Error(
			"useListingContext must be used within a ListingContextProvider"
		);
	}

	return context;
}
export default function PhemeContextProvider({ children }) {
	const [navDrawerOpen, setNavDrawerOpen] = useState(false);

	const value = {
		navDrawerOpen,
		setNavDrawerOpen,
	};

	return (
		<PhemeContext.Provider value={value}>
			{children}
		</PhemeContext.Provider>
	);
}
