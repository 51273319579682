import styled from "styled-components";

export const ButtonWrapper = styled.button`
	background-color: var(--PRIMARY);
	width: 70px;
	height: 70px;
	position: absolute;
	bottom: 120px;
	right: 10px;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const Icon = styled.span`
	// common styles for the icons
	width: 24px;
	height: 24px;
	margin-right: 8px;
`;

export const PlusIconStyled = styled.div`
	& > svg {
		width: 32px;
		height: 32px;
		color: white;
		transition: all 0.1s;
		transform: ${(props) => props.isPlusClicked && "rotate(45deg)"};
	}
`;

export const FabOptionsWrapper = styled.div`
	position: absolute;
	padding-right: 40px;
	padding-bottom: 5px;
	right: 0;
	bottom: 0;
`;

export const FabOptions = styled.div`
	position: relative;
	background-color: white;
	display: flex;
	flex-direction: column;
	padding: 5px;
	border-radius: 5px;
	gap: 3px;

	box-shadow: 0px 5px 10px #00000010;
`;

export const ListItem = styled.button`
	display: flex;
	flex-direction: row;
	gap: 5px;
	white-space: nowrap;
	align-items: center;
	padding: 10px;
	border-radius: 5px;
	font-size: 14px;
	font-weight: 500;
	color: var(--PRIMARY);
	min-width: 200px;

	& svg {
		fill: var(--PRIMARY);
	}

	&:hover {
		background-color: var(--PRIMARY_BG);
	}
`;

// export const RootPortal = styled.div`
// 	// styles for root-portal
// `;
