import { useState } from "react";
import { BsSearch } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import TeamItem from "./teamItem";

export default function Team({
	data,
	selectedChat,
	onSelect,
	hideSearch,
}) {
	const user = useSelector((state) => state.auth.user);
	const dispatch = useDispatch();
	const [searchTerm, setSearchTerm] = useState("");

	const onChatPress = (item) => {
		console.log("item", item);
		onSelect(item);
		// dispatch(fetchSingleChat({id: item.url, channel: item}))
	};

	const filteredData = data
		.filter(
			(item) =>
				item?.name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
				item?.lastMessage?.message
					?.toLowerCase()
					.includes(searchTerm?.toLowerCase())
		)
		.sort(function (a, b) {
			var dateA = new Date(a.createdAt),
				dateB = new Date(b.createdAt);
			return dateB - dateA;
		});

	console.log(filteredData);

	return (
		<>
			{!hideSearch && (
				<div class="w-full flex justify-end items-center relative px-4 my-4">
					<BsSearch
						size={18}
						className="absolute left-0 text-gray-400 ml-7"
					/>
					<input
						placeholder="Search Teams"
						className="w-full p-2 pl-10 rounded-full appearance-none bg-gray-100"
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
					/>
				</div>
			)}
			<div className="flex flex-col overflow-y-auto gap-1 flex-1 px-4 pb-4">
				{filteredData?.map((item) => (
					<TeamItem
						key={item._id}
						{...item}
						onSelect={() => onChatPress(item)}
						isActive={item._id === selectedChat?._id}
					/>
				))}
			</div>
		</>
	);
}
