import React from "react";

export default function Loader({ color, message = "" }) {
	return (
		<div className="flex items-center">
			<div
				class={`
        spinner-border
        animate-spin
        inline-block
        w-5
        h-5
        border-[2px]
        rounded-full
        ${color || "text-login-blue"}
    `}
				role="status"
			>
				<span className="visually-hidden">{message}</span>
			</div>
			<span className="ml-2 font-semibold text-lg">{message}</span>
		</div>
	);
}
