import {
	takeLatest,
	put,
	all,
	select,
	delay,
	takeLeading,
} from "redux-saga/effects";
import sendBird from "services/sendbird";
import { urls } from "utils/constants";
import { getApi } from "utils/helpers";
import { getMessages, getToken } from "utils/selectors";
import * as consultActions from "./actions";
import * as constants from "./constants";

function* fechAllChats(actions) {
	try {
		const { onSuccess } = actions.payload;
		const token = yield select(getToken);
		const api = yield getApi(token);
		const chats = yield sendBird.getChats();
		// console.log('unhidden', chats);
		const members = [];
		chats.forEach((item) => {
			item.members.forEach((user) => {
				members.push({
					_id: user?.userId,
					channelURL: item.url,
				});
			});
		});
		const { data } = yield api.post(urls.getUsers, { ids: members });
		// console.log('These are consults for', data);
		const memberData = {};
		const consultInfo = {};
		data.data.forEach((item) => {
			memberData[item._id] = item;
		});
		// data.consultCounts.forEach((item) => {
		//   consultInfo[item.channelURL] = item;
		// });
		const newChats = chats.map((chat) => ({
			...chat,
			consultInfo: { count: 0 },
			members: chat.members.map((item) => ({
				...item,
				...memberData[item.userId],
			})),
		}));
		if (onSuccess) {
			onSuccess();
		}
		yield put(
			consultActions.fetchAllChatsSuccess({
				chats: newChats,
			})
		);
		// yield put(consultActions.getAllConsult());
	} catch (e) {
		console.log(e);
		yield put(
			consultActions.fetchAllChatsFailed({ error: e?.response?.data })
		);
	}
}

function* fechAllConsultChats(actions) {
	try {
		const { onNext } = actions.payload;
		const token = yield select(getToken);
		const api = yield getApi(token);
		const chats = yield sendBird.getConsultChats();
		const members = [];
		chats.forEach((item) => {
			item.members.forEach((user) => {
				members.push({
					_id: user?.userId,
					channelURL: item.url,
				});
			});
		});
		console.log("chats", chats);
		const { data } = yield api.post(urls.getUsers, { ids: members });
		const memberData = {};
		const consultInfo = {};
		data.data.forEach((item) => {
			memberData[item._id] = item;
		});
		data.consultCounts.forEach((item) => {
			consultInfo[item.channelURL] = item;
		});
		const newChats = chats.map((chat) => ({
			...chat,
			consultInfo: consultInfo?.hasOwnProperty(chat.url)
				? consultInfo[chat.url]
				: { count: 0 },
			members: chat.members.map((item) => ({
				...item,
				...memberData[item.userId],
			})),
		}));
		yield put(
			consultActions.fetchAllConsultChatsSuccess({
				chats: newChats,
			})
		);
		if (onNext) {
			onNext();
		}
		// yield put(consultActions.getAllConsult());
	} catch (e) {
		console.log(e);
		yield put(
			consultActions.fetchAllConsultChatsFailed({
				error: e?.response?.data,
			})
		);
	}
}

function* addReaction(actions) {
	try {
		const { message, channel, reaction } = actions.payload;
		const response = yield sendBird.addReaction(reaction, message, channel);
		//   yield put(
		// 	consultActions.addReactionSuccess({
		// 	  message,
		// 	  channel,
		// 	  reaction: {...reaction, ...response},
		// 	}),
		//   );
		yield put(consultActions.fetchSingleChat({ id: channel}));
	} catch (e) {
		console.log(e);
		yield put(consultActions.addReactionFailed({ error: e.message }));
	}
}

function* fetchPreviousMessages(actions) {
	try {
		const { channel, onNext } = actions.payload;
		const chatChannel = yield sendBird.getChannel(channel);
		const channelMessages = yield select(getMessages);
		const messageArray = channelMessages[channel];
		const lastMessage = messageArray[messageArray.length - 1];
		const previousMessages = yield sendBird.fetchPreviousMessages(
			chatChannel,
			lastMessage
		);
		const isReadPromise = [];
		//   previousMessages.forEach((item) => {
		// 	isReadPromise.push(sendBird.getReadStatus(chatChannel, item));
		//   });
		//   const allPromise = yield Promise.all(isReadPromise);
		//   const messages = previousMessages
		// 	.map((item, index) => ({
		// 	  ...item,
		// 	  isRead: allPromise[index],
		// 	}))
		// 	.reverse();
		if (onNext) {
			onNext(previousMessages);
		}
		yield put(
			consultActions.fetchPreviousMessagesSuccess({
				messages: previousMessages,
				id: channel,
			})
		);
	} catch (e) {
		console.log(e);
		yield put(consultActions.fetchPreviousMessagesFailed({ error: e }));
	}
}

function* fetchSingleChat(actions) {
	const { id, channel, onSuccess } = actions.payload;
	try {
		const chats = yield sendBird.fetchMessages(id);
		const isReadPromise = [];
		chats.forEach((item) => {
			isReadPromise.push(sendBird.getReadStatus(id, item));
		});
		const allPromise = yield Promise.all(isReadPromise);
		const messages = chats.map((item, index) => ({
			...item,
			isRead: allPromise[index],
		}));
		if (onSuccess) {
			onSuccess();
		}
		yield put(
			consultActions.fetchSingleChatSuccess({ chats: messages, id })
		);
	} catch (e) {
		console.log("this is the error", e);
		if (onSuccess) {
			onSuccess();
		}
		yield put(consultActions.fetchSingleChatFailed({ error: e }));
	}
}

function* sendMessage(actions) {
	const {
		id,
		message,
		type,
		sender,
		createdAt,
		onProgress = () => {},
		extraData = "",
	} = actions.payload;
	try {
		let msg;
		if (type === "text") {
			msg = yield sendBird.sendMessage(message, id, extraData);
		}
		if (type === "gif") {
			msg = yield sendBird.sendMessage(message, id, type);
		}
		if (type === "file") {
			msg = yield sendBird.sendFile(
				message,
				id,
				undefined,
				undefined,
				undefined,
				extraData,
				onProgress
			);
		}
		if (msg) {
			yield put(
				consultActions.sendMessageSuccess({
					message: msg,
					id,
					createdAt,
				})
			);
			const channel = yield sendBird.getChannel(id);
			yield put(consultActions.fetchSingleChat({ id: id, channel }));
			yield put(consultActions.fetchAllChats({}));
			yield put(consultActions.fetchAllConsultChats({}));
		}
	} catch (e) {
		console.log(e);
		yield put(
			consultActions.sendMessageFailed({
				e,
				id,
				message,
				type,
				sender,
				createdAt,
			})
		);
	}
}

export default function* watchGlobal() {
	all([
		yield takeLatest(constants.FETCH_ALL_CHATS, fechAllChats),
		yield takeLatest(constants.FETCH_SINGLE_CHAT, fetchSingleChat),
		yield takeLatest(
			constants.FETCH_ALL_CONSULT_CHATS,
			fechAllConsultChats
		),
		yield takeLatest(
			constants.FETCH_PREVIOUS_MESSAGES,
			fetchPreviousMessages
		),
		yield takeLatest(constants.SEND_MESSAGE, sendMessage),
		yield takeLatest(constants.ADD_REACTION, addReaction),
	]);
}
