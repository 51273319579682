import Loader from "components/loader";
import {
	getCurrentUser,
	setStatusMessage,
	updateUser,
} from "containers/auth/actions";
import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";
import { getToken } from "utils/selectors";

export default function StatusMessage() {
	const user = useSelector((state) => state.auth.user);
	const [message, setMessage] = useState(user?.status.message);
	const [clearAfter, setClearAfter] = useState(new Date(user?.status?.clearAfter));
	const [showMessageInChat, setShowMessageInChat] = useState(
		user?.status?.showInChats
	);
	const dispatch = useDispatch();
	const [loading, setLoading] = React.useState(false);

	const onSave = () => {
		setLoading(true);
		dispatch(
			setStatusMessage({
				message,
				showInChats: showMessageInChat,
				teammates: [],
				clearAfter: clearAfter,
				onNext: () => {
                    alert("Status Message Updated");
					setLoading(false);
				},
			})
		);
	};

	return (
		<div className="overflow-hidden bg-white sm:rounded-lg w-full">
			<div className="px-4 py-5 sm:px-6">
				<h3 className="text-lg font-medium leading-6 text-gray-900">
					Set Status Message
				</h3>
			</div>
			<div className="border-t border-gray-200 p-8">
				<div className="flex w-[400px] flex-col items-start">
					<div className="w-full">
						<label
							htmlFor="email-address"
							className="font-semibold text-sm"
						>
							Message
						</label>
						<textarea
							id="chat"
							rows="1"
							cols={50}
							onChange={(e) => setMessage(e.target.value)}
							value={message}
							className="block p-2.5 mt-4 w-full text-sm resize-none bg-white
						 rounded-md min-h-[100px] border border-gray-300
						   dark:bg-gray-800 dark:border-gray-600
						    dark:placeholder-gray-400 dark:text-white"
							placeholder="message..."
						></textarea>
					</div>
					<div className="w-full mt-4">
						<label
							htmlFor="email-address"
							className="font-semibold text-sm"
						>
							Clear After
						</label>
						<ReactDatePicker
							fixedHeight
							selected={clearAfter}
							onChange={(date) => setClearAfter(date)}
							minDate={new Date()}
							dateFormat="MM/dd/yyyy"
							portalId="root-portal"
							placeholderText="e.g. 01/20/2024"
							customInput={
								<input
									label="login"
									className="focus:outline-none border-transparent appearance-none w-full p-3 bg-white ring-1 ring-gray-300 focus:ring-login-blue focus:border-login-blue rounded mt-2 focus:z-10 sm:text-sm mb-6"
									type="email"
								/>
							}
						/>
					</div>
					<div className="bg-white my-2 w-full flex-row flex">
						<dt className="text-md font-medium text-gray-700 flex flex-1">
							Show in Chats:
						</dt>
						<Switch
							onChange={() =>
								setShowMessageInChat(!showMessageInChat)
							}
							checked={showMessageInChat}
                            offColor="#B0BEC5"
                            onColor="#584feb"
                            checkedIcon={false}
                            uncheckedIcon={false}
						/>
					</div>
				</div>
				<button
					type="button"
					className=" mt-4 justify-center items-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-health text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-login-blue sm:w-50 sm:text-sm"
					onClick={onSave}
				>
					{loading ? (
						<Loader color="text-white" />
					) : (
						"Save Status Message"
					)}
				</button>
			</div>
		</div>
	);
}
