import * as constants from "./constants";

export const signIn = ({
	email,
	password,
	fcm,
	invitationCode,
	onSuccess,
}) => ({
	type: constants.SIGN_IN,
	payload: { email, password, fcm, invitationCode, onSuccess },
});

export const signInFailed = ({ error }) => ({
	type: constants.SIGN_IN_FAILED,
	payload: { error },
});
export const signInSuccess = ({ user, token }) => ({
	type: constants.SIGN_IN_SUCCESS,
	payload: { user, token },
});

export const logOut = () => ({
	type: constants.LOG_OUT,
});

export const logOutSuccess = () => ({
	type: constants.LOG_OUT_SUCCESS,
});

export const logOutFailed = () => ({
	type: constants.LOG_OUT_FAILED,
});

export const getVerificationRequests = ({ onNext }) => ({
	type: constants.GET_VERIFICATION_REQUESTS,
	payload: { onNext },
});

export const getVerificationRequestsSuccess = ({ verificationRequests }) => ({
	type: constants.GET_VERIFICATION_REQUESTS_SUCCESS,
	payload: { verificationRequests },
});

export const getVerificationRequestsFailed = ({ error }) => ({
	type: constants.GET_VERIFICATION_REQUESTS_FAILED,
	payload: { error },
});

export const acceptVerification = ({ verificationId, onNext }) => ({
	type: constants.ACCEPT_VERIFICATION,
	payload: { verificationId, onNext },
});

export const acceptVerificationSuccess = ({ verificationId }) => ({
	type: constants.ACCEPT_VERIFICATION_SUCCESS,
	payload: { verificationId },
});

export const acceptVerificationFailed = ({ verificationId, error }) => ({
	type: constants.ACCEPT_VERIFICATION_FAILED,
	payload: { verificationId, error },
});

export const rejectVerification = ({ verificationId, onNext }) => ({
	type: constants.REJECT_VERIFICATION,
	payload: { verificationId, onNext },
});

export const rejectVerificationSuccess = ({ verificationId }) => ({
	type: constants.REJECT_VERIFICATION_SUCCESS,
	payload: { verificationId },
});

export const rejectVerificationFailed = ({ verificationId, error }) => ({
	type: constants.REJECT_VERIFICATION_FAILED,
	payload: { verificationId, error },
});

export const sendProfileVerification = ({ data, onNext }) => ({
	type: constants.SEND_PROFILE_VERIFICATION,
	payload: { data, onNext },
});

export const sendProfileVerificationSuccess = ({ data }) => ({
	type: constants.SEND_PROFILE_VERIFICATION_SUCCESS,
	payload: { data },
});

export const sendProfileVerificationFailed = ({ err }) => ({
	type: constants.SEND_PROFILE_VERIFICATION_FAILED,
	payload: { err },
});

export const sendPhoneCode = ({
	firstName,
	lastName,
	email,
	phone,
	onNext,
}) => ({
	type: constants.SEND_PHONE_CODE,
	payload: { firstName, lastName, email, phone, onNext },
});

export const sendPhoneCodeFailed = ({ error }) => ({
	type: constants.SEND_PHONE_CODE_FAILED,
	payload: { error },
});
export const sendPhoneCodeSuccess = ({
	firstName,
	lastName,
	email,
	phone,
}) => ({
	type: constants.SEND_PHONE_CODE_SUCCESS,
	payload: { firstName, lastName, email, phone },
});

export const verifyPhoneCode = ({ code, phone, onNext }) => ({
	type: constants.VERIFY_PHONE_CODE,
	payload: { code, onNext, phone },
});

export const verfiyPhoneCodeSuccess = () => ({
	type: constants.VERIFY_PHONE_CODE_SUCCESS,
});

export const verfiyPhoneCodeFailed = ({ error }) => ({
	type: constants.VERIFY_PHONE_CODE_FAILED,
	payload: { error },
});

export const createPassword = ({ password }) => ({
	type: constants.CREATE_PASSWORD,
	payload: { password },
});

export const addProfession = ({
	profession,
	title,
	organization,
	organizationCity,
	organizationState,
}) => ({
	type: constants.ADD_PROFESSION,
	payload: {
		profession,
		title,
		organization,
		organizationCity,
		organizationState,
	},
});

export const registerUser = ({
	user,
	pinCode,
	onNext,
	fcm,
	invitationCode,
	setLoading = () => {},
}) => ({
	type: constants.REGISTER_USER,
	payload: { user, pinCode, onNext, fcm, invitationCode, setLoading },
});

export const registerUserSuccess = ({ user, token }) => ({
	type: constants.REGISTER_USER_SUCCESS,
	payload: { user, token },
});

export const registerUserFailed = ({ error }) => ({
	type: constants.REGISTER_USER_FAILED,
	payload: { error },
});

export const sendPasswordReset = ({ email, onNext }) => ({
	type: constants.SEND_PASSWORD_RESET,
	payload: { email, onNext },
});

export const sendPasswordResetSuccess = () => ({
	type: constants.SEND_PASSWORD_RESET_SUCCESS,
});

export const sendPasswordResetFailed = ({ error }) => ({
	type: constants.SEND_PASSWORD_RESET_SUCCESS,
	payload: { error },
});

export const resetRegistration = () => ({
	type: constants.RESET_REGISTRATION,
});

export const getCurrentUser = () => ({
	type: constants.GET_CURRENT_USER,
});

export const getCurrentUserSuccess = ({ user }) => ({
	type: constants.GET_CURRENT_USER_SUCCESS,
	payload: { user },
});

export const getCurrentUserSuccessFailed = ({ error }) => ({
	type: constants.GET_CURRENT_USER_FAILED,
	payload: { error },
});

export const updateUser = ({
	profession,
	title,
	organization,
	organizationCity,
	organizationState,
	email,
	phone,
	profilePhoto,
	firstName,
	lastName,
	onNext,
}) => ({
	type: constants.UPDATE_USER,
	payload: {
		profession,
		title,
		organization,
		organizationCity,
		organizationState,
		email,
		phone,
		profilePhoto,
		firstName,
		lastName,
		onNext,
	},
});

export const updateUserSuccess = ({ user }) => ({
	type: constants.UPDATE_USER_SUCCESS,
	payload: { user },
});

export const updateUserFailed = ({ error }) => ({
	type: constants.UPDATE_USER_FAILED,
	payload: { error },
});

export const setStatusMessage = ({
	message,
	showInChats,
	teammates,
	clearAfter,
	onNext,
}) => ({
	type: constants.SET_STATUS_MESSAGE,
	payload: { message, showInChats, teammates, clearAfter, onNext },
});

export const setStatusMessageSuccess = () => ({
	type: constants.SET_STATUS_MESSAGE_SUCCESS,
});

export const setStatusMessageFailed = ({ error }) => ({
	type: constants.SET_STATUS_MESSAGE_FAILED,
	payload: { error },
});

export const addTeamMembers = ({ members }) => ({
	type: constants.ADD_TEAM_MEMBER,
	payload: { members },
});

export const addTeamMembersSuccess = ({ members }) => ({
	type: constants.ADD_TEAM_MEMBER_SUCCESS,
	payload: { members },
});

export const addTeamMembersFailed = ({ error }) => ({
	type: constants.ADD_TEAM_MEMBER_FAILED,
	payload: { error },
});

export const removeTeamMember = ({ member }) => ({
	type: constants.REMOVE_TEAM_MEMBER,
	payload: { member },
});

export const removeTeamMemberSuccess = ({ member }) => ({
	type: constants.REMOVE_TEAM_MEMBER_SUCCESS,
	payload: { member },
});

export const removeTeamMemberFailed = ({ error, member }) => ({
	type: constants.REMOVE_TEAM_MEMBER_FAILED,
	payload: { error, member },
});
