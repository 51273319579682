import React from "react";
import mobileDetect from "mobile-detect";

const Layout = ({ children }) => {
	const d = new mobileDetect(window.navigator.userAgent);

	if (d.phone() !== null) {
		return (
			<div className="flex mt-[20%] flex-1 items-center h-full w-screen p-4 flex-col">
				<img
					src={require("../assets/images/logo_big.png")}
					className="w-[150px] h-[60px] mb-8"
					alt=""
				/>
				<div className="font-semibold text-xl text-center text-gray-400">
					Hey, we've detected that you are browsing with your mobile
					device. You can download our mobile app to use our services.
				</div>
				<a href="https://play.google.com/store/apps/details?id=com.phemerx.communication&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
					<img
						alt="Get it on Google Play"
						className="w-80 h-36"
						src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
					/>
				</a>
				<a href="https://apps.apple.com/pl/app/pheme-medical-chat/id1607464541">
					<img
						alt="Get it on Google Play"
						className="w-80 h-36"
						src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSP2NN1rOitfVpuA7iNQOjEWnlEqCQVygvEfKFy4Z22KALRjPHR-xXRE6J0_vuUvuq7pqQ&usqp=CAU"
					/>
				</a>
			</div>
		);
	}

	return <div>{children}</div>;
};

export default Layout;
