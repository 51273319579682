import {
	all,
	put,
	select,
	takeEvery,
	takeLatest,
} from "redux-saga/effects";
import sendBird from "services/sendbird";
import {
	getChats,
	getMessages,
	getToken,
	getUser,
} from "utils/selectors";
import { store } from "../../store";
import { urls } from "../../utils/constants";
import { getApi } from "../../utils/helpers";
import * as consultActions from "./actions";
import * as constants from "./constants";

function* fechAllChats(actions) {
	try {
		const token = yield select(getToken);
		const api = yield getApi(token);
		const chats = yield sendBird.getChats();
		// console.log('unhidden', chats);
		const members = [];
		chats.forEach((item) => {
			item.members.forEach((user) => {
				members.push({
					_id: user?.userId,
					channelURL: item.url,
				});
			});
		});
		const { data } = yield api.post(urls.getUsers, { ids: members });
		// console.log('These are consults for', data);
		const memberData = {};
		const consultInfo = {};
		data.data.forEach((item) => {
			memberData[item._id] = item;
		});
		// data.consultCounts.forEach((item) => {
		//   consultInfo[item.channelURL] = item;
		// });
		const newChats = chats.map((chat) => ({
			...chat,
			consultInfo: { count: 0 },
			members: chat.members.map((item) => ({
				...item,
				...memberData[item.userId],
			})),
		}));
		yield put(
			consultActions.fetchAllChatsSuccess({
				chats: newChats,
			})
		);
		// yield put(consultActions.getAllConsult());
	} catch (e) {
		console.log(e);
		yield put(
			consultActions.fetchAllChatsFailed({ error: e?.response?.data })
		);
	}
}

function* fechAllConsultChats(actions) {
	try {
		const { onNext } = actions.payload;
		const token = yield select(getToken);
		const api = yield getApi(token);
		const chats = yield sendBird.getConsultChats();
		const members = [];
		chats.forEach((item) => {
			item.members.forEach((user) => {
				members.push({
					_id: user?.userId,
					channelURL: item.url,
				});
			});
		});
		const { data } = yield api.post(urls.getUsers, { ids: members });
		const memberData = {};
		const consultInfo = {};
		data.data.forEach((item) => {
			memberData[item._id] = item;
		});
		data.consultCounts.forEach((item) => {
			consultInfo[item.channelURL] = item;
		});
		const newChats = chats.map((chat) => ({
			...chat,
			consultInfo: consultInfo?.hasOwnProperty(chat.url)
				? consultInfo[chat.url]
				: { count: 0 },
			members: chat.members.map((item) => ({
				...item,
				...memberData[item.userId],
			})),
		}));
		yield put(
			consultActions.fetchAllConsultChatsSuccess({
				chats: newChats,
			})
		);
		if (onNext) {
			onNext();
		}
		// yield put(consultActions.getAllConsult());
	} catch (e) {
		console.log(e);
		yield put(
			consultActions.fetchAllConsultChatsFailed({
				error: e?.response?.data,
			})
		);
	}
}

function* fetchConsultsForId(action) {
	try {
		const { id, type } = action.payload;
		const token = yield select(getToken);
		const api = yield getApi(token);
		let consults = [];
		if (type === "nextshift") {
			const nextShiftChannel = yield sendBird.getChannel(id);
			const messages = yield sendBird.searchConsultMessages(
				nextShiftChannel
			);
			consults = messages
				.filter(
					(x) => x.messageType === "user" && x.customType === "consult"
				)
				.map((item) => JSON.parse(item.data));
		} else {
			const { data } = yield api.get(urls.getConsults(id));
			// console.log("cons", data);
			consults = data.data;
		}
		yield put(
			consultActions.getConsultsSuccess({ id, consults, type })
		);
	} catch (e) {
		console.log(e);
		yield put(
			consultActions.fetchAllChatsFailed({ error: e?.response?.data })
		);
	}
}

function* updateConsult(action) {
	try {
		const { consult, onNext, oldConsult } = action.payload;
		const { _id, channel, chatUrl } = consult;
		const token = yield select(getToken);
		const api = yield getApi(token);
		const message = yield sendBird.updateConsult(
			channel,
			chatUrl,
			consult,
			chatUrl
		);
		console.log("new", consult);
		if (message) {
			const { data } = yield api.post(urls.updateConsult(_id), consult);
			if (data.success) {
				yield put(
					consultActions.updateConsultSuccess({ consult, message })
				);
				if (onNext) {
					onNext();
				}
			} else {
				alert("Failed to update the consult");
				yield sendBird.updateConsult(
					channel,
					chatUrl,
					oldConsult,
					chatUrl
				);
			}
		} else {
			alert("Failed to update the consult");
		}
	} catch (e) {
		alert("Failed to update the consult");
		console.log(e);
		yield put(
			consultActions.updateConsultFailed({ error: e?.response?.data })
		);
	}
}

function* reopenConsult(action) {
	try {
		const { onNext, consult, users = [] } = action.payload;
		const { chatUrl, _id, channel } = consult;
		const token = yield select(getToken);
		const api = yield getApi(token);
		const user = yield select(getUser);
		const newConsult = { ...consult, resolved: false };
		const msg = yield sendBird.updateConsult(
			channel,
			chatUrl,
			newConsult,
			chatUrl
		);
		if (msg) {
			const { data } = yield api.post(urls.reopenConsult(_id));
			if (data.success) {
				yield put(
					consultActions.reopenConsultSuccess({
						consult,
						msg,
					})
				);
				if (users.length > 0) {
					const d = yield api.post(urls.sendNotification, {
						users: users
							.map((item) => item.userId || item._id)
							.filter((x) => x !== user._id),
						notification: {
							title: `Consult reopened for Pt.${consult.firstName} ${consult.lastName}`,
							body: `${consult.title} is reopened by ${
								user.firstName || ""
							} ${user.lastName || ""}`,
						},
					});
				}
				if (onNext) {
					onNext();
				}
			} else {
				alert("Failed to Re-Open the consult");
				const $newConsult = { ...consult, resolved: true };
				yield sendBird.updateConsult(
					channel,
					chatUrl,
					$newConsult,
					chatUrl
				);
			}
		} else {
			alert("Failed to Re-Open the consult");
		}
	} catch (e) {
		alert("Failed to Re-Open the consult");
		console.log(e);
		yield put(
			consultActions.reopenConsultFailed({ error: e?.response?.data })
		);
	}
}

function* deleteConsult(action) {
	const { onNext, consult } = action.payload;
	try {
		yield sendBird.deleteChat(consult.chatUrl);
		console.log("chatDeleteds");
		// yield sendBird.deleteMessage(consult.messageID, consult.channel, true);
		if (onNext) {
			onNext();
		}
		yield put(consultActions.deleteConsultSuccess({}));
	} catch (e) {
		yield put(consultActions.deleteConsultFailed({ error: e.message }));
	}
}

function* archiveConsult(action) {
	try {
		const { onNext, consult, shouldHideChat = false } = action.payload;
		const { chatUrl, _id, channel } = consult;
		const token = yield select(getToken);
		const api = yield getApi(token);
		const newConsult = { ...consult, archived: true };
		const msg = yield sendBird.updateConsult(
			channel,
			chatUrl,
			newConsult,
			chatUrl,
			shouldHideChat
		);
		// if (msg) {
		const { data } = yield api.post(urls.archiveConsult(_id));
		if (data.success) {
			yield put(
				consultActions.archiveConsultSuccess({
					consult,
					msg,
				})
			);
			if (onNext) {
				onNext();
			}
			// } else {
			//   alert('Failed to archive the consult');
			//   const $newConsult = {...consult, archived: false};
			//   yield sendBird.updateConsult(channel, chatUrl, $newConsult, chatUrl);
			// }
		} else {
			alert("Failed to archive the consult");
		}
	} catch (e) {
		alert("Failed to archive the consult");
		console.log(e);
		yield put(
			consultActions.archiveConsultFailed({ error: e?.response?.data })
		);
	}
}

function* markConsultResolved(action) {
	try {
		const { onNext, consult, users = [] } = action.payload;
		const { chatUrl, _id, channel } = consult;
		const token = yield select(getToken);
		const api = yield getApi(token);
		const user = yield select(getUser);
		const newConsult = { ...consult, resolved: true };
		const msg = yield sendBird.updateConsult(
			channel,
			chatUrl,
			newConsult,
			chatUrl,
			true
		);
		console.log("message", msg);
		if (msg) {
			const { data } = yield api.post(urls.resolveConsult(_id));
			console.log("dat", data);
			if (data.success) {
				yield put(
					consultActions.markConsultResolvedSuccess({
						consult,
						msg,
					})
				);
				console.log(
					" notifiusers",
					users.map((item) => item.userId || item._id)
				);
				if (users.length > 0) {
					yield api.post(urls.sendNotification, {
						users: users
							.map((item) => item.userId || item._id)
							.filter((x) => x !== user._id),
						notification: {
							title: `Consult resolved for Pt. ${consult.firstName} ${consult.lastName}`,
							body: `${consult.title} is marked as resolved by ${
								user.firstName || ""
							} ${user.lastName || ""}`,
						},
					});
				}
				if (onNext) {
					onNext();
				}
			} else {
				alert("Failed to Resolve the consult");
				const $newConsult = { ...consult, resolved: false };
				yield sendBird.updateConsult(
					channel,
					chatUrl,
					$newConsult,
					chatUrl
				);
			}
		} else {
			alert("Failed to Resolve the consult");
		}
	} catch (e) {
		alert("Failed to Resolve the consult");
		console.log(e);
		yield put(
			consultActions.markConsultResolvedFailed({
				error: e?.response?.data,
			})
		);
	}
}

function* createConsult(actions) {
	const {
		id,
		title,
		identifier,
		description,
		onNext,
		firstName,
		lastName,
		dateOfBirth,
		team,
		file,
	} = actions.payload;
	const consultObj = {
		title,
		identifier,
		firstName,
		lastName,
		dateOfBirth,
		description,
		team,
		resolved: false,
		archived: false,
	};
	try {
		let msg;
		const token = yield select(getToken);
		const api = yield getApi(token);
		const channel = yield sendBird.getChannel(id);
		let members = channel.members.map((item) => item.userId);
		const chatConsult = yield sendBird.createChat(
			members,
			false,
			"chatConsult"
		);
		// msg = yield sendBird.sendMessage(
		//   {...consultObj, channel: id, chatUrl: chatConsult.url},
		//   id,
		//   'consult',
		// );
		// if (msg) {
		const consult = {
			channel: id,
			chatUrl: chatConsult.url,
			firstName,
			lastName,
			dateOfBirth,
			title,
			identifier,
			team,
			description,
			messageID: null,
		};
		const { data } = yield api.post(urls.createConsult, consult);
		if (data.success) {
			const newMessage = yield sendBird.updateConsult(
				null,
				null,
				{
					...data.data,
					messageID: null,
					team,
					channel: id,
					chatUrl: chatConsult.url,
				},
				chatConsult.url
			);
			if (file) {
				yield sendBird.sendFile(file, chatConsult.url);
			}
			yield put(
				consultActions.createConsultSuccess({
					consult: data.data,
					id,
					message: newMessage,
				})
			);
			// yield sendBird.hideChat(chatConsult);
			if (onNext) {
				onNext();
			}
		}
	} catch (e) {
		console.log(e);
		yield put(consultActions.createConsultFailed({ e, id, title }));
	}
}

function* sendMessage(actions) {
	const {
		id,
		message,
		type,
		sender,
		createdAt,
		onProgress = () => {},
		extraData = "",
	} = actions.payload;
	try {
		let msg;
		if (type === "text") {
			msg = yield sendBird.sendMessage(message, id, extraData);
		}
		if (type === "gif") {
			msg = yield sendBird.sendMessage(message, id, type);
		}
		if (type === "file") {
			msg = yield sendBird.sendFile(
				message,
				id,
				undefined,
				undefined,
				undefined,
				extraData,
				onProgress
			);
		}
		if (msg) {
			yield put(
				consultActions.sendMessageSuccess({ message: msg, id, createdAt })
			);
			const channel = yield sendBird.getChannel(id);
			yield put(consultActions.fetchSingleChat({ id: id, channel }));
		}
	} catch (e) {
		console.log(e);
		yield put(
			consultActions.sendMessageFailed({
				e,
				id,
				message,
				type,
				sender,
				createdAt,
			})
		);
	}
}

function* resendMessage(actions) {
	const {
		id,
		message,
		type,
		sender,
		createdAt,
		failedAt,
		onProgress,
	} = actions.payload;
	try {
		let msg;
		if (type === "text") {
			msg = yield sendBird.sendMessage(message, id);
		}
		if (type === "file") {
			msg = yield sendBird.sendFile(
				message,
				id,
				undefined,
				undefined,
				undefined,
				undefined,
				onProgress
			);
		}
		if (msg) {
			yield put(
				consultActions.sendMessageSuccess({
					message: msg,
					id,
					createdAt,
					failedAt,
				})
			);
		}
	} catch (e) {
		yield put(
			consultActions.resendMessageFailed({
				e,
				id,
				message,
				type,
				sender,
				createdAt,
				failedAt,
			})
		);
	}
}

function* replyToThread(actions) {
	const { message, type, parentMessageId, sender, parentMessage, id } =
		actions.payload;
	try {
		let msg;
		if (type === "text") {
			msg = yield sendBird.sendMessage(
				message,
				id,
				parentMessage ? "reply" : "thread",
				parentMessageId,
				parentMessage,
				true
			);
		}
		if (type === "file") {
			msg = yield sendBird.sendFile(
				message,
				id,
				parentMessageId,
				parentMessage,
				true,
				parentMessage ? "reply" : "thread"
			);
		}
		if (msg) {
			yield put(
				consultActions.replyToThreadSuccess({
					message: msg,
					parentMessageId,
				})
			);
		}
	} catch (e) {
		console.log(e);
		yield put(
			consultActions.replyToThreadFailed({
				e,
				message,
				parentMessageId,
				type,
				sender,
			})
		);
	}
}

function* replyToMessage(actions) {
	const {
		channel,
		message,
		type,
		sender,
		parentMessage,
		createdAt,
		id,
		onProgress,
	} = actions.payload;
	const parentMessageId = parentMessage.messageId;
	try {
		let msg;
		console.log("triggered reply", actions.payload);

		if (type === "text") {
			console.log("sending message");
			msg = yield sendBird.sendMessage(
				message,
				id,
				"reply",
				parentMessageId,
				parentMessage
			);
		}
		if (type === "file") {
			msg = yield sendBird.sendFile(
				message,
				id,
				parentMessageId,
				parentMessage,
				false,
				"reply",
				onProgress
			);
		}
		console.log("message sent", msg);
		if (msg) {
			yield put(
				consultActions.sendMessageSuccess({
					message: msg,
					createdAt,
					id: channel.url,
				})
			);
		}
	} catch (e) {
		console.log(e);
		yield put(
			consultActions.replyToThreadFailed({
				e,
				message,
				parentMessageId,
				type,
				sender,
			})
		);
	}
}

function* fetchSingleChat(actions) {
	const { id, channel, onSuccess } = actions.payload;
	try {
		const chats = yield sendBird.fetchMessages(channel);
		const isReadPromise = [];
		chats.forEach((item) => {
			isReadPromise.push(sendBird.getReadStatus(channel, item));
		});
		console.log("getting read promise");
		const allPromise = yield Promise.all(isReadPromise);
		const messages = chats.map((item, index) => ({
			...item,
			isRead: allPromise[index],
		}));
		if (onSuccess) {
			onSuccess();
		}
		yield put(
			consultActions.fetchSingleChatSuccess({ chats: messages, id })
		);
	} catch (e) {
		console.log("this is the error", e);
		if (onSuccess) {
			onSuccess();
		}
		yield put(consultActions.fetchSingleChatFailed({ error: e }));
	}
}

function* fetchMembers(actions) {
	try {
		const { members, channel } = actions.payload;
		const userIds = members.map((user) => ({
			_id: user?.userId,
			channelURL: channel,
		}));
		const token = yield select(getToken);
		const api = yield getApi(token);
		const { data } = yield api.post(urls.getUsers, { ids: userIds });
		const memberData = {};
		data.data.forEach((item) => {
			memberData[item._id] = item;
		});
		yield put(
			consultActions.fetchMembersSuccess({ members: memberData })
		);
	} catch (e) {
		console.log(e);
		yield put(consultActions.fetchMembersFailed({ error: e }));
	}
}

function* deleteMessage(action) {
	try {
		const { message, channel } = action.payload;
		if (!message.error) {
			yield sendBird.deleteMessage(message, channel);
		}
		yield put(consultActions.deleteMessageSuccess(action.payload));
	} catch (e) {
		console.log(e);
		yield put(consultActions.deleteMessageFailed(e?.response));
	}
}

function* fetchThread(action) {
	try {
		const { id, channel } = action.payload;
		const messages = yield sendBird.fetchThread(id, channel);
		yield put(consultActions.fetchThreadSuccess({ messages, id }));
	} catch (e) {
		console.log(e);
		yield put(consultActions.fetchThreadFailed(e?.response));
	}
}

function* fetchPreviousMessages(actions) {
	try {
		const { channel, onNext } = actions.payload;
		const chatChannel = yield sendBird.getChannel(channel);
		const channelMessages = yield select(getMessages);
		const messageArray = channelMessages[channel];
		const lastMessage = messageArray[messageArray.length - 1];
		const previousMessages = yield sendBird.fetchPreviousMessages(
			chatChannel,
			lastMessage
		);
		const isReadPromise = [];
		previousMessages.forEach((item) => {
			isReadPromise.push(sendBird.getReadStatus(chatChannel, item));
		});
		const allPromise = yield Promise.all(isReadPromise);
		const messages = previousMessages
			.map((item, index) => ({
				...item,
				isRead: allPromise[index],
			}))
			.reverse();
		if (onNext) {
			onNext(messages);
		}
		yield put(
			consultActions.fetchPreviousMessagesSuccess({
				messages,
				id: channel,
			})
		);
	} catch (e) {
		console.log(e);
		yield put(consultActions.fetchPreviousMessagesFailed({ error: e }));
	}
}

function* createNewShift(actions) {
	try {
		const { shifts, onNext } = actions.payload;
		const shiftsArr = Object.values(shifts);
		const user = yield select(getUser);
		console.log(shiftsArr);
		yield all(
			shiftsArr.map((item) =>
				put(
					consultActions.createNewShiftConversation({
						members: [item.user?._id],
						customType: `${item.firstName || ""} ${item.lastName || ""}`,
						messages: item.consults,
					})
				)
			)
		);
		console.log("success created");
		yield put(consultActions.createNextShiftSuccess({ shifts: [] }));
		if (onNext) {
			onNext();
		}
		// console.log('chats', chats);
	} catch (e) {
		console.log(e);
		yield put(consultActions.createNextShiftFailed());
	}
}

function* createNewShiftConversation(actions) {
	try {
		const { messages, members, customType } = actions.payload;
		const user = yield select(getUser);
		const chats = yield select(getChats);
		const token = yield select(getToken);
		const api = yield getApi(token);
		// const currentChat = chats.filter(
		//   (x) =>
		//     x.members?.length === 2 &&
		//     x.customType === 'nextshift' &&
		//     x.members.findIndex((usr) => usr.userId === members[0]) !== -1,
		// );
		// console.log('yes');
		// let chatData;
		// if (currentChat.length > 0) {
		//   chatData = currentChat[0];
		// } else {
		//   chatData = yield sendBird.createChat(
		//     [...members, user?._id],
		//     false,
		//     customType || 'nextshift',
		//   );
		// }
		// console.log('reaced here');
		// yield all(
		//   messages.map((m) => sendBird.sendMessage(m, chatData.url, 'consult')),
		// );
		yield all(
			messages.map((m) =>
				sendBird.addAdminToGroup(m.url, members, true, customType)
			)
		);
		yield all(
			messages.map((m) =>
				api.post(urls.adminMessage(m.url), {
					send_push: true,
					message: "added as NextShift",
				})
			)
		);
		yield put(consultActions.createNewShiftConversationSuccess());
	} catch (e) {
		console.log(e.response);
		yield put(
			consultActions.createNewShiftConversationFailed({ error: e })
		);
	}
}

function* createNewConversation(actions) {
	try {
		const {
			type,
			channel,
			members,
			member,
			customType,
			onNext,
			name,
			cover,
		} = actions.payload;
		console.log("in saga", actions.payload);
		const user = yield select(getUser);
		const token = yield select(getToken);
		let chatData;
		if (type === "add") {
			chatData = yield sendBird.addUserToGroup(channel, members);
		} else if (type === "remove") {
			chatData = yield sendBird.removeUserFromGroup(channel, member);
			onNext();
		} else {
			console.log(
				"creating a new sendbird chat",
				[...members, user?._id],
				true,
				customType || "",
				name,
				cover
			);
			chatData = yield sendBird.createChat(
				[...members, user?._id],
				name ? false : true,
				customType || "",
				name,
				cover
			);
		}
		const api = yield getApi(token);
		const channelMembers = [];
		chatData.members.forEach((usr) => {
			channelMembers.push({
				_id: usr.userId,
				channelURL: chatData.url,
			});
		});
		let memberInfo = {};
		const { data } = yield api.post(urls.getUsers, {
			ids: channelMembers,
		});
		data.data.forEach((item) => {
			memberInfo[item._id] = item;
		});
		chatData.members = chatData.members.map((item) => ({
			...item,
			...memberInfo[item.userId],
		}));
		onNext(chatData);
	} catch (e) {
		console.log(e);
		yield put(consultActions.createNewConversationFailed({ error: e }));
	}
}

const onProgress = (id, messageId, event) => {
	const p = Math.floor((event.loaded / event.total) * 100);
	store.dispatch(
		consultActions.updateProgress({ id, messageId, progress: p })
	);
};

function* forwardMessage(actions) {
	const { channels, message, sender, onNext } = actions.payload;
	try {
		const createdAt = new Date().valueOf();
		yield all(
			channels.map((channel) =>
				put(
					consultActions.sendMessage({
						id: channel,
						message:
							message.messageType === "user"
								? message.message
								: {
										name: message.name,
										type: message.type,
										uri: message.plainUrl,
										size: message.size,
								  },
						sender,
						extraData: "forwarded",
						type: message.messageType === "user" ? "text" : "file",
						onProgress: (event) => onProgress(channel, createdAt, event),
						createdAt,
					})
				)
			)
		);
		if (onNext) {
			onNext();
		}
	} catch (e) {
		console.log(e);
	}
}

function* getAllConsults(actions) {
	try {
		const chats = yield select(getChats);
		yield all(
			chats.map((chat) =>
				put(
					consultActions.getConsults({
						id: chat.url,
						type: chat.customType,
					})
				)
			)
		);
	} catch (e) {
		console.log(e);
	}
}

function* deleteChat(actions) {
	try {
		const { channel } = actions.payload;
		const chat = yield sendBird.getChannel(channel);
		yield sendBird.hideChat(chat);
		yield put(consultActions.deleteChatSuccess({ channel }));
	} catch (e) {
		console.log(e);
		yield put(consultActions.deleteChatFailed({ error: e.message }));
	}
}

function* leaveChannel(actions) {
	try {
		const { channel, onNext } = actions.payload;
		if (typeof channel === "object") {
			if (channel.customType === "chatConsult" && channel.data) {
				// const consult = JSON.parse(channel.data);
				const chat = yield sendBird.getChannel(channel.url);
				yield sendBird.leaveChat(chat);
				yield put(
					consultActions.deleteChatSuccess({ channel: channel.url })
				);
			}
		} else {
			const chat = yield sendBird.getChannel(channel);
			yield sendBird.leaveChat(chat);
			yield put(consultActions.deleteChatSuccess({ channel }));
		}
		yield put(consultActions.fetchAllConsultChats());
		if (onNext) {
			onNext();
		}
	} catch (e) {
		console.log(e);
		yield put(consultActions.deleteChatFailed({ error: e.message }));
	}
}

function* addReaction(actions) {
	try {
		const { message, channel, reaction } = actions.payload;
		const response = yield sendBird.addReaction(
			reaction.key,
			message,
			channel
		);
		yield put(
			consultActions.addReactionSuccess({
				message,
				channel,
				reaction: { ...reaction, ...response },
			})
		);
	} catch (e) {
		console.log(e);
		yield put(consultActions.addReactionFailed({ error: e.message }));
	}
}

export default function* watchConsult() {
	all([
		// yield takeLatest(constants.FETCH_ALL_CONSULT_CHATS, fechAllConsultChats),
		// yield takeLatest(constants.FETCH_ALL_CHATS, fechAllChats),
		// yield takeEvery(constants.SEND_MESSAGE, sendMessage),
		// yield takeEvery(constants.RESEND_MESSAGE, resendMessage),
		// yield takeEvery(constants.FETCH_SINGLE_CHAT, fetchSingleChat),
		// yield takeLatest(constants.DELETE_MESSAGE, deleteMessage),
		yield takeEvery(constants.CREATE_CONSULT, createConsult),
		// yield takeEvery(constants.GET_CONSULTS, fetchConsultsForId),
		// yield takeLatest(constants.RESOLVE_CONSULT, markConsultResolved),
		yield takeLatest(constants.DELETE_CONSULT, deleteConsult),
		// yield takeLatest(constants.UPDATE_CONSULT, updateConsult),
		// yield takeLatest(constants.REOPEN_CONSULT, reopenConsult),
		// yield takeLatest(constants.ARCHIVE_CONSULT, archiveConsult),
		// yield takeLatest(constants.REPLY_TO_THREAD, replyToThread),
		// yield takeLatest(constants.FETCH_MEMBERS, fetchMembers),
		// yield takeLatest(constants.FETCH_THREAD, fetchThread),
		// yield takeLatest(constants.FETCH_PREVIOUS_MESSAGES, fetchPreviousMessages),
		yield takeLatest(
			constants.CREATE_NEW_CONVERSATION,
			createNewConversation
		),
		yield takeLatest(constants.REPLY_TO_MESSAGE, replyToMessage),
		// yield takeLatest(constants.FORWARD_MESSAGE, forwardMessage),
		// yield takeLatest(constants.GET_ALL_CONSULTS, getAllConsults),
		// yield takeLatest(constants.CREATE_NEXT_SHIFT, createNewShift),
		// yield takeLatest(constants.DELETE_CHAT, deleteChat),
		// yield takeLatest(constants.LEAVE_CHANNEL, leaveChannel),
		// yield takeLatest(constants.ADD_REACTION, addReaction),
		// yield takeEvery(
		//   constants.CREATE_NEW_SHIFT_CONVERSATION,
		//   createNewShiftConversation,
		// ),
	]);
}
