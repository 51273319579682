import { all, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import sendBird from "services/sendbird";
import { urls } from "../../utils/constants";
import { getApi } from "../../utils/helpers";
import { getToken, getUser } from "../../utils/selectors";
import * as directionActions from "./actions";
import * as constants from "./constants";

function* fetchDirectory(actions) {
  const { searchString, setSearchResults } = actions.payload;
  try {
    const token = yield select(getToken);
    const user = yield select(getUser);
    const api = yield getApi(token);
    const { data } = yield api.get(
      `${urls.searchDirectory}?search=${searchString}&verifiedOnly=true`
    );
    setSearchResults(data.data.filter((x) => x._id !== user?._id));
    yield put(directionActions.fetchDirectorySuccess());
  } catch (e) {
    console.log(e);
    setSearchResults([]);
    yield put(
      directionActions.fetchDirectoryFailed({ error: e.response.data })
    );
  }
}

function* fetchBlockedUsers() {
  try {
    const users = yield sendBird.fetchBlockedUsers();
    yield put(directionActions.fetchBlockedUsersSuccess({ users }));
  } catch (e) {
    console.log(e);
    yield put(directionActions.fetchBlockedUsersFailed({ error: e?.response }));
  }
}

function* blockUser(actions) {
  const { user } = actions.payload;
  try {
    const id = user?._id || user?.userId;
    yield sendBird.blockUser(id);
    const users = yield sendBird.fetchBlockedUsers();
    yield put(directionActions.blockUserSuccess({ users }));
  } catch (e) {
    console.log(e);
    yield put(directionActions.blockUserFailed({ error: e?.response, user }));
  }
}

function* unblockUser(actions) {
  const { user } = actions.payload;
  try {
    const id = user?._id || user?.userId;
    yield sendBird.unblockUser(id);
    const users = yield sendBird.fetchBlockedUsers();
    yield put(directionActions.unblockUserSuccess({ users }));
  } catch (e) {
    console.log(e);
    yield put(directionActions.unblockUserFailed({ error: e?.response, user }));
  }
}

function* createTeam(actions) {
  const { title, users, onNext } = actions.payload;
  try {
    const token = yield select(getToken);
    const user = yield select(getUser);
    const api = yield getApi(token);
    console.log("team data", title, users, user);
    const { data } = yield api.post(urls.team, {
      name: title,
      users: [user?.userId, ...users],
      proxies: [user?.userId, ...users]
    });
    console.log("team data", data);
    if (data.success) {
      if (onNext) {
        onNext();
      }
      yield put(directionActions.createTeamSuccess());
    }
  } catch (e) {
    console.log(e);
    yield put(directionActions.createTeamFailed({ error: e }));
  }
}

function* getTeams(actions) {
  try {
    const token = yield select(getToken);
    const api = yield getApi(token);
    const { data } = yield api.get(urls.team);
    if (data.success) {
      yield put(directionActions.getTeamsSuccess({ teams: data.data }));
    }
    console.log(data);
  } catch (e) {
    console.log(e);
    yield put(directionActions.getTeamsFailed({ error: e }));
  }
}

function* getTeamsById(actions) {
  try {
    const { id, onSuccess } = actions.payload;
    console.log("we got here ", id);
    if (id) {
      const token = yield select(getToken);
      const api = yield getApi(token);
      const { data } = yield api.get(urls.team + "/" + id);
      // console.log('team by id', data);
      if (data.success) {
        yield put(directionActions.getTeamsByIdSuccess({ team: data }));
        onSuccess(data?.data?.team);
      }
    }
  } catch (e) {
    console.log(e);
    yield put(directionActions.getTeamsByIdFailed({ error: e }));
  }
}

function* deleteTeam(actions) {
  try {
    const { id, onNext } = actions.payload;
    const token = yield select(getToken);
    const api = yield getApi(token);
    const { data } = yield api.delete(urls.deleteTeam(id), { id: id });
    if (data.success) {
      yield put(directionActions.deleteTeamSuccess());
      if (onNext) {
        onNext();
      }
    }
    console.log(data);
  } catch (e) {
    console.log(e);
    yield put(directionActions.deleteTeamFailed({ error: e }));
  }
}

function* leaveTeam(actions) {
  try {
    const { id, onNext } = actions.payload;
    const token = yield select(getToken);
    const api = yield getApi(token);
    const { data } = yield api.post(urls.leaveTeam(id), { id: id });
    if (onNext) {
      onNext();
    }
    if (data.success) {
      yield put(directionActions.leaveTeamSuccess());
      // if (onNext) {
      //   onNext();
      // }
    }
    console.log(data);
  } catch (e) {
    console.log(e);
    yield put(directionActions.deleteTeamFailed({ error: e }));
  }
}

function* addTeamUsers(actions) {
  const { users, id, onNext } = actions.payload;
  try {
    const token = yield select(getToken);
    const api = yield getApi(token);
    const { data } = yield api.post(urls.addTeamUser(id), {
      users: users
    });
    console.log("data", data);
    if (data.success) {
      if (onNext) {
        onNext();
      }
      yield put(directionActions.addTeamUsersSuccess());
    }
  } catch (e) {
    console.log(e);
    yield put(directionActions.addTeamUsersFailed({ error: e }));
  }
}

function* removeTeamUsers(actions) {
  const { users, id, onNext } = actions.payload;
  try {
    const token = yield select(getToken);
    const api = yield getApi(token);
    const { data } = yield api.post(urls.removeTeamUser(id), {
      users: users,
      id
    });
    console.log("data", data);
    if (data.success) {
      if (onNext) {
        onNext();
      }
      yield put(directionActions.removeTeamMembersSuccess());
    }
  } catch (e) {
    console.log(e);
    yield put(directionActions.removeTeamMembersFailed({ error: e }));
  }
}

function* toggleProxies(actions) {
  const { users, id, onNext, previousProxies } = actions.payload;
  try {
    const token = yield select(getToken);
    const api = yield getApi(token);
    const removedUsers = previousProxies.filter(
      (usr) => users.findIndex((userId) => userId === usr) === -1
    );
    const addedUsers = users.filter(
      (usr) => previousProxies.findIndex((userId) => userId === usr) === -1
    );
    const { data } = yield api.post(urls.addProxyUser(id), {
      proxies: addedUsers
    });
    const removeData = yield api.post(urls.removeProxyUser(id), {
      proxies: removedUsers
    });
    console.log("data", addedUsers, removedUsers);
    if (data.success && removeData.data.success) {
      if (onNext) {
        onNext();
      }
      yield put(directionActions.addTeamUsersSuccess());
    }
  } catch (e) {
    console.log(e);
    yield put(directionActions.addTeamUsersFailed({ error: e }));
  }
}

function* updateTeam({ payload }) {
  try {
    const { title, photo, teamId, onSuccess } = payload;
    const token = yield select(getToken);
    const api = yield getApi(token);
    let uploadData = {};

    if (title) {
      uploadData.name = title;
    }
    if (photo) {
      const { data } = yield api.get(urls.uploadSignedUrl(teamId));
      console.log("uploaddat ", data);
      const preSignedURL = data.data.presignedUrl;
      const pathToImage = photo;
      const headers = { "Content-Type": "image/jpeg" };

      // yield RNFetchBlob.fetch(
      //   'PUT',
      //   preSignedURL,
      //   headers,
      //   RNFetchBlob.wrap(pathToImage),
      // ).then((d) => console.log('resut', d));
      yield fetch(data.data.presignedUrl, {
        body: {
          uri: photo,
          type: "image/jpeg",
          name: teamId
        },
        method: "PUT"
      });
      uploadData.logo = data.data.viewUrl;
    }
    const updateResp = yield api.put(urls.getTeam(teamId), {
      ...uploadData
    });
    if (updateResp.data.success && onSuccess) {
      onSuccess();
    }
  } catch (e) {
    console.log("error", e?.response);
  }
}

export default function* watchDirectory() {
  all([
    yield takeLatest(constants.FETCH_DIRECTORY, fetchDirectory),
    yield takeLatest(constants.FETCH_BLOCKED_USERS, fetchBlockedUsers),
    yield takeLatest(constants.BLOCK_USER, blockUser),
    yield takeLatest(constants.UNBLOCK_USER, unblockUser),
    yield takeLatest(constants.CREATE_TEAM, createTeam),
    yield takeLatest(constants.GET_TEAMS, getTeams),
    yield takeLatest(constants.DELETE_TEAM, deleteTeam),
    yield takeLatest(constants.LEAVE_TEAM, leaveTeam),
    yield takeLatest(constants.ADD_TEAM_USERS, addTeamUsers),
    yield takeLatest(constants.ADD_PROXY, toggleProxies),
    yield takeLatest(constants.REMOVE_TEAM_USERS, removeTeamUsers),
    yield takeLatest(constants.UPDATE_TEAM, updateTeam),
    yield takeEvery(constants.GET_TEAM_BY_ID, getTeamsById)
  ]);
}
