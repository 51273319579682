import {
	deleteTeam,
	getTeams,
	removeTeamMembers,
	updateTeam,
} from "containers/directory/actions";

import CreateConsultModal from "components/consult/createConsultModal";
import AddTeamMemberModal from "components/teams/addTeamMemberModal";
import moment from "moment";
import { useEffect, useState } from "react";
import { BsChevronLeft } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { usePhemeContext } from "store/phemeContext";
import ProfileGroup from "../../assets/images/profilegroup.png";
import ProfileSingle from "../../assets/images/profilesingle.png";

export default function TeamDetail({
	selectedTeam,
	onStartChat,
	...restProps
}) {
	const dispatch = useDispatch();
	const allTeams = useSelector((state) => state.directory.teams);
	const [consultModalOpen, setConsultModalOpen] = useState(false);
	const [addTeamMemberModalOpen, setAddTeamMemberModalOpen] =
		useState(false);
	const [teamData, setTeamData] = useState({});

	useEffect(() => {
		setTeamData(allTeams.find((item) => item._id === selectedTeam._id));
	}, [allTeams, selectedTeam]);

	const handleCreateConversation = async () => {
		onStartChat({
			title: selectedTeam.name,
			members: selectedTeam.users.map((item) => item?._id),
		});
	};

	const handleCreateConsult = async () => {
		setConsultModalOpen(true);
	};

	const handleDeleteTeam = async () => {
		if (window.confirm("Are you sure you want to delete this team?")) {
			dispatch(
				deleteTeam({
					id: selectedTeam._id,
					onNext: () => {
						window.location.reload();
					},
				})
			);
		}
	};

	const handleUpdateTeam = async () => {
		dispatch(
			updateTeam({
				teamId: selectedTeam._id,
				title: "test",
			})
		);
	};

	const handleRemoveUserFromTeam = async (userId) => {
		if (
			window.confirm(
				"Are you sure you want to remove this user from the team?"
			)
		) {
			dispatch(
				removeTeamMembers({
					id: selectedTeam._id,
					users: [userId],
					onNext: () => {
						// window.location.reload();
						dispatch(getTeams());
					},
				})
			);
		}
	};

	const handleAddUserToTeam = async () => {
		setAddTeamMemberModalOpen(true);
	};

	const { navDrawerOpen, setNavDrawerOpen } = usePhemeContext();

	return (
		<div
			className="overflow-hidden bg-white sm:rounded-lg w-full"
			{...restProps}
		>
			<div className="flex flex-row border-b border-gray-100 items-center h-[70px] p-4">
				<button
					onClick={() => setNavDrawerOpen(!navDrawerOpen)}
					className="flex flex-row items-center justify-center rounded-full pr-3 transition-transform duration-300"
					style={{
						transform: navDrawerOpen ? "" : "rotate(180deg)",
						display: navDrawerOpen ? "" : "none",
					}}
				>
					<BsChevronLeft size={26} className="text-gray-400 " />
				</button>
				<h3 className="text-lg font-medium leading-6 text-gray-900">
					Team Information
				</h3>
			</div>
			<div className="border-t border-gray-200">
				<dl>
					<div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
						<dt className="text-sm font-medium text-gray-500">Team name</dt>
						<div className="flex">
							<dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
								{teamData.name}
							</dd>
							{/* <button onClick={() => handleUpdateTeam()}>Update</button> */}
						</div>
					</div>
					<div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
						<dt className="text-sm font-medium text-gray-500">Logo</dt>
						<dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
							<img
								className="w-20 h-20 rounded-full self-center"
								src={teamData?.logo || `${ProfileGroup}`}
								alt=""
							/>
						</dd>
					</div>
					<div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
						<dt className="text-sm font-medium text-gray-500">
							Created on
						</dt>
						<dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
							{moment(teamData.createdAt).format("DD MMM YYYY")}
						</dd>
					</div>
					<div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
						<dt className="text-sm font-medium text-gray-500">
							Created by
						</dt>
						<dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
							{teamData.owner?.firstName} {teamData.owner?.lastName}
						</dd>
					</div>
					<div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
						<dt className="text-sm font-medium text-gray-500">Users</dt>
						<dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 overflow-y-auto max-h-80">
							<ul
								role="list"
								className="divide-y divide-gray-200 rounded-md border border-gray-200"
							>
								{teamData?.users?.map((item) => (
									<li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
										<div className="flex w-0 flex-1 items-center">
											<img
												src={item?.profilePhoto || ProfileSingle}
												alt=""
												className="rounded-full w-[40px] h-[40px]"
											/>
											<span className="ml-2 w-0 flex-1 truncate">
												{item?.firstName} {item?.lastName}
											</span>
											<button onClick={() => handleRemoveUserFromTeam(item._id)}>
												Remove
											</button>
										</div>
									</li>
								))}
							</ul>
						</dd>
						<dt></dt>
						<dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 overflow-y-auto max-h-80">
							<button onClick={handleAddUserToTeam}>
								Add Users to Team
							</button>
						</dd>
					</div>
					<div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
						<dt className="text-sm font-medium text-gray-500">Actions</dt>
						<dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
							<button onClick={handleCreateConversation}>
								Send Message
							</button>
							<br />
							<br />
							<button onClick={handleCreateConsult}>
								Create Consult for this Team
							</button>
							<br />
							<br />
							<button onClick={handleDeleteTeam}>Delete Team</button>
						</dd>
					</div>
				</dl>
			</div>
			<CreateConsultModal
				open={consultModalOpen}
				setOpen={setConsultModalOpen}
				isTeamConsult={true}
				preselectedPeople={teamData?.users}
			/>
			<AddTeamMemberModal
				open={addTeamMemberModalOpen}
				setOpen={setAddTeamMemberModalOpen}
				teamId={teamData._id}
			/>
		</div>
	);
}
