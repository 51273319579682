import { combineReducers } from "redux";
import GlobalReducer from "../containers/global/reducer";
import AuthReducer from "../containers/auth/reducer";
import DirectoryReducer from "containers/directory/reducer";
import ConsultReducer from "containers/consults/reducer";

const appReducer = combineReducers({
  global: GlobalReducer,
  auth: AuthReducer,
  directory: DirectoryReducer,
  consult: ConsultReducer,
});

const rootReducer = (state, action) => {
  let $state = state;
  return appReducer($state, action);
};

export default rootReducer;
