const ProgressiveImg = ({ ...props }) => {
	return (
		<img
			{...{ ...props }}
			alt={props.alt || ""}
			className="image rounded-lg border-gray-100 border"
		/>
	);
};
export default ProgressiveImg;
