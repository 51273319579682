import FabButton from "components/FabButton";
import Chat from "components/chats";
import Team from "components/chats/teams";
import CreateConsultModal from "components/consult/createConsultModal";
import CreateConversationModal from "components/conversation/newConversationModal";
import CreateTeamModal from "components/conversation/newTeamModal";
import ShareModal from "components/conversation/shareModal";
import TeamDetail from "components/teamdetail";
import { logOut } from "containers/auth/actions";
import { createNewConversation } from "containers/consults/actions";
import { getTeams } from "containers/directory/actions";
import {
	fetchAllChats,
	fetchAllConsultChats,
} from "containers/global/actions";
import { orderBy } from "lodash";
import { useEffect, useState } from "react";
import {
	BsArchive,
	BsChatText,
	BsChevronLeft,
	BsClipboardCheck,
	BsGear,
	BsPeople,
} from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { usePhemeContext } from "store/phemeContext";
import styled from "styled-components";
import { getFullName } from "utils/helpers";
import { getChats, getConsults } from "utils/selectors";
import defaultImage from "../assets/images/profilegroup.png";
import Consult from "../components/consult";
import Conversation from "../components/conversation";
import sendBird from "../services/sendbird";
// import sendBird from "services/sendbird";

const ChatNavbar = styled.div`
	width: 100%;
	max-width: 385px;
`;

const tabs = {
	consults: "Consults",
	chat: "Chats",
	team: "Teams",
	archive: "Archived Consults",
};

export default function Consults() {
	const consults = useSelector(getConsults) || [];
	const chats = useSelector(getChats) || [];
	const teams = useSelector((state) => state.directory.teams);
	const [archivedChats, setArchivedChats] = useState([]);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [loading, setLoading] = useState([]);
	const [selectedChat, setSelectedChat] = useState(null);
	const [activeTab, setActiveTab] = useState(tabs.consults);
	const user = useSelector((state) => state.auth.user);
	const [isPlusClicked, setIsPlusClicked] = useState(false);
	const [openConversationModal, setOpenConversationModal] =
		useState(false);
	const [openNewTeamModal, setOpenNewTeamModal] = useState(false);
	const [openConsultModal, setOpenConsultModal] = useState(false);
	const [openShareModal, setOpenShareModal] = useState(false);

	const { navDrawerOpen, setNavDrawerOpen } = usePhemeContext();

	useEffect(() => {
		if (activeTab === "Consults" || activeTab === "Chats") {
			let currentList;
			switch (activeTab) {
				case "Consults":
					currentList = consults;
					break;
				case "Chats":
					currentList = chats;
					break;
			}
			setSelectedChat(currentList[0]);
		} else {
			setSelectedChat(null);
		}
	}, [activeTab]);

	const selectNewConsultFromSidebar = () => {
		window.location.reload();
	};

	const logout = async () => {
		dispatch(logOut());
		setTimeout(() => {
			navigate("/login");
		}, 0);
	};

	useEffect(() => {
		if (sendBird.isConnected()) {
			dispatch(
				fetchAllConsultChats({
					onNext: () => {
						setLoading(false);
					},
				})
			);
		} else {
			sendBird.connect(user).then(() => {
				dispatch(
					fetchAllConsultChats({
						onNext: () => {
							setLoading(false);
						},
					})
				);
			});
		}
	}, []);

	const fetchChatTab = () => {
		dispatch(
			fetchAllChats({
				onSuccess: () => {
					setLoading(false);
				},
			})
		);
	};

	const fetchTeamTab = () => {
		dispatch(getTeams());
	};

	const sortConsults = (consultChats) => {
		const newConsults = consultChats.map((item) => ({
			...item,
			sortTime:
				JSON.parse(item.data)?.archivedOn ||
				item?.lastMessage?.createdAt ||
				item.createdAt,
		}));
		// console.log(
		//   'timesort',
		//   consultChats.length,
		//   newConsults.map((item) => item.sortTime),
		// );
		return orderBy(newConsults, ["sortTime"], "desc");
	};

	const fetchArchivedConsults = () => {
		sendBird.getArchivedChats("chatConsult").then((chat) => {
			setArchivedChats(sortConsults(chat));
			setLoading(false);
		});
	};

	const onChangeTab = (name) => {
		if (name === tabs.chat) {
			fetchChatTab();
		}
		if (name === tabs.consults) {
			dispatch(
				fetchAllConsultChats({
					onNext: () => {
						setLoading(false);
					},
				})
			);
		}
		if (name === tabs.team) {
			fetchTeamTab();
		}
		if (name === tabs.archive) {
			fetchArchivedConsults();
		}
		setActiveTab(name);
	};

	const isActive = (name) => name === activeTab;

	const checkIfHidden = (state) => {
		return (
			state === "hidden_allow_auto_unhide" ||
			state === "hidden_prevent_auto_unhide"
		);
	};

	function arraysEqual(arr1, arr2) {
		// Check if the lengths of the arrays are the same
		if (arr1.length !== arr2.length) {
			return false;
		}

		// Sort both arrays
		const sortedArr1 = arr1.slice().sort();
		const sortedArr2 = arr2.slice().sort();

		// Compare the sorted arrays
		for (let i = 0; i < sortedArr1.length; i++) {
			if (sortedArr1[i] !== sortedArr2[i]) {
				return false;
			}
		}

		return true;
	}

	const onCreateChatForTeam = async ({ members, name, onNext }) => {
		const channels = await sendBird.getChats();
		const teamChannel = channels.find(
			(x) =>
				x.name === name &&
				arraysEqual(
					x.members?.map((m) => m.userId),
					members
				)
		);

		if (channels.length > 0 && teamChannel) {
			setSelectedChat(teamChannel);
			setActiveTab(tabs.chat);
		} else {
			if (members?.length) {
				setLoading(true);
				dispatch(
					createNewConversation({
						members: members,
						name,
						onNext: (data) => {
							console.log("onNext data", data);
							setSelectedChat(data);
							setActiveTab(tabs.chat);
						},
					})
				);
			} else {
				alert("Error creating chat for your team.");
			}
		}
	};

	// const onNextChatForTeam = (data) => {
	//   const createdAt = new Date().valueOf();
	//   dispatch(
	//     sendMessage({
	//       id: data?.url,
	//       message,
	//       sender: {
	//         userId: user?._id
	//       },
	//       channel: data,
	//       type: typeof message === "string" ? "text" : "file",
	//       createdAt
	//     })
	//   );
	//   setLoading(false);
	//   setOpen(false);
	//   setMessage("");
	// };

	return (
		<div>
			<title>Consults</title>
			<div className="h-screen flex flex-row overflow-hidden relative">
				{!navDrawerOpen && (
					<ChatNavbar
						className="flex flex-col flex-initial shrink-0 relative transition-transform duration-300 ease-in-out"
						style={{
							display: navDrawerOpen ? "none" : "",
						}}
					>
						{/** Header */}
						<div className="flex flex-row items-center justify-between border-b border-b-gray-50 items-start p-4">
							<div className="flex flex-row items-center cursor-pointer">
								{user?.profilePhoto ? (
									<img
										src={user?.profilePhoto}
										alt=""
										width={40}
										height={40}
										className="rounded-full w-[40px] h-[40px]"
									/>
								) : (
									<img
										alt=""
										src={defaultImage}
										width={40}
										height={40}
										className="rounded-full w-[40px] h-[40px]"
									/>
								)}
								<div className="flex flex-col mx-2">
									<div className="text-md font-semibold">
										{getFullName(user)}
									</div>
									<div className="text-gray-500 text-xs text-start">
										{user?.title}
									</div>
								</div>
							</div>
							<div className="flex flex-row">
								<button
									onClick={() => navigate("/settings")}
									className="flex flex-row items-center justify-center rounded-full p-1 ml-4"
								>
									<BsGear size={26} className="text-gray-500" />
								</button>
								<button
									onClick={() => setNavDrawerOpen(!navDrawerOpen)}
									className="flex flex-row items-center justify-center rounded-full p-1 ml-4 transition-transform duration-300"
								>
									<BsChevronLeft size={26} className="text-gray-500 " />
								</button>
							</div>
						</div>

						{/** title and chats */}
						<div className="font-bold text-3xl px-4 pt-4">{activeTab}</div>
						{activeTab === tabs.consults ? (
							<Consult
								data={consults}
								selectedChat={selectedChat}
								onSelect={setSelectedChat}
							/>
						) : activeTab === tabs.chat ? (
							<Chat
								data={chats}
								selectedChat={selectedChat}
								onSelect={setSelectedChat}
							/>
						) : activeTab === tabs.archive ? (
							<Consult
								data={archivedChats}
								selectedChat={selectedChat}
								onSelect={setSelectedChat}
							/>
						) : (
							<Team
								data={teams}
								selectedChat={selectedChat}
								onSelect={setSelectedChat}
							/>
						)}
						<FabButton
							isPlusClicked={isPlusClicked}
							setIsPlusClicked={setIsPlusClicked}
							setOpenConversationModal={setOpenConversationModal}
							setOpenConsultModal={setOpenConsultModal}
							setOpenShareModal={setOpenShareModal}
							setOpenNewTeamModal={setOpenNewTeamModal}
						/>
						<div className="flex flex-row justify-between w-full border-t p-4 bottom-0">
							<div className="flex flex-row justify-between w-full">
								<button
									onClick={() => onChangeTab(tabs.consults)}
									className="flex flex-col justify-center items-center"
								>
									<BsClipboardCheck
										size={26}
										className={`${
											isActive(tabs.consults) ? "text-health" : "text-gray-500"
										} mb-2`}
									/>
									<div
										className={`${
											isActive(tabs.consults) ? "text-health" : "text-gray-500"
										} text-xs font-semibold`}
									>
										Consults
									</div>
								</button>
								<button
									onClick={() => onChangeTab(tabs.chat)}
									className="flex flex-col justify-center items-center"
								>
									<BsChatText
										size={26}
										className={`${
											isActive(tabs.chat) ? "text-health" : "text-gray-500"
										} mb-2`}
									/>
									<div
										className={`${
											isActive(tabs.chat) ? "text-health" : "text-gray-500"
										} text-xs font-semibold`}
									>
										Chats
									</div>
								</button>
								<button
									onClick={() => onChangeTab(tabs.team)}
									className="flex flex-col justify-center items-center"
								>
									<BsPeople
										size={26}
										className={`${
											isActive(tabs.team) ? "text-health" : "text-gray-500"
										} mb-2`}
									/>
									<div
										className={`${
											isActive(tabs.team) ? "text-health" : "text-gray-500"
										} text-xs font-semibold`}
									>
										Teams
									</div>
								</button>
								<button
									onClick={() => onChangeTab(tabs.archive)}
									className="flex flex-col justify-center items-center"
								>
									<BsArchive
										size={26}
										className={`${
											isActive(tabs.archive) ? "text-health" : "text-gray-500"
										} mb-2`}
									/>
									<div
										className={`${
											isActive(tabs.archive) ? "text-health" : "text-gray-500"
										} text-xs font-semibold`}
									>
										Archived
									</div>
								</button>
							</div>
						</div>
					</ChatNavbar>
				)}

				{selectedChat ? (
					activeTab === tabs.team ? (
						<TeamDetail
							selectedTeam={selectedChat}
							setSelectedTeam={setSelectedChat}
							onStartChat={(args) => {
								console.log("start chat in cons??", args);
								onCreateChatForTeam({
									members: args.members,
									name: args.title,
								});

								// setSelectedChat(selectedChat);
								onChangeTab(tabs.chat);
							}}
							style={{
								width: navDrawerOpen ? "100vw" : "",
							}}
						/>
					) : (
						<Conversation
							selectedChat={selectedChat}
							isConsult={activeTab !== tabs.chat}
							isArchived={checkIfHidden(selectedChat?.hiddenState)}
							myId={user?._id}
							setSelectedChat={setSelectedChat}
							style={{
								width: navDrawerOpen ? "100vw" : "",
							}}
						/>
					)
				) : (
					<div
						className="flex flex-col items-center justify-center flex-1 bg-gray-50"
						style={{
							width: navDrawerOpen ? "100vw" : "",
						}}
					>
						<button
							onClick={() => setNavDrawerOpen(!navDrawerOpen)}
							className="absolute top-5 left-5"
							style={{
								transform: navDrawerOpen ? "" : "rotate(180deg)",
								display: navDrawerOpen ? "" : "none",
							}}
						>
							<BsChevronLeft size={26} className="text-gray-400 " />
						</button>
						{/* <img src="emptyState.svg" alt="" className="w-auto h-96" /> */}
						<img
							className="w-auto"
							style={{ maxWidth: "200px" }}
							src={"/pheme-logo.svg"}
							alt="logo"
						/>
					</div>
				)}
			</div>
			{/* <ConsultInfoModal open={false} setOpen={setOpen} selectedChat={selectedChat}/> */}
			<ShareModal open={openShareModal} setOpen={setOpenShareModal} />
			<CreateConsultModal
				open={openConsultModal}
				setOpen={setOpenConsultModal}
				selectNewConsultFromSidebar={selectNewConsultFromSidebar}
			/>
			<CreateConversationModal
				open={openConversationModal}
				setOpen={setOpenConversationModal}
			/>
			<CreateTeamModal
				open={openNewTeamModal}
				setOpen={setOpenNewTeamModal}
			/>
			{/** reaction view modal with div */}
		</div>
	);
}
