import * as constants from "./constants";
const initialState = {
	isLoggedIn: false,
	error: null,
};

const authReducer = (state = initialState, actions) => {
	switch (actions.type) {
		case constants.SIGN_IN_SUCCESS: {
			const { user, token } = actions.payload;
			return {
				...state,
				authInProcess: false,
				user,
				token,
				isLoggedIn: true,
				error: null,
			};
		}
		case constants.SEND_PHONE_CODE_FAILED: {
			const { error } = actions.payload;
			return {
				...state,
				error,
			};
		}
		case constants.SIGN_IN_FAILED: {
			const { error } = actions.payload;
			return {
				...state,
				error,
			};
		}
		case constants.LOG_OUT: {
			return initialState;
		}
		case constants.GET_CURRENT_USER_SUCCESS: {
			const { user } = actions.payload;
			return {
				...state,
				user: {
					...user,
					profilePhoto: user?.profilePhoto ? user?.profilePhoto : null,
				},
			};
		}
		case constants.REGISTER_USER_SUCCESS: {
			const { user, token } = actions.payload;
			return {
				...state,
				authInProcess: false,
				isLoggedIn: true,
				user,
				token,
			};
		}
		default:
			return state;
	}
};

export default authReducer;
