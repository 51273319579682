/* eslint-disable react/no-unescaped-entities */
import Button from "components/Button";
import Loader from "components/loader";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import * as S from "../components/onboarding/styled";
import {
	sendPasswordReset,
	signIn,
} from "../containers/auth/actions";
import "../normalize.css";
import { validateEmail } from "../utils/helpers";

const validationSchema = Yup.object().shape({
	email: Yup.string().email("Invalid email").required("Required"),
	password: Yup.string().required("Required"),
});

export default function Login() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const authState = useSelector((state) => state.auth);
	const [showPassword, setShowPassword] = useState(false);
	const [email, setEmail] = useState("");
	const [forgotPasswordModal, setForgotPasswordModal] =
		useState(false);
	const [sendingPasswordReset, setSendingPasswordReset] =
		useState(false);

	console.log(process.env.REACT_APP_API_URL_DEV);

	useEffect(() => {
		if (authState.isLoggedIn) {
			navigate("/consults");
		}
	}, [authState.isLoggedIn]);

	const initialValues = {
		email: "",
		password: "",
	};

	const onForgotPasswordSend = () => {
		if (validateEmail(email.trim())) {
			setSendingPasswordReset(true);
			dispatch(
				sendPasswordReset({
					email: email.trim(),
					onNext: () => {
						setSendingPasswordReset(false);
						setForgotPasswordModal(false);
						setTimeout(() => {
							alert(
								"If you're registered with us then you will receive an email with instructions to reset your password"
							);
						}, 100);
					},
				})
			);
		} else {
			alert("Please enter valid email");
		}
	};

	return (
		<>
			<S.Page>
				<S.InfoSection>
					<div className="container">
						<h1 className="title">
							Modern Communication Platform for Healthcare Professionals
						</h1>
						<p className="description">
							{`Pheme connects busy healthcare professionals and their teams quickly & efficiently with HIPAA compliance.`}
						</p>
						<div className="review-box">
							<p className="name">Jack Summa</p>
							<p className="meta">5 Star Review on App Store</p>
							<p className="meta">13 Nov, 2022</p>
							<p className="text">
								“Using an app such as Pheme helps to ensure that protected
								health information is kept secure in the process of
								transferring important and confidential patient data.”
							</p>
						</div>
					</div>
				</S.InfoSection>
				<S.FormSection>
					<img className="logo" src={"/pheme-logo.svg"} alt="logo" />

					<div className="container">
						<Formik
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={(values, { setSubmitting, setErrors }) => {
								dispatch(
									signIn({
										email: values.email.trim(),
										password: values.password,
										onSuccess: () => {
											setSubmitting(false);
										},
									})
								);
							}}
							validateOnMount
						>
							{({ values, errors, isValid, isSubmitting, handleSubmit }) => (
								<Form
									autoComplete="off"
									onKeyDown={(e) => {
										if (e.key === "Enter" && isValid && !isSubmitting) {
											e.preventDefault();
											handleSubmit();
										}
									}}
								>
									<div className="head">
										<h1 className="title">Welcome Back!</h1>
										<p className="text-account">
											Don't have an account?{" "}
											<span
												onClick={() => navigate("/signup")}
												className="inline-button"
											>
												Sign up
											</span>
										</p>
										{errors.submit && (
											<div className="error-global">{errors.submit}</div>
										)}
									</div>
									<div className="form-field-column">
										<div className="form-field-row">
											<div className="form-field-container">
												<p className="label">Email</p>
												<Field
													name="email"
													type="text"
													placeholder="Enter your email address"
													className="field"
												/>
												<ErrorMessage
													name="email"
													component="div"
													className="error-msg"
												/>
											</div>
										</div>

										<div className="form-field-row">
											<div className="form-field-container">
												<p className="label">Password</p>
												<div className="password-field-container">
													<Field
														name="password"
														type={showPassword ? "text" : "password"}
														placeholder="Enter your password"
														className="field"
														autoComplete="new-password"
													/>
													{values.password && (
														<button
															type="button"
															onClick={() => setShowPassword(!showPassword)} // Toggle the showPassword state
															className="show-hide-button"
														>
															{showPassword ? "Hide" : "Show"}
														</button>
													)}
												</div>
												<ErrorMessage
													name="password"
													component="div"
													className="error-msg"
												/>
											</div>
										</div>

										<div className="forgot-password-container">
											<span
												onClick={() => setForgotPasswordModal(true)}
												className="inline-button"
											>
												Forgot password?
											</span>
										</div>

										<div className="button-container">
											{console.log("isSubmitting", isSubmitting)}
											{console.log("!isValid", !isValid)}
											<Button
												buttonType="primary"
												type="submit"
												disabled={isSubmitting || !isValid}
											>
												{isSubmitting ? "Loading" : "Login"}
											</Button>
										</div>
									</div>
								</Form>
							)}
						</Formik>
					</div>
					<div
						className={`${
							forgotPasswordModal ? "flex" : "hidden"
						} fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50`}
					>
						<div className="flex flex-col justify-center items-center w-full h-full">
							<div className="flex flex-col bg-white rounded-xl w-[400px] h-[320px] p-8">
								<div className="flex flex-row justify-between items-center">
									<div className="text-gray-800 text-xl font-semibold">
										Forgot Password
									</div>
									<button
										className="text-gray-500 text-xl font-semibold cursor-pointer"
										onClick={() => setForgotPasswordModal(false)}
									>
										×
									</button>
								</div>
								<div className="text-gray-500 text-sm my-4">
									Enter your email address and we'll send you a link to reset
									your password.
								</div>
								<div className="form-field-row">
									<div className="form-field-container">
										<p className="label">Email</p>
										<input
											name="email"
											type="text"
											placeholder="Enter your email address"
											className="field"
											value={email}
											onChange={(e) => setEmail(e.target.value)}
										/>
									</div>
								</div>
								<div className="flex flex-row justify-end mt-8">
									<button
										onClick={onForgotPasswordSend}
										className="bg-health text-white rounded-lg p-2 w-full font-semibold flex items-center justify-center h-12"
									>
										{sendingPasswordReset ? <Loader color="white" /> : "Send"}
									</button>
								</div>
							</div>
						</div>
					</div>
				</S.FormSection>
			</S.Page>
			{/** forgot Password Modal */}
		</>
	);
}
