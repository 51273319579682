import { BsFillTrashFill } from "react-icons/bs";
import defaultImage from "../../assets/images/profilesingle.png";

const { BiChevronRight } = require("react-icons/bi");

const User = ({
	userIsAdmin,
	member,
	name,
	img,
	profession,
	isActive = false,
	isProfessonBlack,
	onClick,
	isAdmin,
	setRemoveModalOpen,
	showAbilityToRemoveUser,
}) => {
	const teamMember =
		isAdmin && showAbilityToRemoveUser ? `${name} (Admin)` : name;
	return (
		<button
			onClick={onClick}
			className="flex flex-row items-center w-full mb-2 hover:bg-gray-50 p-2 rounded"
		>
			<div className="relative">
				{img ? (
					<img
						src={img}
						alt=""
						className="rounded-full min-w-[45px] h-[45px]"
					/>
				) : (
					<img
						src={defaultImage}
						alt=""
						className="rounded-full min-w-[45px] h-[45px]"
					/>
				)}
				{isActive && (
					<span className="absolute text-green-500 right-0 bottom-0">
						<svg width="15" height="15">
							<circle cx="6" cy="6" r="6" fill="currentColor"></circle>
						</svg>
					</span>
				)}
			</div>
			<div className="flex w-full justify-between">
				<div className="flex flex-col pl-2 text-start">
					<div className="text-sm whitespace-nowrap">{teamMember}</div>
					<span
						className={`${
							isProfessonBlack ? "text-gray-500" : "text-red-500"
						} text-xs font-semibold`}
					>
						{profession}
					</span>
				</div>
				{!isAdmin && userIsAdmin && showAbilityToRemoveUser && (
					<BsFillTrashFill
						className="text-red-500"
						onClick={() => setRemoveModalOpen({ open: true, member })}
					/>
				)}
			</div>
		</button>
	);
};

export default User;
