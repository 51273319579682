import { replyToMessage } from "containers/consults/actions";
import {
	fetchAllChats,
	fetchAllConsultChats,
	sendMessage,
} from "containers/global/actions";
import { useEffect, useState } from "react";
import { BsX } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import sendBird from "services/sendbird";
import styled from "styled-components";
import { emojis } from "utils/constants";
import Composer from "../composer";
import ChatInfoModal from "./chatInfoModal";
import ConsultInfoModal from "./consultInfoModal";
import Header from "./header";
import MessageList from "./messagesList";

const ConversationWrapper = styled.div`
	width: calc(100vw - 385px);
`;

export default function Conversation({
	isConsult = false,
	selectedChat,
	setSelectedChat,
	isArchived = false,
	...restProps
}) {
	const user = useSelector((state) => state.auth.user);
	const myId = user?._id;
	const dispatch = useDispatch();
	const [sender, setSender] = useState({});
	const [showInfo, setShowInfo] = useState(false);
	const { members, url, name, coverUrl } = selectedChat || {};
	const [showReactions, setShowReactions] = useState(false);
	const [reactionsData, setReactionsData] = useState([]);
	const [consultModalOpen, setConsultModalOpen] = useState(false);
	const [chatModalOpen, setChatModalOpen] = useState(false);
	const [isReplying, setIsReplying] = useState(false);
	const [replyingTo, setReplyingTo] = useState({});

	const allUsers = {};

	reactionsData.map((item) => {
		allUsers[item.key] = item.userIds.map((userId) => ({
			...members.find((member) => member.userId === userId),
			key: item.key,
		}));
	});

	console.log("reactions", allUsers);

	const isAdmin =
		members?.find((x) => x.userId === myId)?.role === "operator";

	const [title, setTitle] = useState("");

	useEffect(() => {
		setShowInfo(false);
	}, [selectedChat]);

	const onSend = (message, type) => {
		const createdAt = new Date().valueOf();
		if (isReplying && replyingTo?.messageId) {
			dispatch(
				replyToMessage({
					id: selectedChat?.url,
					message,
					sender: {
						userId: myId,
					},
					parentMessage: replyingTo,
					channel: selectedChat,
					type,
					createdAt,
				})
			);

			setIsReplying(false);
			setReplyingTo({});
		} else {
			dispatch(
				sendMessage({
					id: selectedChat?.url,
					message,
					sender: {
						userId: myId,
					},
					channel: selectedChat,
					type,
					createdAt,
				})
			);
		}
	};

	useEffect(() => {
		sendBird.markAsRead(selectedChat).then(() => {
			if (isConsult) {
				dispatch(fetchAllConsultChats());
			} else {
				dispatch(fetchAllChats({}));
			}
		});
		if (members?.length === 2) {
			const chatUser = members.find((usr) => usr.userId !== myId);
			setSender(chatUser);
			setTitle(`${chatUser.nickname || chatUser?.firstName || ""}`);
		}
		if (members?.length === 1) {
			const chatUser = members[0];
			setSender(chatUser);
			setTitle(chatUser.nickname || chatUser?.firstName || "");
		}
		if (members?.length === 3) {
			const chatUser = members.filter((usr) => usr.userId !== myId);
			if (chatUser.length === 2) {
				setTitle(
					`You, ${
						getFirstName(chatUser[0].nickname) || ""
					} and ${getFirstName(chatUser[1].nickname)}`
				);
			} else if (chatUser.length === 1) {
				setTitle(`${getFirstName(chatUser[0].nickname)}`);
			}
		}
		if (members?.length > 3) {
			const chatUser = members.filter((usr) => usr.userId !== myId);
			setTitle(`You and ${chatUser.length} others`);
		}
	}, [url, members, myId]);

	const getFirstName = (n) => {
		return n.split(" ")[0];
	};

	return (
		<>
			<ConversationWrapper
				className="flex flex-col border-l"
				{...restProps}
			>
				<Header
					selectedChat={selectedChat}
					user={sender}
					title={title}
					isConsult={isConsult}
					isArchived={isArchived}
					isAdmin={isAdmin}
					setSelectedChat={setSelectedChat}
					onShowInfo={() => setShowInfo(!showInfo)}
					setConsultModalOpen={setConsultModalOpen}
					setChatModalOpen={setChatModalOpen}
				/>
				<MessageList
					id={selectedChat.url}
					setReactionsData={setReactionsData}
					setShowReactions={setShowReactions}
					selectedChat={selectedChat}
					onReply={(message) => {
						// console.log("replying to message", message);
						// dispatch(replyToMessage(message));

						setIsReplying(true);
						setReplyingTo(message);
					}}
				/>
				{/* {!isArchived && (
					<Composer
						onSend={onSend}
						isReplying={isReplying}
						replyingTo={replyingTo}
						handleCancelReply={() => {
							setIsReplying(false);
							setReplyingTo({});
						}}
					/>
				)} */}

				<Composer
					onSend={onSend}
					isReplying={isReplying}
					replyingTo={replyingTo}
					handleCancelReply={() => {
						setIsReplying(false);
						setReplyingTo({});
					}}
				/>

				{/* {isConsult && <ConsultInfo selectedChat={selectedChat} />} */}
				<div
					className={`fixed top-0 left-0 w-full h-full z-50 bg-black bg-opacity-50 flex justify-center items-center ${
						showReactions ? "block" : "hidden"
					}`}
				>
					<div className="bg-white rounded-lg p-4 w-96">
						<div className="flex justify-between items-center">
							<div className="text-xl font-semibold">Reactions</div>
							<button
								onClick={() => setShowReactions(false)}
								className="text-2xl"
							>
								<BsX />
							</button>
						</div>
						<div className="flex flex-wrap mt-4">
							{Object.values(allUsers)
								.flat()
								.map((reaction) => (
									<div
										key={reaction._id}
										className="flex flex-row items-center justify-center mr-4 mb-2 space-x-2"
									>
										<img
											src={emojis.find((x) => x.key === reaction.key).url}
											alt=""
											className="w-8 h-8 rounded-full"
										/>
										<div className="text-sm font-semibold mt-2">
											{reaction.nickname}
										</div>
									</div>
								))}
						</div>
					</div>
				</div>
			</ConversationWrapper>
			<ChatInfoModal
				open={chatModalOpen}
				setOpen={setChatModalOpen}
				user={sender}
				members={members}
			/>
			<ConsultInfoModal
				open={consultModalOpen}
				setOpen={setConsultModalOpen}
				selectedChat={selectedChat}
			/>
		</>
	);
}
