import Loader from "components/loader";
import User from "components/user";
import { fetchDirectory } from "containers/directory/actions";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { getFullName } from "utils/helpers";
import Chip from "./chip";

const defaultImage =
	"https://www.nicepng.com/png/detail/933-9332131_profile-picture-default-png.png";

const SearchBox = ({ value, onChange }) => {
	const searchInput = useRef(null);

	const [searchTerm, setSearchTerm] = useState("");
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const [users, setUsers] = useState([]);
	const [selectedPeople, setSelectedPeople] = useState([]);
	const [searchResults, setSearchResults] = useState([]);

	const onUserSelect = (item) => {
		setSearchTerm("");
		const $selectedPeople = [...selectedPeople];
		if (selectedPeople.findIndex((i) => i._id === item._id) === -1) {
			$selectedPeople.push(item);
			onChange($selectedPeople);
			setSelectedPeople($selectedPeople);
		} else {
			removeSelectedItem(item);
		}
		searchInput.current.focus();
	};

	const removeSelectedItem = (usr) => {
		const $selectedPeople = [...selectedPeople];
		const filteredPeople = $selectedPeople.filter(
			(item) => item._id !== usr._id
		);
		onChange(filteredPeople);
		setSelectedPeople(filteredPeople);
	};

	const onSearch = (e) => {
		const txt = e.target.value;
		setSearchTerm(txt);
		setLoading(true);
		dispatch(
			fetchDirectory({
				searchString: txt,
				setSearchResults: (resp) => {
					setLoading(false);
					const selectedMembers = resp.filter(
						(item) => users.findIndex((usr) => usr._id === item._id) === -1
					);
					setSearchResults(selectedMembers);
				},
			})
		);

		if (txt === "") {
			setLoading(false);
			setSearchResults([]);
		}
	};

	return (
		<div className="relative mb-6">
			<input
				ref={searchInput}
				autoFocus
				label="To"
				className="focus:outline-none border-transparent appearance-none w-full p-3 bg-white ring-1 ring-gray-300 focus:ring-login-blue focus:border-login-blue rounded mt-2 focus:z-10 sm:text-sm "
				placeholder="To"
				onChange={onSearch}
				value={searchTerm}
			/>
			<div className="flex space-x-2 mt-2 overflow-x-auto scrollbar-hide">
				{selectedPeople.map((item) => (
					<Chip
						name={getFullName(item)}
						img={item?.profilePhoto || defaultImage}
						onClose={() => onUserSelect(item)}
					/>
				))}
			</div>
			{searchTerm !== "" && (
				<div className="absolute right-0 top-12 w-full  mt-2 p-2 bg-white shadow-xl rounded z-30 max-h-72  overflow-y-auto">
					{loading && (
						<div className="w-full flex items-center justify-center p-2">
							<Loader />
						</div>
					)}
					{searchResults.map((item) => (
						<User
							img={item?.profilePhoto || defaultImage}
							name={getFullName(item)}
							profession={item?.profession}
							isProfessonBlack
							onClick={() => onUserSelect(item)}
						/>
					))}
				</div>
			)}
		</div>
	);
};

export default SearchBox;
