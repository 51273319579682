import Loader from "components/loader";
import { getCurrentUser, updateUser } from "containers/auth/actions";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { urls } from "utils/constants";
import { getApi, validatePassword } from "utils/helpers";
import { getToken } from "utils/selectors";

export default function ChangePassword() {
	const user = useSelector((state) => state.auth.user);
	const [oldPassword, setCurrentPassword] = React.useState("");
	const [newPassword, setNewPassword] = React.useState("");
	const dispatch = useDispatch();
	const token = useSelector(getToken);
	const [loading, setLoading] = React.useState(false);

	const onSavePress = async () => {
		try {
			if (newPassword && oldPassword) {
				if (
					validatePassword(newPassword) &&
					validatePassword(oldPassword)
				) {
					setLoading(true);
					const api = await getApi(token);
					const { data } = await api.post(urls.changePassword, {
						oldPassword: oldPassword,
						newPassword: newPassword,
					});
					if (data.success) {
                        setLoading(false);
						alert("Password changed successfully");
					} else {
                        setLoading(false);
						alert(data.msg);
					}
				} else {
					alert(
						"Password must be at least 6 characters long and have 1 number and 1 uppercase letter"
					);
				}
			} else {
                setLoading(false);
				console.log(newPassword, oldPassword);
				alert("Please fill in all the fields");
			}
			//
		} catch (e) {
            setLoading(false);
			alert(e.response.data.msg);
			console.log(e.response.data.msg);
		}
	};

	return (
		<div className="overflow-hidden bg-white sm:rounded-lg w-full">
			<div className="px-4 py-5 sm:px-6">
				<h3 className="text-lg font-medium leading-6 text-gray-900">
					Change Password
				</h3>
			</div>
			<div className="border-t border-gray-200 p-8">
				<div className="flex w-full flex-col items-start">
					<div className="w-96">
						<label
							htmlFor="email-address"
							className="font-semibold text-sm"
						>
							Current Password
						</label>
						<input
							className="focus:outline-none border-transparent appearance-none w-full p-3 bg-white ring-1 ring-gray-300 focus:ring-login-blue focus:border-login-blue rounded mt-2 focus:z-10 sm:text-sm mb-6"
							placeholder="Current Password"
							value={oldPassword}
							onChange={(e) => setCurrentPassword(e.target.value)}
						/>
					</div>
					<div className="w-96">
						<label
							htmlFor="email-address"
							className="font-semibold text-sm"
						>
							New Password
						</label>
						<input
							className="focus:outline-none border-transparent appearance-none w-full p-3 bg-white ring-1 ring-gray-300 focus:ring-login-blue focus:border-login-blue rounded mt-2 focus:z-10 sm:text-sm mb-6"
							placeholder="New Password"
							value={newPassword}
							onChange={(e) => setNewPassword(e.target.value)}
						/>
					</div>
				</div>
				<button
					type="button"
					className="justify-center items-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-health text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-login-blue sm:w-40 sm:text-sm"
					onClick={onSavePress}
				>
					{loading ? (
						<Loader color="text-white" />
					) : (
						"Change Password"
					)}
				</button>
			</div>
		</div>
	);
}
