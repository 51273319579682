import { Dialog, Transition } from "@headlessui/react";
import User from "components/user";
import { Fragment, useRef, useState } from "react";
import Lightbox from "react-awesome-lightbox";
import { GoLocation } from "react-icons/go";
import { MdSubtitles, MdWorkOutline } from "react-icons/md";
import { getInitials } from "utils/helpers";

import "react-datepicker/dist/react-datepicker.css";

export default function ChatInfoModal({
	open,
	setOpen,
	user,
	members,
}) {
	const cancelButtonRef = useRef(null);
	const [showImage, setShowImage] = useState(false);

	const Info = () => {
		if (members?.length > 2) {
			return (
				<div>
					<div className="flex flex-row items-center justify-between w-full mt-3 mb-2">
						<div className="text-md text-gray-600 font-semibold">
							Members
						</div>
						<button className="text-xs text-red-600 font-semibold px-2 py-1 bg-red-100 rounded-full">
							+ Add to Chat
						</button>
					</div>
					{members?.map((member, index) => (
						<User
							name={member.nickname}
							img={member?.profilePhoto}
							isActive={member?.connectionStatus === "active"}
						/>
					))}
				</div>
			);
		}

		return (
			<div className="flex flex-col p-4 pt-4 min-w-[18rem] max-w-[20rem] items-center border-l">
				<div className="w-[110%] font-bold font-xl min-h-[70px] max-h-[70px] pt-4 pb-8 border-b border-gray-100 -ml-4 pl-4 mb-4">
					Chat Info
				</div>
				{user?.profilePhoto ? (
					<img
						alt=""
						src={user.profilePhoto}
						width={100}
						height={100}
						onClick={() => setShowImage(true)}
						className="rounded-full self-center w-[100px] h-[100px] cursor-pointer"
					/>
				) : (
					<div class="mr-2 w-28 h-28 relative flex justify-center items-center rounded-full bg-red-500 text-2xl text-white uppercase">
						{getInitials(user?.nickname)}
					</div>
				)}
				<div className="font-bold text-sm mt-4">{user?.nickname}</div>
				<div className="flex flex-row items-start mt-2">
					<MdWorkOutline size={16} className="text-gray-400" />
					<div className="ml-2 font-regular text-sm -mt-0.5 text-gray-400 max-w-[16rem] text-center">
						{user?.title}
					</div>
				</div>
				<div className="flex flex-col w-full border-t mt-4 pt-4">
					<div className="text-xs text-gray-400">Address</div>
					<div className="flex flex-row items-start mt-2">
						<GoLocation size={16} className="text-gray-400" />
						<div className="font-regular text-sm ml-2 -mt-0.5 text-gray-700 max-w-[24rem] text-start">
							{user?.organizationCity}
						</div>
					</div>
					{/* <div className="text-xs text-gray-400 mt-4">Phone</div>
					<div className="flex flex-row items-start mt-2">
						<BsTelephoneFill size={16} className="text-gray-400" />
						<div className="ml-2 font-regular text-sm -mt-0.5 text-gray-700 max-w-[16rem] text-center">
							+91-9888888888
						</div>
					</div>
					<div className="text-xs text-gray-400 mt-4">Email</div>
					<div className="flex flex-row items-start mt-2">
						<HiMail size={16} className="text-gray-400" />
						<div className="ml-2 font-regular text-sm -mt-0.5 text-gray-700 max-w-[16rem] text-center">
							robdeleonardis@phemehealth.com
						</div>
					</div> */}
					<div className="text-xs text-gray-400 mt-4">Profession</div>
					<div className="flex flex-row items-start mt-2">
						<MdSubtitles size={16} className="text-gray-400" />
						<div className="ml-2 font-regular text-sm -mt-0.5 text-gray-700 max-w-[16rem] text-center">
							{user?.profession}
						</div>
					</div>
				</div>
				{showImage && (
					<Lightbox
						image={user?.profilePhoto}
						onClose={() => setShowImage(false)}
					/>
				)}
			</div>
		);
	};

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as="div"
				className="relative z-10"
				initialFocus={cancelButtonRef}
				onClose={() => {}}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed z-10 inset-0 overflow-y-auto">
					<div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
								<div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
									<div className="text-gray-700 font-bold text-lg mb-3">
										Chat Detail
									</div>
									<Info />
								</div>
								<div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
									<button
										type="button"
										className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-login-blue sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
										onClick={() => setOpen(false)}
										ref={cancelButtonRef}
									>
										Close
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
