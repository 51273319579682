import { Dialog, Transition } from "@headlessui/react";
import Loader from "components/loader";
import {
	createNewConversation,
	getConsults,
} from "containers/consults/actions";

import { Fragment, useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";

export default function RemoveUserFromChatModal({
	members,
	member,
	open,
	setOpen,
	channel,
}) {
	const cancelButtonRef = useRef(null);
	const dispatch = useDispatch();
	const user = useSelector((state) => state.auth.user);
	const [message, setMessage] = useState("");
	const [loading, setLoading] = useState(false);
	const [newMembers, setNewMembers] = useState([]);
	// const [selectedPeople, setSelectedPeople] = useState([]);

	// const newMembers = members && member ? members.filter((user) => user.userId !== member.userId) : [];
	// console.log(newMembers)

	useEffect(() => {
		console.log("something changed", members, member);
		if (members?.length && member && member?.length) {
			const filteredMembers = members.filter(
				(user) => user.userId !== member
			);
			setNewMembers([...filteredMembers]);
		}
	}, [members, member]);

	useEffect(() => {
		console.log("new members", newMembers);
	}, [newMembers]);

	const onRemoveUser = async () => {
		if (newMembers.length) {
			setLoading(true);
			dispatch(
				createNewConversation({
					member,
					members,
					type: "remove",
					channel,
					onNext: () => {
						setTimeout(() => {
							setLoading(false);
							setOpen({ open: false, member: {} });
							window.location.reload();
						}, 2000);
						dispatch(getConsults());
					},
				})
			);
		}
	};
	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as="div"
				className="relative z-10"
				initialFocus={cancelButtonRef}
				onClose={() => {}}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed z-10 inset-0 overflow-y-auto">
					<div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
								<div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
									<div className="text-gray-700 font-bold text-lg mb-3">
										Remove
									</div>
								</div>
								<div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
									<button
										type="button"
										className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-login-blue sm:ml-3 sm:w-36 sm:text-sm"
										onClick={onRemoveUser}
									>
										{loading ? <Loader color="text-white" /> : "Remove"}
									</button>
									<button
										type="button"
										className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-login-blue sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
										onClick={() => setOpen({ open: false, member: {} })}
										ref={cancelButtonRef}
									>
										Cancel
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
