import { Dialog, Transition } from "@headlessui/react";
import Loader from "components/loader";
import { Fragment, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { BsFacebook, BsTwitter, BsWhatsapp, BsMailbox } from "react-icons/bs";


export default function ShareModal({ open, setOpen }) {
	const cancelButtonRef = useRef(null);
	const [loading, setLoading] = useState(false);

	const encodedUrl = encodeURIComponent('https://www.phemehealth.com/');
	const encodedTitle = encodeURIComponent('Hey, Id like to consult with you about a patient matter using Pheme: a fast, secure and HIPAA compliant messaging app designed for medical professionals. You can download Pheme for free');

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as="div"
				className="relative z-10"
				initialFocus={cancelButtonRef}
				onClose={() => {}}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed z-10 inset-0 overflow-y-auto">
					<div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
								<div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
									<div className="text-gray-700 font-bold text-lg mb-3">
										Pheme a Friend
									</div>
									<div className="flex justify-left">
											<BsFacebook className="cursor-pointer mx-2" onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`, "_blank")}/>
											<BsTwitter className="cursor-pointer mx-2" onClick={() => window.open(`https://twitter.com/intent/tweet?text=${encodedTitle}&url=${encodedUrl}`, "_blank")}/>
											<BsWhatsapp className="cursor-pointer mx-2" onClick={() => window.open(`https://api.whatsapp.com/send?text=${encodedTitle} ${encodedUrl}`)}/>
											<BsMailbox className="cursor-pointer mx-2" onClick={() => window.open(`mailto:?subject=${encodedTitle}&body=${encodedUrl}`)}/>
									</div>
								</div>

								<div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
									<button
										type="button"
										className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-login-blue sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
										onClick={() => setOpen(false)}
										ref={cancelButtonRef}
									>
										Cancel
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
