/* eslint-disable react/no-unescaped-entities */
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import * as S from "../components/onboarding/styled";
import {
	registerUser,
	sendPhoneCode,
	verifyPhoneCode,
} from "../containers/auth/actions";
import "../normalize.css";

import Button from "components/Button";
import OTPForm from "components/otpform";
import { useNavigate } from "react-router-dom";
import {
	formatPhoneNumber,
	professionsList,
	stateList,
} from "utils/constants";

const validationSchema = Yup.object().shape({
	firstName: Yup.string().required("First Name is required"),
	lastName: Yup.string().required("Last Name is required"),
	email: Yup.string()
		.email("Invalid email address")
		.required("Email is required"),
	phoneNumber: Yup.string().required("Phone Number is required"),
	state: Yup.string().required("State is required"),
	address: Yup.string(),
	profession: Yup.string(),
	title: Yup.string(),
	password: Yup.string()
		.required("Password is required")
		.min(6, "Password must be at least 6 characters long"),
	confirmPassword: Yup.string()
		.required("Confirm Password is required")
		.oneOf([Yup.ref("password"), null], "Passwords must match"),
	isChecked: Yup.boolean()
		.oneOf(
			[true],
			"You must accept the Terms of Service and Privacy Policy"
		)
		.required(
			"You must accept the Terms of Service and Privacy Policy"
		),
});

export default function Signup() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	// Exposes redux state
	const authState = useSelector((state) => state.auth);

	// Handles the show/hide password inside the form
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] =
		useState(false);

	// Handles the OTP open and close
	const [openOTPVerify, setOpenOTPVerify] = useState(false);

	// Even though we are using Formik to handle form state, these are for the onResendCode function
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [password, setPassword] = useState("");
	const [state, setState] = useState("");
	const [address, setAddress] = useState("");
	const [profession, setProfession] = useState("");
	const [title, setTitle] = useState("");
	const [userType, setUserType] = useState("patient");
	// const [isPatient, setIsPatient] = useState(false);

	console.log(process.env.REACT_APP_API_URL_DEV);

	useEffect(() => {
		if (authState.isLoggedIn) {
			navigate("/consults");
		}
	}, [authState.isLoggedIn]);

	const initialValues = {
		// isPatient: false,
		firstName: "",
		lastName: "",
		email: "",
		phoneNumber: "",
		state: stateList[0].value,
		address: "",
		profession: professionsList[0].value,
		title: "",
		password: "",
		confirmPassword: "",
		isChecked: false,
	};

	const onVerifyCode = (otp, actions) => {
		dispatch(
			verifyPhoneCode({
				code: otp,
				phone: phoneNumber,
				onNext: () => {
					actions.setSubmitting(false);
					dispatch(
						registerUser({
							user: {
								firstName,
								lastName,
								email: email.trim(),
								phone: phoneNumber,
								password,
								phoneCode: otp,
								organization: "",
								organizationState: state,
								organizationCity: address,
								profession: userType === "patient" ? "N/A" : profession,
								title,
							},
							onNext: () => {
								navigate("/");
							},
						})
					);
				},
			})
		);
	};

	const onSubmit = async (values, { setSubmitting }) => {
		setFirstName(values.firstName);
		setLastName(values.lastName);
		setEmail(values.email);
		setPhoneNumber(values.phoneNumber);
		setPassword(values.password);
		setState(values.state);
		setAddress(values.address);
		setProfession(values.profession);
		setTitle(values.title);

		dispatch(
			sendPhoneCode({
				firstName: values.firstName,
				lastName: values.lastname,
				email: values.email,
				phone: values.phoneNumber,
				onNext: () => {
					setOpenOTPVerify(true);
					setSubmitting(false);
				},
			})
		);
	};

	return (
		<>
			<S.Page>
				<S.InfoSection>
					<div className="container">
						<h1 className="title">
							Modern Communication Platform for Healthcare Professionals
						</h1>
						<p className="description">
							{`Pheme connects busy healthcare professionals and their teams quickly & efficiently with HIPAA compliance.`}
						</p>
						<div className="review-box">
							<p className="name">Jack Summa</p>
							<p className="meta">5 Star Review on App Store</p>
							<p className="meta">13 Nov, 2022</p>
							<p className="text">
								“Using an app such as Pheme helps to ensure that protected
								health information is kept secure in the process of
								transferring important and confidential patient data.”
							</p>
						</div>
					</div>
				</S.InfoSection>
				<S.FormSection>
					<img className="logo" src={"/pheme-logo.svg"} alt="logo" />
					{openOTPVerify ? (
						<div className="container">
							<div className="head">
								<h1 className="title">Verify Phone</h1>
								<p className="text-description">
									{`We've sent a 6-digit code to +1 ${phoneNumber},\nplease enter it below to create your account.`}
								</p>
								{authState?.error?.data?.status === 401 && (
									<div className="error-global">
										{authState?.error?.status === 401 &&
											"The code you entered is invalid, please try again."}
									</div>
								)}
								<OTPForm
									onClose={() => setOpenOTPVerify(false)}
									firstName={firstName}
									lastName={lastName}
									email={email}
									phone={phoneNumber}
									onVerifyCode={onVerifyCode}
								/>
							</div>
						</div>
					) : (
						<div className="container">
							<Formik
								initialValues={initialValues}
								validationSchema={validationSchema}
								onSubmit={onSubmit}
								validateOnMount
							>
								{({ values, errors, isValid, isSubmitting }) => (
									<Form autoComplete="off">
										<div className="head">
											<h1 className="title">Sign up</h1>
											<p className="text-account">
												Have an account?{" "}
												<span
													onClick={() => navigate("/login")}
													className="inline-button"
												>
													Login
												</span>
											</p>
											{authState?.error?.status === 409 && (
												<div className="error-global">
													{authState?.error?.status === 409 &&
														"It seems you already have an account with us. If you're having trouble logging in or need assistance with your account, please don't hesitate to contact our support team"}
												</div>
											)}
										</div>
										<div className="form-field-column">
											<div className="form-field-row">
												<div className="form-field-container">
													<p className="label">{`Are you a Provider or a Patient? (I'm a ${
														userType === "provider" ? "provider" : "patient"
													})`}</p>
													<S.FormFieldTab>
														<S.FormFieldTabItem
															active={userType === "provider"}
															onClick={() => setUserType("provider")}
														>
															Provider
														</S.FormFieldTabItem>
														<S.FormFieldTabItem
															active={userType === "patient"}
															onClick={() => setUserType("patient")}
														>
															Patient
														</S.FormFieldTabItem>
													</S.FormFieldTab>
												</div>
											</div>

											{/** First Name and Last Name */}
											<div className="form-field-row">
												<div className="form-field-container">
													<p className="label">First Name</p>
													<Field
														name="firstName"
														type="text"
														placeholder="Enter your first name"
														className="field"
													/>
													<ErrorMessage
														name="firstName"
														component="div"
														className="error-msg"
													/>
												</div>
												<div className="form-field-container">
													<p className="label">Last Name</p>
													<Field
														name="lastName"
														type="text"
														placeholder="Enter your last name"
														className="field"
													/>
													<ErrorMessage
														name="lastName"
														component="div"
														className="error-msg"
													/>
												</div>
											</div>

											{/** Email and Phone */}
											<div className="form-field-row">
												<div className="form-field-container">
													<p className="label">Email</p>
													<Field
														name="email"
														type="text"
														placeholder="Enter your email address"
														className="field"
													/>
													<ErrorMessage
														name="email"
														component="div"
														className="error-msg"
													/>
												</div>
												<div className="form-field-container">
													<p className="label">Phone Number</p>
													<Field name="phoneNumber">
														{({ field, form }) => (
															<input
																{...field}
																onChange={(event) => {
																	form.setFieldValue(
																		"phoneNumber",
																		formatPhoneNumber(event.target.value)
																	);
																}}
																placeholder="(123) 456-7890"
																maxLength={14}
																className="field"
															/>
														)}
													</Field>
													<ErrorMessage
														name="phoneNumber"
														component="div"
														className="error-msg"
													/>
												</div>
											</div>

											{/** Title and Profession */}
											{userType === "provider" && (
												<div className="form-field-row">
													<div className="form-field-container">
														<p className="label">Title / Specialization</p>
														<Field
															name="title"
															type="text"
															placeholder="Enter your title or specialization"
															className="field"
														/>
														<ErrorMessage
															name="title"
															component="div"
															className="error-msg"
														/>
													</div>
													<div className="form-field-container">
														<p className="label">Profession</p>
														<Field name="profession" as="select" className="field">
															{professionsList.map((profession) => (
																<option key={profession.value}>
																	{profession.label}
																</option>
															))}
														</Field>
														<ErrorMessage
															name="profession"
															component="div"
															className="error-msg"
														/>
													</div>
												</div>
											)}

											{/** State and Address */}
											<div className="form-field-row">
												<div className="form-field-container">
													<p className="label">State</p>
													<Field name="state" as="select" className="field">
														{stateList.map((state) => (
															<option key={state.value}>{state.label}</option>
														))}
													</Field>
													<ErrorMessage
														name="state"
														component="div"
														className="error-msg"
													/>
												</div>
												{userType === "provider" && (
													<div className="form-field-container">
														<p className="label">Address</p>
														<Field
															name="address"
															type="text"
															placeholder="Enter your address"
															className="field"
														/>
														<ErrorMessage
															name="address"
															component="div"
															className="error-msg"
														/>
													</div>
												)}
											</div>

											{/** Password and Repeat Password */}
											<div className="form-field-row">
												<div className="form-field-container">
													<p className="label">Password</p>
													<div className="password-field-container">
														<Field
															name="password"
															type={showPassword ? "text" : "password"}
															placeholder="Enter your password"
															className="field"
															autoComplete="new-password"
														/>
														{values.password && (
															<button
																type="button"
																onClick={() => setShowPassword(!showPassword)} // Toggle the showPassword state
																className="show-hide-button"
															>
																{showPassword ? "Hide" : "Show"}
															</button>
														)}
													</div>
													<ErrorMessage
														name="password"
														component="div"
														className="error-msg"
													/>
												</div>
												<div className="form-field-container">
													<p className="label">Confirm Password</p>
													<div className="password-field-container">
														<Field
															name="confirmPassword"
															type={showConfirmPassword ? "text" : "password"}
															placeholder="Enter your password"
															className="field"
															autoComplete="new-password"
														/>
														{values.confirmPassword && (
															<button
																type="button"
																onClick={() =>
																	setShowConfirmPassword(!showConfirmPassword)
																} // Toggle the showPassword state
																className="show-hide-button"
															>
																{showConfirmPassword ? "Hide" : "Show"}
															</button>
														)}
													</div>
													<ErrorMessage
														name="confirmPassword"
														component="div"
														className="error-msg"
													/>
												</div>
											</div>

											<div className="form-field-checkbox-container">
												<div className="form-field-checkbox-row">
													<Field
														name="isChecked"
														render={({ field }) => (
															<input
																{...field}
																type="checkbox"
																className="checkbox"
															/>
														)}
													/>
													<p className="text">
														I agree to the Terms of Service and Privacy Policy
													</p>
												</div>
												<ErrorMessage
													name="isChecked"
													component="div"
													className="error-msg"
												/>
											</div>

											<div className="button-container">
												{/* {console.log("isSubmitting", isSubmitting)}
												{console.log("!isValid", !isValid)} */}
												<Button
													buttonType="primary"
													type="submit"
													disabled={isSubmitting || !isValid}
												>
													{isSubmitting ? "Loading" : "Sign up"}
												</Button>
											</div>
										</div>
									</Form>
								)}
							</Formik>
						</div>
					)}
				</S.FormSection>
			</S.Page>
		</>
	);
}
