import SendbirdChat from "@sendbird/chat";
import {
	GroupChannelHandler,
	GroupChannelModule,
} from "@sendbird/chat/groupChannel";
import {
	fetchAllChats,
	fetchAllConsultChats,
} from "containers/global/actions";
import { store } from "../store";
import { isProduction } from "../utils/constants";
import { getFullName } from "../utils/helpers";

class SendBirdClient {
	constructor() {
		this.client = SendbirdChat.init({
			appId: isProduction
				? process.env.REACT_APP_sendbirdAppIdProd
				: process.env.REACT_APP_sendbirdAppId,
			modules: [new GroupChannelModule()],
		});
	}

	isConnected() {
		return !!this.client.currentUser;
	}

	// USER

	getCurrentUser() {
		return this.client.currentUser;
	}

	connect = async ({
		firstName,
		lastName,
		profilePhoto = "",
		_id,
	}) => {
		const nickname = getFullName({ firstName, lastName });
		await this.client.connect(_id);
		await this.client.updateCurrentUserInfo(nickname, profilePhoto);
		console.log("this is sendbird user", this.client.currentUser);
	};

	updateProfile = async ({ firstName, lastName, profilePhoto }) => {
		const nickname = getFullName({ firstName, lastName });
		await this.client.updateCurrentUserInfo(nickname, profilePhoto);
	};

	blockUser = async (userId) => {
		await this.client.blockUserWithUserId(userId);
		return true;
	};

	unblockUser = async (userId) => {
		await this.client.unblockUserWithUserId(userId);
		return true;
	};

	fetchBlockedUsers = async () => {
		var listQuery = this.client.createBlockedUserListQuery();
		return new Promise((resolve, reject) => {
			listQuery.next(function (users, error) {
				if (error) {
					reject(error);
				}

				resolve(users);
			});
		});
	};

	// Chats

	getChannel = async (url) => {
		const channel = await this.client.groupChannel.getChannel(url);
		return channel;
	};

	startTyping = async (channel) => {
		console.log("yes");
		const groupChannel = await this.getChannel(channel);
		groupChannel.startTyping();
		return;
	};

	stopTyping = async (channel) => {
		const groupChannel = await this.getChannel(channel);
		groupChannel.endTyping();
		return;
	};

	clearChatHistory = async (channel) => {
		// Hiding (archiving) a group channel.
		const c = await this.getChannel(channel.url);
		// console.log(c, channel);
		return c.resetMyHistory();
	};

	hideChat = async (chan, d) => {
		const channel = await this.getChannel(chan.url);
		if (channel.customType === "chatConsult") {
			console.log("updated consult ot hide");
			const data = d || JSON.parse(channel.data);
			const archivedBy = data?.hasOwnProperty("archivedBy")
				? data.archivedBy
				: {};
			await this.updateConsult(
				data.channel,
				data.chatUrl,
				{
					...data,
					archived: true,
					archivedBy: {
						...archivedBy,
						[this.client.currentUser.userId]: 1,
					},
				},
				data.chatUrl
			);
		}
		return channel.hide({
			hidePreviousMessages: false,
			allowAutoUnhide: true,
		});
	};

	unhideChat = async (c) => {
		const channel = await this.getChannel(c.url);
		if (channel.customType === "chatConsult") {
			const data = JSON.parse(channel.data);
			const archivedBy = data?.hasOwnProperty("archivedBy")
				? data.archivedBy
				: {};
			console.log("here we are1");
			delete archivedBy[this.client.currentUser.userId];
			this.updateConsult(
				data.channel,
				data.chatUrl,
				{
					...data,
					archived: false,
					resolved: false,
					archivedBy,
				},
				data.chatUrl
			);
			console.log("here we are2");
		}
		return channel.unhide();
	};

	searchConsultMessages = async (channel) => {
		const listQuery = channel.createPreviousMessageListQuery();
		listQuery.limit = 90;
		listQuery.CustomTypeFilter = "consult";
		return listQuery.load();
	};

	leaveChat = async (channel) => {
		// Hiding (archiving) a group channel.
		await channel.leave(function (response, error) {
			if (error) {
				console.log("unable to hide error");
				// Handle error.
			}

			console.log("hide success");

			// The channel successfully gets hidden from the list.
			// The current user's channel view should be refreshed to reflect the change.
		});
		return true;
	};

	createChat = async (
		userIds,
		isDistinct,
		customType = "",
		name,
		cover
	) => {
		console.log(userIds);
		const params = {};
		params.invitedUserIds = userIds;
		params.isDistinct = isDistinct;
		params.customType = customType;
		params.operatorUserIds = [...userIds];
		if (name) {
			params.name = name;
		}
		if (cover) {
			params.coverUrl = cover;
		}
		console.log("newchat", params);
		const newChannel = await this.client.groupChannel.createChannel(
			params,
			(channel, error) => {
				console.log(error);
			}
		);
		// console.log('c', newChannel);
		// const operators = userIds.length > 2 ? [userIds.pop()] : userIds;
		// newChannel.addOperators(operators, function (response, error) {
		//   if (error) {
		//     // Handle error.
		//   }
		// });
		return newChannel;
	};

	addUserToGroup = async (url, userIds) => {
		console.log(url, userIds);
		const channel = await this.getChannel(url);
		const newChannel = await channel.inviteWithUserIds(userIds);
		return newChannel;
	};

	addAdminToGroup = async (url, userIds, isNextShift, username) => {
		console.log(url, userIds);
		const channel = await this.getChannel(url);
		await channel.inviteWithUserIds(userIds);
		const newChannel = await channel.addOperators(userIds);
		// if (isNextShift) {
		//   try {
		//     console.log(
		//       'nextShift',
		//       `https://api-${Config.sendbirdAppId}.sendbird.com/v3/group_channels/${url}/messages`,
		//     );
		//     const {data} = await axios.post(
		//       `https://api-${Config.sendbirdAppId}.sendbird.com/v3/group_channels/${url}/messages`,
		//       {
		//         headers: {
		//           'Content-Type': 'application/json; charset=utf8',
		//           Authorization:
		//             'Basic YW1pdGtoYXRrYXJAaWNsb3VkLmNvbTo5NDE2OTk1ODU0YUFA',
		//         },
		//         data: {
		//           message_type: 'ADMM',
		//           message: `${username} added to the NextShift`,
		//         },
		//       },
		//     );
		//     console.log('nextshift', data);
		//   } catch (e) {
		//     console.log('nextShift', e.response);
		//   }
		// }
		return newChannel;
	};

	removeUserFromGroup = async (url, userId) => {
		return new Promise(async (resolve, reject) => {
			try {
				const channel = await this.getChannel(url);
				// if (myId) {
				//   await channel.addOperators([myId], function (response, error) {
				//     if (error) {
				//       // Handle error.
				//       console.log('reg', error);
				//     }
				//   });
				// }
				await channel.banUserWithUserId(userId, 5, "", (cb, err) => {
					if (err) {
						reject(err);
						console.log(err);
					}
				});
				resolve();
			} catch (e) {
				reject(e);
			}
		});
	};

	getArchivedChats = async (type = "") => {
		var listQuery =
			this.client.groupChannel.createMyGroupChannelListQuery();
		listQuery.includeEmpty = true;
		listQuery.membershipFilter = "joined";
		listQuery.order = "chronological";
		listQuery.hiddenChannelFilter = "hidden_only";
		listQuery.customTypesFilter = [type];
		listQuery.limit = 50;
		console.log(listQuery);
		if (listQuery.hasNext) {
			return listQuery.next();
		}
	};

	getConsultChats = async () => {
		var listQuery =
			this.client.groupChannel.createMyGroupChannelListQuery();
		listQuery.includeEmpty = true;
		listQuery.membershipFilter = "joined";
		listQuery.order = "chronological";
		listQuery.customTypesFilter = ["chatConsult"];
		listQuery.limit = 50;
		listQuery.isHidden = false;

		if (listQuery.hasNext) {
			return listQuery.next();
		}
	};

	getChats = async () => {
		var listQuery =
			this.client.groupChannel.createMyGroupChannelListQuery();
		listQuery.includeEmpty = false;
		// listQuery.membershipFilter = 'joined';
		listQuery.order = "latest_last_message";
		listQuery.customTypesFilter = ["", "nextshift"];
		listQuery.limit = 20;

		if (listQuery.hasNext) {
			return listQuery.next();
		}
	};

	fetchMessages = async (url) => {
		const channel = await this.getChannel(url);
		var listQuery = channel.createPreviousMessageListQuery();
		listQuery.limit = 30;
		listQuery.reverse = false;
		listQuery.includeReactions = true;

		return listQuery.load();
	};

	fetchPreviousMessages = async (channel, timestamp) => {
		const params = {};
		params.isInclusive = false;
		params.prevResultSize = 40;
		params.nextResultSize = 0;
		params.shouldReverse = true;
		params.includeReactions = true;
		return channel.getMessagesByTimestamp(timestamp.createdAt, params);
	};

	fetchConsults = async (channel, TIMESTAMP) => {
		const params = {};
		params.isInclusive = false;
		params.prevResultSize = 100;
		params.nextResultSize = 100;
		params.shouldReverse = true;
		params.customType = "consult";
		return channel.getMessagesByTimestamp(
			TIMESTAMP,
			params,
			(msg, err) => {
				if (err) {
					console.log("error fetching consult", err);
				} else {
					// console.log('res', msg);
				}
			}
		);
	};

	//TODO convert to get by timestamp;
	getMessageById = async (id, channel) => {
		const params = {};
		params.isInclusive = true;
		const msgs = await channel.getMessagesByMessageId(
			Number(id),
			params
		);
		return msgs.length > 0 ? msgs[0] : {};
	};

	searchConsult = (str) => {
		return new Promise((resolve, reject) => {
			var listQuery =
				this.client.groupChannel.createMyGroupChannelListQuery();
			listQuery.includeEmpty = true;
			listQuery.channelNameContainsFilter = str;

			listQuery.next(function (groupChannels, error) {
				if (error) {
					reject(error);
					// Handle error.
				} else {
					resolve(groupChannels);
				}
			});
		});
	};

	updateConsult = async (
		channel,
		id,
		consult,
		consultChannel,
		shouldHideChat
	) => {
		// const params = new this.client.UserMessageParams();
		const channelparam = {};
		channelparam.data = JSON.stringify(consult);
		channelparam.name = consult.title;
		// params.data = JSON.stringify(consult);
		// params.message = consult.title;
		// const groupChannel = await this.getChannel(channel);
		if (consultChannel) {
			const consultGroupChannel = await this.getChannel(consultChannel);
			await consultGroupChannel.updateChannel(channelparam);
			if (shouldHideChat) {
				this.hideChat(consultGroupChannel, consult);
			}
		}
		return true;
		// if (consult.messageID) {
		//   return new Promise((resolve, reject) => {
		//     groupChannel.updateUserMessage(
		//       Number(consult.messageID),
		//       params,
		//       function (message, error) {
		//         if (error) {
		//           console.log(error);
		//           reject(error);
		//         } else {
		//           resolve(message);
		//         }
		//       },
		//     );
	};

	sendMessage = async (
		message,
		id,
		customType,
		parentMessageId,
		parantMessage,
		isThread
	) => {
		let channel = await this.getChannel(id);
		return new Promise((resolve, reject) => {
			const params = {};
			params.message =
				customType === "consult"
					? message.title
					: customType === "gif"
					? "gif"
					: message;
			params.customType = customType || "";
			params.data = JSON.stringify(message);
			if (parentMessageId) {
				params.parentMessageId = Number(parentMessageId);
				if (parantMessage) {
					if (!isThread) {
						params.parentMessageId = null;
					}
					params.data = JSON.stringify(parantMessage);
				}
			}
			if (channel.customType === "chatConsult") {
				console.log("updated consult ot hide");
				const data = JSON.parse(channel.data);
				this.updateConsult(
					data.channel,
					data.chatUrl,
					{
						...data,
						archived: false,
						archivedBy: {},
					},
					data.chatUrl
				);
			}
			params.pushNotificationDeliveryOption = "default";
			channel
				.sendUserMessage(params)
				.onSucceeded((msgData) => {
					resolve(msgData);
				})
				.onFailed((error) => {
					reject(error);
				});
		});
	};

	markAsRead = async (c) => {
		const channel = await this.getChannel(c.url);
		await channel.markAsRead();
		// PushNotification.getApplicationIconBadgeNumber((badgeCount) => {
		//   const totalBadgeCount =
		//     badgeCount - channel.unreadMessageCount >= 0
		//       ? badgeCount - channel.unreadMessageCount
		//       : 0;
		//   PushNotification.setApplicationIconBadgeNumber(totalBadgeCount);
		// });
	};

	//TODO add read reciept conver v3 to v4
	getReadStatus = async (id, message) => {
		const channel = await this.getChannel(id);
		const receipt = channel.getReadStatus(message);
		return receipt <= 0;
	};

	sendFile = async (
		file,
		id,
		parentMessageId,
		parantMessage,
		isThread,
		customType = "",
		onProgess
	) => {
		let channel = await this.getChannel(id);
		console.log("filemessage", file);
		return new Promise((resolve, reject) => {
			try {
				const params = {};
				let name;
				if (file.name) {
					name = file.name;
				} else {
					let paths = file.uri.split("/");
					name = paths[paths.length - 1];
				}
				if (file.type.includes("audio")) {
					params.data = `${file.size}`;
				} else {
					params.thumbnailSizes = [{ maxWidth: 500, maxHeight: 500 }];
				}
				params.file = file.getRawFile();
				params.fileName = name;
				params.fileSize = file.size;
				params.mimeType = file.type;
				params.customType = customType;

				params.pushNotificationDeliveryOption = "default";
				if (parantMessage) {
					params.data = JSON.stringify(parantMessage);
				}
				console.log("/params/", params);
				channel
					.sendFileMessage(params)
					.onSucceeded((msgData) => {
						if (onProgess) {
							onProgess({ loaded: 100, total: 100 });
						}
						resolve(msgData);
					})
					.onPending((res) => {
						if (onProgess) {
							onProgess({ loaded: 90, total: 100 });
						}
					})
					.onFailed((error) => {
						reject(error);
					});
			} catch (e) {
				console.log("rrr", e);
				reject(e);
			}
		});
	};

	deleteChat = async (url) => {
		const channel = await this.getChannel(url);
		return channel.delete();
	};

	deleteMessage = async (message, channel, fetchFirst) => {
		return new Promise(async (resolve, reject) => {
			if (fetchFirst) {
				const params = {};
				params.isInclusive = true;
				params.prevResultSize = 0;
				params.nextResultSize = 0;
				params.shouldReverse = false;
				const c = await this.getChannel(channel);
				// console.log('we are here', c, message);
				const messsages = await c.getMessagesByTimestamp(
					message.createdAt,
					params
				);
				console.log("messages", messsages);
				if (messsages.length > 0) {
					await c.deleteMessage(messsages[0]);
					// resolve();
				}
				return resolve();
			} else {
				const c = await this.getChannel(channel);
				await c.deleteMessage(message);
				resolve();
			}
		});
	};

	addReaction = async (emoji, message, channel) => {
		const groupChannel = await this.getChannel(channel);
		// const msg = await this.getMessageById(message, groupChannel);
		console.log("this is msg", emoji, message);
		const messages = await groupChannel.getMessagesByTimestamp(
			message.createdAt,
			{
				isInclusive: true,
			}
		);
		const msg = messages.find((x) => x.messageId === message.messageId);
		console.log(msg, emoji);
		const reactionEvent = await groupChannel.addReaction(msg, emoji);
		msg.applyReactionEvent(reactionEvent);
		return reactionEvent;
	};

	//handlers for messages

	subscribeToChat = async (onMessageReceived, onMessageDeleted) => {
		var channelHandler = new GroupChannelHandler();
		console.log("subscribed to chat");
		channelHandler.onMessageReceived = (channel, message) => {
			const state = store.getState();
			const activeChat = state.global.activeChat;
			console.log("acive chat", activeChat === channel.url);
			if (activeChat === channel.url) {
				this.markAsRead(channel);
			}
			console.log("new message received");
			onMessageReceived({ message, id: channel.url });
			store.dispatch(fetchAllChats({}));
			store.dispatch(fetchAllConsultChats());
			// this.client.markAsDelivered(channel.url);
		};

		channelHandler.onMessageDeleted = (channel, message) => {
			onMessageDeleted({ messageId: message }, channel);
		};

		this.client.groupChannel.addGroupChannelHandler(
			"single_chat",
			channelHandler
		);
	};
}

let SendBirdInstance;

const sendBird = (function () {
	if (SendBirdInstance) return SendBirdInstance;
	SendBirdInstance = new SendBirdClient();
	return SendBirdInstance;
})();

export default sendBird;
