import moment from "moment";
import { FaClipboard } from "react-icons/fa";
import { useSelector } from "react-redux";
import ProfileSingle from "../../assets/images/profilesingle.png";

export default function ConsultItem({
	isActive = false,
	url,
	lastMessage,
	coverUrl,
	members,
	myId,
	name,
	customType,
	unreadMessageCount,
	onSelect,
	data,
}) {
	const teams = useSelector((state) => state.global.teams) || [];
	const consult = JSON.parse(data || "{}");
	// console.log("con", consult);
	const getTeamName = () => {
		if (consult.team) {
			const mem = members.filter((x) => x.userId !== myId);
			const team = teams?.find((x) => x._id === consult?.team?._id);
			if (team) {
				if (team.users.length === mem.length) {
					let isAvailable = true;
					team.users.forEach((u) => {
						if (mem.findIndex((n) => n.userId === u._id) === -1) {
							isAvailable = false;
						}
					});
					if (isAvailable) {
						return team.name;
					} else {
						return "";
					}
				} else if (team.users.length < mem.length) {
					return `${team.name} and ${
						mem.length - team.users.length
					} other`;
				} else {
					return "";
				}
			}
		} else {
			return "";
		}
	};

	const getTitle = () => {
		const mem = members.filter((x) => x.userId !== myId);
		if (mem.length === 0) {
			return "You";
		}
		if (mem.length === 1) {
			return `You and ${mem[0].nickname}`;
		}
		if (mem.length === 2) {
			return `You , ${
				getFirstName(mem[0].nickname) || ""
			} and ${getFirstName(mem[1].nickname)}`;
		}

		if (mem.length >= 3) {
			return `You, ${getFirstName(mem[0].nickname)} and ${
				mem.length - 2
			} others`;
		}
	};

	const getFirstName = (name) => {
		return name.split(" ")[0];
	};

	return (
		<button
			className={`flex flex-col p-4 rounded-xl ${
				isActive ? "bg-health-20" : "bg-white"
			}`}
			onClick={onSelect}
		>
			<div className="flex flex-row items-center justify-between w-full">
				<div
					className="text-md font-semibold flex flex-row items-center"
					style={{ textAlign: "left" }}
				>
					<FaClipboard
						size={12}
						className={`${
							consult?.resolved ? "text-green-500" : "text-red-500"
						} mr-2`}
					/>
					<div className="text-xs mr-2">
						{consult?.firstName && consult?.lastName && "Pt."}
					</div>{" "}
					{consult?.firstName && consult?.lastName
						? `${consult?.firstName} ${consult?.lastName}`
						: `${consult?.title}`}
				</div>
				{consult?.dateOfBirth && (
					<div className="flex flex-row items-center">
						<div className="text-gray-400 text-sm">DOB: </div>
						<div className="text-gray-600 text-sm ml-1">
							{consult?.dateOfBirth}
						</div>
					</div>
				)}
			</div>
			<div className="flex flex-row items-center justify-between w-full mt-2">
				<span className="text-[#989fa7] text-xs text-left mt-1 line-clamp-2 max-w-[17rem]">
					{consult?.firstName && consult?.lastName
						? `${consult?.title}`
						: ``}
				</span>
				<div className="flex flex-row items-center">
					{!!unreadMessageCount && (
						<span className="text-white text-[12px] p-2 py-1 bg-red-500 rounded mr-2">
							{unreadMessageCount}
						</span>
					)}
					{lastMessage?.createdAt && (
						<div className="text-gray-600 text-sm ml-1">
							{moment(lastMessage?.createdAt).fromNow()}
						</div>
					)}
				</div>
			</div>
			<div className="flex flex-row items-center w-full mt-4">
				<div className="flex flex-row items-center -space-x-2">
					{members
						.filter((x) => x.userId === myId)
						.slice(0, 2)
						.map((user) => (
							<div className="flex rounded-full">
								<img
									alt=""
									src={user?.profilePhoto || ProfileSingle}
									className="rounded-full w-[25px] h-[25px]"
								/>
							</div>
						))}
					{members
						.filter((x) => x.userId !== myId)
						.slice(0, 2)
						.map((member) => (
							<div className="flex rounded-full">
								<img
									alt=""
									src={member?.profilePhoto || coverUrl}
									className="rounded-full w-[25px] h-[25px]"
								/>
							</div>
						))}
					{members?.length > 3 && (
						<div className="flex text-[8px] font-medium w-[25px] h-[25px] z-10 justify-center items-center text-white bg-gray-700 rounded-full border-gray-300 hover:bg-gray-600 dark:border-gray-800">
							+{members?.length - 3}
						</div>
					)}
				</div>
				<span className="text-gray-600 font-medium text-xs text-left mt-1 line-clamp-1 max-w-[15rem] ml-2">
					{getTeamName() || getTitle()}
				</span>
			</div>
		</button>
	);
}
