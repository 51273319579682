import styled from "styled-components";

export const Button = styled.button`
	flex-shrink: 0;
	white-space: nowrap;
	font-size: 16px;
	padding: 18px 60px;
	font-weight: 600;
	border-radius: 10px;
	cursor: pointer;
	opacity: 1;
	transition: all 250ms;

	${({ buttonType }) =>
		buttonType === "primary"
			? `
    background-color: var(--PRIMARY);
	color: white;
    `
			: buttonType === "outlined"
			? `
    background-color: #f0f0f0;
    border: 1px solid #e3e3e3;
    color: black;
    `
			: buttonType === "inline"
			? `
	border-radius: 0px;
	padding: 0px;
	color: var(--PRIMARY);
	`
			: ``}

	:hover {
		opacity: 0.75;
	}

	:disabled {
		cursor: not-allowed;
		background-color: var(--BUTTON_DISABLED);
		color: white;

		&:hover {
			opacity: 1;
		}
	}
`;
