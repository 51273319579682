import { Dialog, Transition } from "@headlessui/react";
import Loader from "components/loader";
import SearchBox from "components/searchBox";
import {
	createConsult,
	createNewConversation,
} from "containers/consults/actions";
import { fetchAllConsultChats } from "containers/global/actions";
import moment from "moment";
import { Fragment, useEffect, useRef, useState } from "react";

import { useDispatch } from "react-redux";
import wordlist from "utils/wordlist";

export default function CreateConsultModal({
	open,
	setOpen,
	isTeamConsult = false,
	preselectedPeople = [],
	selectNewConsultFromSidebar,
}) {
	const cancelButtonRef = useRef(null);
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [title, setTitle] = useState("");
	const [lastName, setLastName] = useState("");
	const [firstName, setFirstName] = useState("");
	const [dateOfBirth, setDob] = useState("");
	const [file, setFile] = useState();
	const [description, setDescription] = useState("");
	const [gender, setGender] = useState("");
	const [selectedPeople, setSelectedPeople] = useState([]);
	const [suggestions, setSuggestions] = useState([]);

	useEffect(() => {
		if (isTeamConsult && preselectedPeople.length) {
			setSelectedPeople(preselectedPeople);
		}
	}, [preselectedPeople, isTeamConsult]);

	const onChangeGender = (e) => {
		console.log(e.target);
		setGender(e.target.value);
	};

	const onCreateChat = async () => {
		if (title) {
			const members = selectedPeople.map((item) => item._id);
			if (members?.length) {
				setLoading(true);
				dispatch(
					createNewConversation({
						members,
						type: "consult",
						onNext: onCreate,
					})
				);
			} else {
				alert("Please select atleast one person");
			}
		} else {
			alert("Please enter consult subject");
		}
	};

	const onCreate = (data) => {
		const { channelUrl, url, team } = data;
		dispatch(
			createConsult({
				id: url || channelUrl,
				title,
				identifier: `Gender: ${gender}`,
				description,
				dateOfBirth: dateOfBirth
					? moment(dateOfBirth).format("MM/DD/YYYY")
					: null,
				firstName,
				lastName,
				file,
				team: null,
				onNext: () => {
					setLoading(false);
					setOpen(false);
					setTitle("");
					setFirstName("");
					setLastName("");
					setDob("");
					setDescription("");
					setGender("");
					setSelectedPeople([]);
					setFile(null);
					dispatch(fetchAllConsultChats());
					if (isTeamConsult) {
						window.location.reload();
					}
					setTimeout(() => {
						selectNewConsultFromSidebar();
					}, 1000);
				},
			})
		);
	};

	useEffect(() => {
		const captionLastWord = title.split(" ").pop();
		if (captionLastWord.length >= 2) {
			searchSuggestions(captionLastWord, setSuggestions);
		} else {
			setSuggestions([]);
		}
	}, [title]);

	const searchSuggestions = async (word, callback) => {
		const newTerms = wordlist.filter((x) =>
			x.toLocaleLowerCase().startsWith(word.toLocaleLowerCase())
		);
		const slicedArray = newTerms.slice(0, 10);
		callback(slicedArray);
		// console.slicedArray('nedical', data);
	};

	const onSetSuggestion = (text) => {
		setTitle((prev) => {
			const words = prev.split(" ");
			words.pop();
			words.push(text + " ");
			setSuggestions([]);
			return words.join(" ");
		});
	};

	const today = new Date();
	const maxDate = today.toISOString().split("T")[0];

	const handleMaxDateChange = (event) => {
		const selectedDate = new Date(event.target.value);
		if (selectedDate <= today) {
			setDob(event.target.value);
		}
	};

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as="div"
				className="relative z-10"
				initialFocus={cancelButtonRef}
				onClose={() => {}}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed z-10 inset-0 overflow-y-auto">
					<div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
								<div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
									<div className="text-gray-700 font-bold text-lg mb-3">
										Create Consult
									</div>
									{!isTeamConsult && preselectedPeople.length === 0 && (
										<SearchBox onChange={setSelectedPeople} />
									)}

									{/* <input
										label="To"
										className="focus:outline-none border-transparent appearance-none w-full p-3 bg-white ring-1 ring-gray-300 focus:ring-login-blue focus:border-login-blue rounded mt-2 focus:z-10 sm:text-sm mb-6"
										placeholder="To"
									/> */}
									<label
										htmlFor="email-address"
										className="font-semibold text-sm"
									>
										Consult Subject
									</label>
									<input
										autoFocus
										className={`focus:outline-none border-transparent appearance-none w-full p-3 bg-white ring-1 ring-gray-300 focus:ring-login-blue focus:border-login-blue rounded mt-2 focus:z-10 sm:text-sm ${
											suggestions.length === 0 && "mb-6"
										}`}
										placeholder="Consult Subject"
										value={title}
										onChange={(e) => setTitle(e.target.value)}
									/>
									<div className="flex flex-row w-full items-center justify-between space-x-3">
										{suggestions.length > 0 && (
											<div className="flex flex-row gap-2 p-2 overflow-x-auto h-[50px] max-w-[800px] scrollbar-hide">
												{suggestions.map((suggestion) => (
													<div
														key={suggestion}
														className="bg-gray-200 rounded-full px-4 py-2 text-sm cursor-pointer hover:bg-gray-300 whitespace-nowrap"
														onClick={() => onSetSuggestion(suggestion)}
													>
														{suggestion}
													</div>
												))}
											</div>
										)}
										<div className="flex-1">
											<label
												htmlFor="email-address"
												className="font-semibold text-sm"
											>
												Patient's First name
											</label>
											<input
												className="focus:outline-none border-transparent appearance-none w-full p-3 bg-white ring-1 ring-gray-300 focus:ring-login-blue focus:border-login-blue rounded mt-2 focus:z-10 sm:text-sm mb-6"
												placeholder="Patient's First name"
												value={firstName}
												onChange={(e) => setFirstName(e.target.value)}
											/>
										</div>
										<div className="flex-1">
											<label
												htmlFor="email-address"
												className="font-semibold text-sm"
											>
												Patient's Last name
											</label>
											<input
												className="focus:outline-none border-transparent appearance-none w-full p-3 bg-white ring-1 ring-gray-300 focus:ring-login-blue focus:border-login-blue rounded mt-2 focus:z-10 sm:text-sm mb-6"
												placeholder="Patient's Last name"
												value={lastName}
												onChange={(e) => setLastName(e.target.value)}
											/>
										</div>
									</div>
									<div className="flex flex-row w-full items-center justify-between space-x-3">
										<div className="flex-1 relative">
											<label
												htmlFor="email-address"
												className="font-semibold text-sm"
											>
												Patients' DOB
											</label>
											<input
												value={dateOfBirth}
												max={maxDate}
												onChange={handleMaxDateChange}
												className="focus:outline-none border-transparent appearance-none w-full p-3 bg-white ring-1 ring-gray-300 focus:ring-login-blue focus:border-login-blue rounded mt-2 focus:z-10 sm:text-sm mb-6"
												type="date"
											/>
										</div>
										<div className="flex-1">
											<label
												htmlFor="email-address"
												className="text-sm font-semibold"
											>
												Gender
											</label>
											<div className="flex justify-center text-sm">
												<select
													className="gender text-sm"
													aria-label="Default select example"
													onChange={onChangeGender}
												>
													<option selected value="">
														Not Selected
													</option>
													<option value="Male">Male</option>
													<option value="Female">Female</option>
													<option value="Not Specified">Not Specified</option>
												</select>
											</div>
										</div>
									</div>

									<label
										htmlFor="email-address"
										className="font-semibold text-sm"
									>
										Description
									</label>
									<textarea
										id="chat"
										rows="3"
										cols={50}
										className="block p-2.5 w-full text-sm resize-none bg-white mt-2
						 rounded max-h-24 min-h-[40px] border border-gray-300
						  focus:ring-login-blue focus:border-login-blue
						   dark:bg-white dark:border-gray-600
						    dark:placeholder-gray-400 dark:text-white
							 dark:focus:ring-blue-500 dark:focus:border-blue-500"
										placeholder="Description"
										value={description}
										onChange={(e) => setDescription(e.target.value)}
									></textarea>
								</div>
								<div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
									<button
										type="button"
										className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-health text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-login-blue sm:ml-3 sm:w-36 sm:text-sm"
										onClick={onCreateChat}
									>
										{loading ? <Loader color="text-white" /> : "Create Consult"}
									</button>
									<button
										type="button"
										className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-login-blue sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
										onClick={() => setOpen(false)}
										ref={cancelButtonRef}
									>
										Cancel
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
