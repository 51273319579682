import * as constants from './constants';

export const fetchDirectory = ({searchString, setSearchResults}) => ({
  type: constants.FETCH_DIRECTORY,
  payload: {searchString, setSearchResults},
});

export const fetchDirectorySuccess = () => ({
  type: constants.FETCH_DIRECTORY_SUCCESS,
});

export const fetchDirectoryFailed = ({error}) => ({
  type: constants.FETCH_DIRECTORY_FAILED,
  payload: {error},
});

export const fetchBlockedUsers = () => ({
  type: constants.FETCH_BLOCKED_USERS,
});

export const fetchBlockedUsersSuccess = ({users}) => ({
  type: constants.FETCH_BLOCKED_USERS_SUCCESS,
  payload: {users},
});

export const fetchBlockedUsersFailed = ({error}) => ({
  type: constants.FETCH_BLOCKED_USERS_FAILED,
  payload: {error},
});

export const blockUser = ({user}) => ({
  type: constants.BLOCK_USER,
  payload: {user},
});

export const blockUserSuccess = ({users}) => ({
  type: constants.BLOCK_USER_SUCCESS,
  payload: {users},
});

export const blockUserFailed = ({error}) => ({
  type: constants.BLOCK_USER,
  payload: {error},
});

export const unblockUser = ({user}) => ({
  type: constants.UNBLOCK_USER,
  payload: {user},
});

export const unblockUserSuccess = ({users}) => ({
  type: constants.UNBLOCK_USER_SUCCESS,
  payload: {users},
});

export const unblockUserFailed = ({error, user}) => ({
  type: constants.UNBLOCK_USER_FAILED,
  payload: {error, user},
});

export const createTeam = ({title, users, onNext}) => ({
  type: constants.CREATE_TEAM,
  payload: {title, users, onNext},
});

export const createTeamSuccess = () => ({
  type: constants.CREATE_TEAM_SUCCESS,
});

export const createTeamFailed = ({error}) => ({
  type: constants.CREATE_TEAM_FAILED,
  payload: {error},
});

export const deleteTeam = ({id, onNext}) => ({
  type: constants.DELETE_TEAM,
  payload: {id, onNext},
});

export const deleteTeamSuccess = () => ({
  type: constants.DELETE_TEAM_SUCCESS,
});

export const deleteTeamFailed = ({error}) => ({
  type: constants.DELETE_TEAM_FAILED,
  payload: {error},
});

export const leaveTeam = ({id, onNext}) => ({
  type: constants.LEAVE_TEAM,
  payload: {id, onNext},
});

export const leaveTeamSuccess = ({id, onNext}) => ({
  type: constants.LEAVE_TEAM_SUCCESS,
});

export const leaveTeamFailed = ({error}) => ({
  type: constants.LEAVE_TEAM_FAILED,
  payload: {error},
});

export const getTeamById = (id, onSuccess) => ({
  type: constants.GET_TEAM_BY_ID,
  payload: {id, onSuccess},
});

export const getTeamsByIdSuccess = ({team}) => ({
  type: constants.GET_TEAM_BY_ID_SUCCESS,
  payload: {team},
});

export const getTeamsByIdFailed = ({error}) => ({
  type: constants.GET_TEAM_BY_ID_FAILED,
  payload: {error},
});

export const getTeams = () => ({
  type: constants.GET_TEAMS,
});

export const getTeamsSuccess = ({teams}) => ({
  type: constants.GET_TEAMS_SUCCESS,
  payload: {teams},
});

export const getTeamsFailed = ({error}) => ({
  type: constants.GET_TEAMS_FAILED,
  payload: {error},
});

export const addTeamUsers = ({users, id, onNext}) => ({
  type: constants.ADD_TEAM_USERS,
  payload: {users, id, onNext},
});

export const addTeamUsersSuccess = () => ({
  type: constants.ADD_TEAM_USERS_SUCCESS,
});

export const addTeamUsersFailed = ({error}) => ({
  type: constants.ADD_TEAM_USERS_FAILED,
  payload: {error},
});

export const removeTeamMembers = ({users, id, onNext}) => ({
  type: constants.REMOVE_TEAM_USERS,
  payload: {users, id, onNext},
});

export const removeTeamMembersSuccess = () => ({
  type: constants.REMOVE_TEAM_USERS_SUCCESS,
});

export const removeTeamMembersFailed = ({error}) => ({
  type: constants.REMOVE_TEAM_USERS_FAILED,
  payload: {error},
});

export const addProxy = ({users, id, onNext, previousProxies}) => ({
  type: constants.ADD_PROXY,
  payload: {users, id, onNext, previousProxies},
});

export const addProxySuccess = () => ({
  type: constants.ADD_PROXY_SUCCESS,
});

export const addProxyFailed = ({error}) => ({
  type: constants.ADD_PROXY_FAILED,
  payload: {error},
});

export const updateTeam = ({title, photo, teamId, onSuccess}) => ({
  type: constants.UPDATE_TEAM,
  payload: {title, photo, teamId, onSuccess},
});
