import { fetchSingleChat } from "containers/global/actions";
import { useState } from "react";
import { BsSearch } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import ConsultItem from "./consultItem";

export default function Consult({
	data,
	selectedChat,
	onSelect,
	hideSearch,
}) {
	const user = useSelector((state) => state.auth.user);
	const dispatch = useDispatch();
	const [searchTerm, setSearchTerm] = useState("");

	// console.log("data::>>>", data);

	const onChatPress = (item) => {
		onSelect(item);
		dispatch(fetchSingleChat({ id: item.url, channel: item }));
		console.log("selected item:", item);
	};

	const filteredData = data?.filter(
		(item) =>
			item?.name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
			item?.lastMessage?.message
				?.toLowerCase()
				.includes(searchTerm?.toLowerCase())
	);

	return (
		<>
			{!hideSearch && (
				<div class="w-full flex justify-end items-center relative px-4 my-4">
					<BsSearch
						size={18}
						className="absolute left-0 text-gray-400 ml-7"
					/>
					<input
						placeholder="Search Consults"
						className="w-full p-2 pl-10 rounded-full appearance-none bg-gray-100"
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
					/>
				</div>
			)}
			<div className="flex flex-col overflow-y-auto gap-1 flex-1 px-4 pb-4">
				{filteredData?.map((item) => (
					<ConsultItem
						key={item.url}
						{...item}
						myId={user?._id}
						onSelect={() => onChatPress(item)}
						isActive={item.url === selectedChat?.url}
					/>
				))}
			</div>
		</>
	);
}
