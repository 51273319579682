import { takeLatest, put, all, select, delay } from "redux-saga/effects";
import * as constants from "./constants";
import { urls } from "../../utils/constants";
import { getApi } from "../../utils/helpers";
import * as authActions from "./actions";
import sendBird from "../../services/sendbird";
import { getToken, getUser } from "utils/selectors";

function* signIn(actions) {
	const { email, password, fcm, invitationCode, onSuccess } = actions.payload;
	try {
		const api = yield getApi();
		const { data } = yield api.post(urls.login, { email, password });
		const { success, token } = data;
		console.log(data);
		if (success) {
			// if (invitationCode) {
			//   const ref = database().ref('invites/users/' + data.data._id);
			//   ref.set({
			//     name: data.data.firstName + ' ' + data.data.lastName,
			//     email,
			//     code: invitationCode,
			//   });
			// }
			yield sendBird.connect({ email, ...data.data });
			yield put(authActions.signInSuccess({ user: data.data, token }));
			onSuccess && onSuccess();
			// yield firebase.registerToken();

			// if (!isAndroid) {
			//   Intercom.registerUnidentifiedUser();
			// }
		}
	} catch (e) {
		console.log(e);
		console.log(e?.response);
		onSuccess && onSuccess();
		yield put(authActions.signInFailed({ error: e?.response?.data }));
	}
}

function* updateCurrentUser(actions) {
	try {
		const { onNext, profilePhoto, ...user } = actions.payload;
		const token = yield select(getToken);
		const oldUser = yield select(getUser);
		const api = yield getApi(token);
		const newUsr = { ...user };
		if (profilePhoto) {
			if (profilePhoto === "remove") {
				newUsr.profilePhoto = "";
			} else {
				const { data } = yield api.get(urls.userPhotouploadSignedUrl);

				// yield RNFetchBlob.fetch(
				//   'PUT',
				//   preSignedURL,
				//   headers,
				//   RNFetchBlob.wrap(pathToImage),
				// ).then((d) => console.log('resut', d));
				yield fetch(data.data.presignedUrl, {
					body: {
						uri: profilePhoto,
						type: "image/jpeg",
					},
					method: "PUT",
				});
				newUsr.profilePhoto = data.data.viewUrl;
			}
		}
		const { data } = yield api.put(urls.updateSingleUser, { ...newUsr });
		const { success } = data;
		if (success) {
			yield sendBird.updateProfile({
				...user,
				profilePhoto: newUsr.profilePhoto || "",
			});
			yield put(
				authActions.updateUserSuccess({
					user: {
						...user,
						profilePhoto: newUsr.profilePhoto,
						verified: true,
					},
				})
			);
			if (onNext) {
				onNext();
			}
		}
	} catch (e) {
		console.log(e);
		yield put(authActions.updateUserFailed({ error: e?.response?.data }));
	}
}

function* setStatusMessage(actions) {
	try {
		const { onNext, ...status } = actions.payload;
		const token = yield select(getToken);
		const api = yield getApi(token);
		const { data } = yield api.post(urls.updateStatus, { ...status });

		if (data?.success) {
			yield put(authActions.setStatusMessageSuccess());
			if (onNext) {
				onNext();
			}
		}
	} catch (e) {
		console.log(e);
		yield put(
			authActions.setStatusMessageFailed({ error: e?.response?.data })
		);
	}
}

function* getCurrentUser() {
	try {
		const token = yield select(getToken);
		const api = yield getApi(token);
		const { data } = yield api.get(urls.getSingleUser);
		const { success } = data;
		if (success) {
			yield put(authActions.getCurrentUserSuccess({ user: data.data }));
		}
	} catch (e) {
		console.log(e?.response);
	}
}

function* sendPasswordReset(actions) {
	try {
		const api = yield getApi(true);
		const { email, onNext } = actions.payload;
		const { data } = yield api.post(urls.resetPassword, { email });

		const { success } = data;
		if (success) {
			yield put(authActions.sendPasswordResetSuccess());
			onNext();
		} else {
			alert("This email is not registered with us.");
			yield put(authActions.sendPasswordResetFailed({ error: data }));
		}
	} catch (e) {
		console.log(e.response);
		alert("This email is not registered with us.");
		yield put(
			authActions.sendPasswordResetFailed({ error: e?.response?.data })
		);
	}
}

function* sendPhoneCode(actions) {
	try {
		const { email, phone, onNext } = actions.payload;
		const api = yield getApi(true);
		const { data } = yield api.post(urls.generatePhoneCode, {
			email,
			phone,
		});

		const { success } = data;
		if (success) {
			yield put(authActions.sendPhoneCodeSuccess(actions.payload));
			onNext();
		} else {
			yield put(authActions.sendPhoneCodeFailed({ error: data }));
		}
	} catch (e) {
		console.log(e?.response);
		yield put(authActions.sendPhoneCodeFailed({ error: e?.response }));
	}
}

function* verifyPhoneCode(actions) {
	try {
		const { code, phone, onNext } = actions.payload;
		const api = yield getApi(true);
		// const tempUser = yield select((state) => state.auth.tempUser);
		const { data } = yield api.post(urls.verifyPhoneCode, {
			code,
			phone,
		});

		const { success } = data;
		if (success) {
			yield put(authActions.verfiyPhoneCodeSuccess(actions.payload));
			onNext();
		} else {
			yield put(authActions.verfiyPhoneCodeFailed({ error: data }));
		}
	} catch (e) {
		yield put(
			authActions.verfiyPhoneCodeFailed({ error: e?.response?.data })
		);
	}
}

function* registerUser(actions) {
	const { onNext, fcm = "", invitationCode, setLoading, user } = actions.payload;
	try {
		const api = yield getApi(true);
		const tempUser = user;
		const { data } = yield api.post(urls.register, {
			...tempUser,
			pinCode: "1111",
			organization: 'N/A',
			fcmToken: null,
		});
		const { success, token } = data;
		if (success) {
			// yield requestUserPermission();
			// yield sendBird.connect({...data.data});
			yield put(
				authActions.registerUserSuccess({ user: data.data, token })
			);
			onNext();
			setLoading(false);
			// yield firebase.registerToken();
			// if (!isAndroid) {
			//   Intercom.registerUnidentifiedUser();
			// }
		} else {
			yield put(authActions.registerUserFailed({ error: data }));
		}
	} catch (e) {
		alert("There is some error, Please try later.");
		setLoading(false);
		yield put(authActions.registerUserFailed({ error: e?.response?.data }));
	}
}

export default function* watchAuth() {
	all([
		yield takeLatest(constants.SIGN_IN, signIn),
		yield takeLatest(constants.UPDATE_USER, updateCurrentUser),
		yield takeLatest(constants.GET_CURRENT_USER, getCurrentUser),
		yield takeLatest(constants.SET_STATUS_MESSAGE, setStatusMessage),
		yield takeLatest(constants.SEND_PASSWORD_RESET, sendPasswordReset),
		yield takeLatest(constants.SEND_PHONE_CODE, sendPhoneCode),
		yield takeLatest(constants.VERIFY_PHONE_CODE, verifyPhoneCode),
		yield takeLatest(constants.REGISTER_USER, registerUser),
	]);
}
