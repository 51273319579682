import FullScreenLoader from "components/loader/fullScreenLoader";
import { fetchAllConsultChats } from "containers/consults/actions";
import {
	deleteConsult,
	fetchAllChats,
} from "containers/global/actions";
import { useEffect, useState } from "react";
import {
	BsChatText,
	BsChevronLeft,
	BsClipboardCheck,
	BsThreeDots,
	BsTrash,
} from "react-icons/bs";
import { useDispatch } from "react-redux";
import sendBird from "services/sendbird";
import { usePhemeContext } from "store/phemeContext";
import styled from "styled-components";

const MoreOptions = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	padding-top: 70px;
	z-index: 50;
`;

export default function Header({
	selectedChat,
	user,
	title,
	isConsult,
	setSelectedChat,
	isArchived,
	isAdmin,
	onShowInfo,
	setConsultModalOpen,
	setChatModalOpen,
}) {
	const { members, name, coverUrl } = selectedChat || {};
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [loadingMessage, setLoadingMessage] = useState("Loading ...");
	const dispatch = useDispatch();
	const [isClipOpen, setIsClipOpen] = useState(false);
	const [showMoreOptions, setShowMoreOptions] = useState(false);

	const { navDrawerOpen, setNavDrawerOpen } = usePhemeContext();

	useEffect(() => {
		window.addEventListener("click", function (e) {
			if (!document.getElementById("header").contains(e.target)) {
				setIsClipOpen(false);
			}
		});
	}, []);

	const archiveConsult = () => {
		if (isArchived) {
			setDeleteModalOpen(true);
			setLoadingMessage("Unarchiving ...");
			sendBird.unhideChat(selectedChat).then(() => {
				setDeleteModalOpen(false);
			});
		} else {
			setDeleteModalOpen(true);
			setLoadingMessage("Archiving ...");
			sendBird.hideChat(selectedChat).then(() => {
				dispatch(
					fetchAllConsultChats(() => {
						setDeleteModalOpen(false);
						setSelectedChat(null);
					})
				);
			});
		}
	};

	const onDelete = () => {
		setDeleteModalOpen(true);
		setLoadingMessage("Deleting...");
		if (isConsult) {
			const consult = JSON.parse(selectedChat.data);
			dispatch(
				deleteConsult({
					consult,
					onNext: () => {
						dispatch(
							fetchAllConsultChats(() => {
								setDeleteModalOpen(false);
								setSelectedChat(null);
							})
						);
					},
				})
			);
		} else {
			sendBird.clearChatHistory(selectedChat).then(() => {
				dispatch(
					fetchAllChats({
						onSuccess: () => {
							setDeleteModalOpen(false);
							setSelectedChat(null);
						},
					})
				);
			});
		}
	};

	return (
		<div className="flex flex-row border-b border-gray-100 items-center justify-between h-[70px] p-4">
			<button
				onClick={() => setNavDrawerOpen(!navDrawerOpen)}
				className="flex flex-row items-center justify-center rounded-full pr-3 transition-transform duration-300"
				style={{
					transform: navDrawerOpen ? "" : "rotate(180deg)",
					display: navDrawerOpen ? "" : "none",
				}}
			>
				<BsChevronLeft size={26} className="text-gray-400 " />
			</button>
			<img
				src={
					members?.length > 2 ? coverUrl : user?.profilePhoto || coverUrl
				}
				width={40}
				height={40}
				alt=""
				className="rounded-full w-[40px] h-[40px]"
			/>
			<div className="flex flex-col w-full pl-2">
				<div className="text-md font-semibold">
					{name !== "Group Channel" ? name : title}
				</div>
				{/* <span className="text-gray-600 text-xs">Doctor</span> */}
			</div>
			<div className="relative flex flex-row">
				{isConsult && (
					<button
						className="mr-2 flex justify-center items-center h-14 w-14 hover:bg-gray-200 rounded-full group/archive"
						id="header"
						onClick={archiveConsult}
					>
						<BsClipboardCheck className="h-6 w-6 text-green-500 group-hover/archive:text-gray-800" />
						<span className="header-tooltip group-hover/archive:scale-100">
							{isArchived ? "Unresolve Consult" : "Resolve Consult"}
						</span>
					</button>
				)}
				<button
					className="mr-2 ml-2 flex justify-center items-center h-14 w-14 hover:bg-health rounded-full group/delete"
					id="header"
					onClick={() => setShowMoreOptions(!showMoreOptions)}
				>
					<BsThreeDots
						className={`h-6 w-6 text-gray-500 group-hover/delete:text-white`}
					/>
					<MoreOptions
						className={`${showMoreOptions ? "scale-100" : "scale-0"}`}
					>
						<div className="w-48 bg-white shadow-md flex p-2 flex-col space-y-1 rounded">
							<button
								className="fab-button"
								onClick={() =>
									isConsult
										? setConsultModalOpen(true)
										: setChatModalOpen(true)
								}
							>
								<BsChatText className="w-6 h-6 text-health mr-2" />
								{isConsult ? "Consult Details" : "Chat Details"}
							</button>
							<button
								onClick={onDelete}
								className="fab-button group"
								//   onClick={() => setNewConvo(true)}
							>
								<BsTrash className="w-6 h-6 text-health mr-2" />
								Delete
							</button>
						</div>
					</MoreOptions>
					<div id="root-portal" className="z-20 mb-4 " />
				</button>
				{/* <button
					className="mr-2 flex justify-center items-center h-14 w-14 hover:bg-gray-50 rounded-full group/info"
					id="header"
					onClick={onShowInfo}
				>
					<BsInfoCircle className="text-gray-500" size={28} />
					<span className="header-tooltip group-hover/info:scale-100">
						{isConsult ? "Consult" : "Chat"} Info
					</span>
				</button> */}
				{/* {
					<div
						className={`w-52 bg-white absolute top-12 right-0 shadow-2xl border flex-col space-y-0 rounded text-sm font-semibold 
						${isClipOpen ? "scale-100" : "scale-0"}`}
					>
						<button className="fab-button" onClick={onDelete}>
							<BsTrash className="w-6 h-6 text-red-500 mr-2" />
							Delete {isConsult ? "Consult" : "Chat"}
						</button>
						{isConsult && (
							<>
								<button
									className="fab-button group"
									onClick={archiveConsult}
								>
									<BsArchive className="w-6 h-6 text-red-500 mr-2" />
									{isArchived
										? "Unarchive Consult"
										: "Archive Consult"}
								</button>
								{isAdmin && (
									<button className="fab-button">
										<PencilIcon className="w-6 h-6 text-red-500 mr-2" />
										Edit Consult
									</button>
								)}
							</>
						)}
					</div>
				} */}
			</div>
			<FullScreenLoader
				message={loadingMessage}
				open={deleteModalOpen}
			/>
		</div>
	);
}
