import styled from "styled-components";

export const OTPInputWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 20px;
	margin: 20px 0;
`;

export const OTPResendWrapper = styled.div`
	margin-bottom: 20px;
	& p {
		font-size: 16px;
		color: #6b7280;
	}
`;

export const OTPInputField = styled.input`
	background-color: var(--FIELD_BG);
	border: 1px solid var(--FIELD_BORDER);
	outline: none;
	width: 50px;
	height: 60px;
	text-align: center;
	font-size: 30px;
	border-radius: 5px;
	padding: 0;

	&:focus {
		background-color: var(--FIELD_BG_FOCUS);
		border: 1px solid var(--PRIMARY);

		--tw-ring-color: none;
	}
`;

export const ButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 10px;
`;
