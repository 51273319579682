import { applyMiddleware, createStore } from "redux";
import { persistStore, persistReducer, createTransform } from "redux-persist";
import createSagaMiddleware from "redux-saga";
import storage from "redux-persist/lib/storage";
import { composeWithDevTools } from "@redux-devtools/extension";

// Transform: remove 'error' before persisting
const removeErrorTransform = createTransform(
	// transform state on its way to being serialized and persisted.
	(inboundState, key) => {
		const { error, ...otherState } = inboundState;
		return otherState;
	},
	// transform state being rehydrated
	(outboundState, key) => {
		return outboundState;
	}
);

const persistConfig = {
	key: "root",
	timeout: 10000,
	storage,
	whitelist: ['auth', 'global', 'consult', 'directory'],
	transforms: [removeErrorTransform],
};

const configstore = (rootReducer, rootSaga) => {
  const middleware = [];
  const enhancers = [];
  const sagaMiddleware = createSagaMiddleware();

  const persistedReducer = persistReducer(persistConfig, rootReducer);
  middleware.push(sagaMiddleware);
  enhancers.push(applyMiddleware(...middleware));

  const store = createStore(
    persistedReducer,
    composeWithDevTools(...enhancers)
  );
  const persistor = persistStore(store);
  const sagasManager = sagaMiddleware.run(rootSaga);

  return {
    store,
    persistor,
    sagasManager,
    sagaMiddleware,
  };
};

export default configstore;
