import { Formik, Form, useField } from "formik";
import React, { createRef, useEffect, useState } from "react";
import * as Yup from "yup";
import * as S from "./styled";
import Button from "components/Button";
import { sendPhoneCode } from "containers/auth/actions";
import { useDispatch } from "react-redux";

const OTPValidationSchema = Yup.object().shape({
	otp1: Yup.string().required("Required").length(1, "Only one digit"),
	otp2: Yup.string().required("Required").length(1, "Only one digit"),
	otp3: Yup.string().required("Required").length(1, "Only one digit"),
	otp4: Yup.string().required("Required").length(1, "Only one digit"),
	otp5: Yup.string().required("Required").length(1, "Only one digit"),
	otp6: Yup.string().required("Required").length(1, "Only one digit"),
});

const OTPInput = ({ name, forwardedRef }) => {
	const [field, , helpers] = useField(name);

	useEffect(() => {
		if (field.value) {
			const nextSibling = forwardedRef.current.nextSibling;
			if (nextSibling) {
				nextSibling.focus();
			}
		}
	}, [field.value]);

	const handleKeyDown = (evt) => {
		if (evt.key === "Backspace" || evt.key === "Delete") {
			const prevSibling = evt.target.previousElementSibling;
			if (prevSibling) {
				helpers.setValue("");
				prevSibling.focus();
				prevSibling.value = "";
			}
		}
	};

	return (
		<S.OTPInputField
			{...field}
			ref={forwardedRef}
			type="text"
			maxLength="1"
			onKeyDown={handleKeyDown}
			onChange={(evt) => helpers.setValue(evt.currentTarget.value)}
		/>
	);
};

const OTPForm = ({
	onClose,
	firstName,
	lastName,
	email,
	phone,
	onVerifyCode,
}) => {
	const [canResend, setCanResend] = useState(false);
	const [resendTimer, setResendTimer] = useState(60);
	const initialValues = {
		otp1: "",
		otp2: "",
		otp3: "",
		otp4: "",
		otp5: "",
		otp6: "",
	};

	const dispatch = useDispatch();

	// Passed as a prop to OTPForm
	const onResendCode = () => {
		dispatch(
			sendPhoneCode({
				firstName,
				lastName,
				email,
				phone,
			})
		);
	};

	useEffect(() => {
		let interval = setInterval(() => {
			if (resendTimer <= 0) {
				clearInterval(interval);
				setCanResend(true);
			} else {
				setResendTimer((timer) => timer - 1);
			}
		}, 1000);

		return () => {
			clearInterval(interval);
		};
	}, [resendTimer]);

	const firstInputRef = createRef();

	useEffect(() => {
		if (firstInputRef.current) {
			firstInputRef.current.focus();
		}
	}, []);

	const onSubmit = (values, actions) => {
		const otp =
			values.otp1 +
			values.otp2 +
			values.otp3 +
			values.otp4 +
			values.otp5 +
			values.otp6;

		console.log("Your OTP is: ", otp);
		if (otp.length !== 6)
			return console.log(
				"OTP length isn't 6 therefore it can't be processed"
			);

		onVerifyCode(otp, actions);
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={OTPValidationSchema}
			onSubmit={onSubmit}
			validateOnMount
		>
			{({ isSubmitting, isValid }) => (
				<Form>
					<S.OTPInputWrapper>
						{Array.from({ length: 6 }, (_, i) => (
							<OTPInput
								key={i}
								name={`otp${i + 1}`}
								forwardedRef={i === 0 ? firstInputRef : createRef()}
							/>
						))}
					</S.OTPInputWrapper>
					<S.OTPResendWrapper>
						{canResend ? (
							<p>
								Didn't receive a code?{" "}
								<Button buttonType={"inline"} onClick={onResendCode}>
									Send new code
								</Button>
							</p>
						) : (
							<p>
								Resend code in{" "}
								{resendTimer === 1
									? `${resendTimer} second`
									: `${resendTimer} seconds`}
							</p>
						)}
					</S.OTPResendWrapper>
					<S.ButtonContainer>
						<Button buttonType={"outlined"} onClick={onClose}>
							Back
						</Button>

						<Button
							buttonType={"primary"}
							type="submit"
							disabled={isSubmitting || !isValid}
						>
							Verify
						</Button>
					</S.ButtonContainer>
				</Form>
			)}
		</Formik>
	);
};

export default OTPForm;
