import { Dialog, Transition } from "@headlessui/react";
import Loader from "components/loader";
import SearchBox from "components/searchBox";
import { createNewConversation } from "containers/consults/actions";
import { sendMessage } from "containers/global/actions";
import { Fragment, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";

export default function CreateConversationModal({ open, setOpen }) {
	const cancelButtonRef = useRef(null);
	const dispatch = useDispatch();
	const user = useSelector((state) => state.auth.user);
	const [message, setMessage] = useState("");
	const [loading, setLoading] = useState(false);
	const [selectedPeople, setSelectedPeople] = useState([]);

	const onCreateChat = async () => {
		const members = selectedPeople.map((item) => item._id);
		if (members?.length) {
			setLoading(true);
			dispatch(
				createNewConversation({
					members,
					type: "chat",
					onNext,
				})
			);
		} else {
			alert("Please select atleast one person");
		}
	};

	const onNext = (data) => {
		const createdAt = new Date().valueOf();
		dispatch(
			sendMessage({
				id: data?.url,
				message,
				sender: {
					userId: user?._id,
				},
				channel: data,
				type: typeof message === "string" ? "text" : "file",
				createdAt,
			})
		);
		setLoading(false);
		setOpen(false);
		setMessage("");
	};

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as="div"
				className="relative z-10"
				initialFocus={cancelButtonRef}
				onClose={() => {}}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed z-10 inset-0 overflow-y-auto">
					<div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
								<div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
									<div className="text-gray-700 font-bold text-lg mb-3">
										New Chat
									</div>
									<SearchBox onChange={setSelectedPeople} />
									<label
										htmlFor="email-address"
										className="font-semibold text-sm"
									>
										Message
									</label>
									<textarea
										id="chat"
										rows="3"
										cols={50}
										className="block p-2.5 w-full text-sm resize-none bg-white mt-2
						 rounded max-h-24 min-h-[40px] border border-gray-300
						  focus:ring-login-blue focus:border-login-blue
						   dark:bg-white dark:border-gray-600
						    dark:placeholder-gray-400 dark:text-white
							 dark:focus:ring-blue-500 dark:focus:border-blue-500"
										placeholder="Type a message"
										value={message}
										onChange={(e) => setMessage(e.target.value)}
									></textarea>
								</div>
								<div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
									<button
										type="button"
										className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-health text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-login-blue sm:ml-3 sm:w-36 sm:text-sm"
										onClick={onCreateChat}
									>
										{loading ? <Loader color="text-white" /> : "Create"}
									</button>
									<button
										type="button"
										className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-login-blue sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
										onClick={() => setOpen(false)}
										ref={cancelButtonRef}
									>
										Cancel
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
